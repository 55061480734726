import { useMutation } from '@tanstack/react-query';
import { POST, POST_UPDATE_MAP_PRODUCT } from '../../../../services';
import { UpdateManagement } from '../../../../resources/payload';
import { openAlert } from '../../../../components/alert/hooks';
import { ROUTES_PATH } from '../../../../resources/routes-name';
import { navigateTo } from '../../../../utils/navigation';

export const useUpdateManagement = () =>
  useMutation({
    mutationFn: async (values) => {
      const formData = new FormData();
      const payload = UpdateManagement(values);

      Object.entries(payload).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          formData.append(key, value);
        }
      });

      const response = await POST(POST_UPDATE_MAP_PRODUCT(), formData);
      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'แก้ไขจัดกลุ่มสินค้าสำเร็จ',
      });
      setTimeout(() => {
        navigateTo({ pathname: ROUTES_PATH.ROUTE_EDIT_PRODUCT.PATH });
      }, 1500);
    },
  });
