import { useMutation } from '@tanstack/react-query';
import { POST, POST_UPDATE_USER_INFO } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';
import { updateUserInfo } from '../../../resources/payload';

export const useUpdateInfo = ({ onSuccessCallBack }) => {
  return useMutation({
    mutationFn: async (values) => {
      const payload = updateUserInfo(values);
      const response = await POST(POST_UPDATE_USER_INFO(), payload);
      if (onSuccessCallBack) onSuccessCallBack(response);

      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'แก้ไขข้อมูลสำเร็จ',
      });
    },
    onError: (error) => {
      openAlert({
        model: 'message',
        type: 'error',
        message: error?.message || 'แก้ไขข้อมูลไม่สำเร็จ',
      });
    },
  });
};
