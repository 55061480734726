import { useMutation } from '@tanstack/react-query';
import { useRef, useState } from 'preact/hooks';
import { convertDateInputToPayload } from '../../../../functions/convert';
import { GET, GET_CLAIM_BY_DATE } from '../../../../services';
import { searchDefaultValues } from '../form';

export const useClaimDate = ({ setSelectedRowKeys, searchParamState }) => {
  const [data, setData] = useState([]);
  const currentValue = useRef(searchDefaultValues);
  const { startDate, tel, fullname, customerCode } = searchParamState;
  const [startDateFrom, startDateTo] = startDate || ['', ''];

  const { isPending, mutate } = useMutation({
    mutationFn: async (value) => {
      const response = await GET(
        GET_CLAIM_BY_DATE({
          tel: tel || '',
          fullname: fullname || '',
          customerCode: customerCode || '',
          startDate: convertDateInputToPayload(startDateFrom),
          endDate: convertDateInputToPayload(startDateTo),
        }),
      );
      currentValue.current = value;
      return response.result.items || [];
    },
    onSuccess: (response) => {
      setSelectedRowKeys((prev) => {
        return prev?.filter((claimId) => response?.some((newRow) => newRow?.claimId === claimId));
      });
      setData(response);
    },
  });

  const onFetch = () => mutate(currentValue.current);

  return { data, setData, loading: isPending, mutate, onFetch };
};
