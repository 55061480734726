import { useMutation } from '@tanstack/react-query';
import { POST, POST_RESEND_KERRY } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';

export const useResendKerry = ({ selectedRowKeys, onSuccessCallback }) =>
  useMutation({
    mutationFn: async () => {
      const payload = {
        claimIdList: selectedRowKeys,
      };
      const response = await POST(POST_RESEND_KERRY(), payload);
      return response;
    },

    onSuccess: (data) => {
      openAlert({ model: 'message', type: 'success', message: data?.message });
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: (response) => {
      openAlert({
        model: 'message',
        type: 'error',
        message: response?.message || 'เกิดข้อผิดพลาด',
      });
    },
  });
