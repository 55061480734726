export const color = {
  theme: 'red',
  // linear-gradient(#e66465, #9198e5);
  edit: '#ff7b54',
  submit: '#7eca9c',
  clear: '#707070',
  remove: '#d35d6e',
  search: '#1890ff',
  red: '#FF0000',
  logout: '#737373',
  line: '#06c755',
  yellow: '#F4D35E',
  kerry: '#F37123',
  gray: '#9e9e9e',

  //main theme focus
  blueFocus: '#2BB3E7',
  blackFocus: '#000000',
  whiteFocus: '#FFFFFF',
};
