import { useState } from 'preact/hooks';
import { useMutation } from '@tanstack/react-query';
import { GET, GET_MAP_PRODUCT_FULL_SEARCH } from '../../../../services';

export const useGetProduct = ({ handleSubmit }) => {
  const [data, setData] = useState([]);

  const { isPending, mutate: onSearchFull } = useMutation({
    mutationFn: async (searchParamState) => {
      const { search } = searchParamState;
      const response = await GET(
        GET_MAP_PRODUCT_FULL_SEARCH({
          search: search || '',
        }),
      );
      return response.result.items || [];
    },
    onSuccess: (response) => {
      setData(response);
    },
  });

  const onFetch = () =>
    handleSubmit((values) => {
      return onSearchFull(values);
    })();

  return { dataSource: data, setData, onSearchFull, loading: isPending, onFetch };
};
