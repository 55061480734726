import { create } from 'apisauce';
import { getQueryString, getQueryStringIgnoreEmpty } from '../functions';
import { getCookies } from '../store/useCookies';
import { env } from '../utils/env/config';
import { Modal } from 'antd';
export const ip = 'http://192.168.1.169:2000';

export const MODE_API = {
  DEVELOPMENT: env.url_dev,
  UAT: env.url_uat,
  PRODUCTION: env.url_prd,
};
export const CONNECT_API = MODE_API[`${env.node_env}`.toUpperCase()];

const api = create({
  baseURL: CONNECT_API,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 100000, // 100 seconds
});

api.axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === 'ECONNABORTED') {
      window.location.href = 'https://central.zetta-system.com/main';
    }
    return Promise.reject(error);
  },
);

const apiupload = create({
  baseURL: CONNECT_API,
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});
/**
 * ฟังก์ชั่นสำหรับ ยิงข้อมูล
 * @param {string} path
 * @param {{}} obj
 * @return {{}}
 */

const checkAccessToken = (token) => {
  if (!token) {
    Modal.confirm({
      title: 'แจ้งเตือน',
      content:
        'ไม่สามารถใช้งานได้ กรุณาออกจากระบบ แล้วลองเข้าใหม่อีกครั้ง หากเข้าไม่ได้ให้ติดต่อ Admin',
      okText: 'ตกลง',
      onOk: () => {
        window.location.href = 'https://central.zetta-system.com/main';
      },
    });
    return false;
  }
  return true;
};

export const POST = (path, obj, token = getCookies('accessToken'), config = {}) =>
  new Promise((resolve, reject) => {
    api
      .post(path, obj, {
        headers: {
          Authorization: 'Bearer ' + token,
          ...(config.responseType === 'blob'
            ? { Accept: 'application/pdf' }
            : { 'Content-Type': 'application/json' }),
        },
        ...config,
        responseType: config.responseType || 'json',
      })
      .then((response) => {
        if (config.responseType === 'blob' && response.status === 200) {
          resolve(response.data);
          return;
        }

        if (response.status === 200) {
          resolve(response.data);
        } else {
          if (response.status === 401) {
            window.location.href = 'https://central.zetta-system.com/main';
          } else {
            response.data
              ? reject(response.data)
              : reject({ success: false, message: response.problem });
          }
        }
      })
      .catch((err) => {
        // ถ้าเป็น blob แต่เกิด error
        if (config.responseType === 'blob' && err.response) {
          // แปลง blob error เป็น json
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const error = JSON.parse(reader.result);
              if (error.status === 401) {
                window.location.href = 'https://central.zetta-system.com/main';
              } else {
                reject(error);
              }
            } catch (e) {
              reject({ success: false, message: 'เกิดข้อผิดพลาด' });
            }
          };
          reader.onerror = () => {
            reject({ success: false, message: 'เกิดข้อผิดพลาด' });
          };
          reader.readAsText(err.response.data);
          return;
        }

        // error กรณีปกติ
        if (err.response && err.response.status === 401) {
          window.location.href = 'https://central.zetta-system.com/main';
        } else {
          reject(err);
        }
      });
  });

/**
 * ฟังก์ชั่นสำหรับ ยิงข้อมูล
 * @param {string} path
 * @param {{}} obj
 * @return {{}}
 */
export const PUT = (path, obj, token = getCookies('accessToken')) =>
  new Promise((resolve, reject) => {
    api
      .put(path, obj, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

/**
 * ฟังก์ชั่นสำหรับ ดึงข้อมูล
 * @param {string} path
 *
 */
export const GET = (path, token = getCookies('accessToken'), options = {}) =>
  new Promise((resolve, reject) => {
    if (!checkAccessToken(token)) {
      return;
    }

    api
      .get(
        path,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
          ...options,
          responseType: options.responseType || 'json',
        },
      )
      .then((response) => {
        if (response.status === 401) {
          window.location.href = 'https://central.zetta-system.com/main';
        } else if (response.status === 200) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          window.location.href = 'https://central.zetta-system.com/main';
        } else {
          reject(err);
        }
      });
  });

export const UPLOAD = (path, formdata, token = getCookies('accessToken')) =>
  new Promise((resolve, reject) => {
    if (!checkAccessToken(token)) {
      return;
    }
    apiupload
      .post(path, formdata, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.message });
        }
      })
      .catch((err) => reject(err));
  });

/* ################################################## URL ################################################## */
export const LOGIN = `/warranty/focus/api/v2/admin/auth/login`;
export const LOGIN_CENTRAL = () => `/warranty/focus/api/v2/admin/auth/login/central`;
export const LOGIN_GOOGLE = `/warranty/focus/api/v2/admin/auth/login/central`;
export const REGISTER = `/authen/register`;
export const GET_PROFILE = `/warranty/focus/api/v2/admin/auth/me`;
export const GET_ALL_PRODUCT = `/product/get-all`;
export const GET_PRODUCT_SEARCH = (obj) => `/product/search?${getQueryString(obj)}`;

export const GET_ALL_POST = `/posts`;
export const INSERT_POST = `/posts`;
export const UPDATE_POST = (id) => `/posts/${id}`;
export const GET_POST_BY_ID = (id) => `/posts/${id}`;

//warranty
export const GET_WARRANTY = (obj) =>
  `/warranty/focus/api/v2/admin/warranties/by-date?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_WARRANTY_FULL_SEARCH = (obj) =>
  `/warranty/focus/api/v2/admin/warranties/full-search?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_WARRANTY_REGISTER = (warrantyNumberCode) =>
  `/warranty/focus/api/v2/admin/serial-number/verify/${warrantyNumberCode}`;
export const GET_CUSTOMERS_TEL = (telephone) =>
  `/warranty/focus/api/v2/admin/customers/tel/${telephone}`;
export const POST_CANCEL_WARRANTY = () => `/warranty/focus/api/v2/admin/warranties/cancel`;
export const GET_WARRANTY_INFO_CODE = (warrantyCode) =>
  `/warranty/focus/api/v2/admin/warranties/info/${warrantyCode}`;
export const POST_WARRANTY_UPDATE = () => `/warranty/focus/api/v2/admin/warranties/update`;
export const POST_WARRANTY_ADD = () => `/warranty/focus/api/v2/admin/warranties/add`;
export const POST_UPDATE_EXPIRED = () => `/warranty/focus/api/v2/admin/warranties/update/expired`;

//claim
export const GET_CLAIM_BY_DATE = (obj) =>
  `/warranty/focus/api/v2/admin/claims/by-date?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_CLAIM_FULL_SEARCH = (obj) =>
  `/warranty/focus/api/v2/admin/claims/full-search?${getQueryStringIgnoreEmpty(obj)}`;
export const POST_UPDATE_TRACKING = () => `/warranty/focus/api/v2/admin/tracking/change-number`;
export const POST_SEND_MESSAGE = () => `/warranty/focus/api/v2/admin/claims/send-msg/line`;
export const POST_SEND_REMARK = () => `/warranty/focus/api/v2/admin/claims/update/remark`;
export const POST_APPROVE_CLAIM = () =>
  `/warranty/focus/api/v2/admin/claims/update/status/approve-list`;
export const POST_RESEND_KERRY = () => `/warranty/focus/api/v2/admin/kerry/order/re-create`;
export const POST_APPROVE_CLAIM_AUTO = () => `/warranty/focus/api/v2/admin/kerry/order/create`;
export const POST_DOWNLOAD_PDF = () =>
  `/warranty/focus/api/v2/admin/tracking/download/shipment-label`;
export const GET_CLAIM_DETAIL = (claimCode) =>
  `/warranty/focus/api/v2/admin/claims/code/${claimCode}`;
export const POST_REJECT_CLAIM = () =>
  `/warranty/focus/api/v2/admin/claims/update/status/reject-list`;
export const POST_CLAIM_REJECT = () => `/warranty/focus/api/v2/admin/claims/update/status/reject`;
export const POST_UPDATE_PRODUCT = () => `/warranty/focus/api/v2/admin/claims/update/product`;
export const POST_CLAIM_UPDATE = () => `/warranty/focus/api/v2/admin/claims/update/info`;
export const POST_CLAIMS_INSERT = () => `/warranty/focus/api/v2/admin/claims/insert`;
export const POST_CLAIMS_CANCEL = () => `/warranty/focus/api/v2/admin/claims/update/status/cancel`;
export const POST_CLAIMS_UPDATE_PROCESS_STATUS = () =>
  `/warranty/focus/api/v2/admin/claims/update/process`;

//user info
export const GET_USER_INFO = (obj) =>
  `/warranty/focus/api/v2/admin/customers/full-search/?${getQueryStringIgnoreEmpty(obj)}`;
export const POST_SEND_MESSAGE_USER_INFO = () =>
  `/warranty/focus/api/v2/admin/customers/send-msg/line`;
export const POST_RESET_LINE = () => `/warranty/focus/api/v2/admin/customers/reset/lineuid`;
export const POST_UPDATE_USER_INFO = () => `/warranty/focus/api/v2/admin/customers/update/info`;
export const POST_USER_TEL = () => `/warranty/focus/api/v2/admin/customers/update/tel`;

//store shop
export const GET_STORE_FULL_SEARCH = (obj) =>
  `/warranty/focus/api/v2/admin/stores/full-search?${getQueryStringIgnoreEmpty(obj)}`;
export const POST_DESTROT_STORE = () => `/warranty/focus/api/v2/admin/stores/destroy`;
export const POST_INSERT_STORE = () => `/warranty/focus/api/v2/admin/stores/insert`;
export const POST_UPDATE_STORE = () => `/warranty/focus/api/v2/admin/stores/update/info`;

//Color QR
export const GET_COLOR_FULL_SEARCH = (obj) =>
  `/warranty/focus/api/v2/admin/options/categories/full-search?${getQueryStringIgnoreEmpty(obj)}`;
export const POST_INSERT_COLOR = () => '/warranty/focus/api/v2/admin/options/categories/insert';
export const POST_DESTROT_COLOR = () => `/warranty/focus/api/v2/admin/options/categories/destroy`;
export const POST_EDIT_COLOR = () => '/warranty/focus/api/v2/admin/options/categories/update';

//Brand
export const GET_BRAND_FULL_SEARCH = (obj) =>
  `/warranty/focus/api/v2/admin/options/mobile-brands/full-search?${getQueryStringIgnoreEmpty(
    obj,
  )}`;
export const POST_INSERT_BRAND = () =>
  '/warranty/focus/api/v2/admin/options/mobile-brands/update/status';
export const POST_UPDATE_STATUS_BRAND = () =>
  `/warranty/focus/api/v2/admin/options/mobile-brands/update/status`;

//Model
export const GET_MODEL_BRAND = (mobileBrandCode) =>
  `/warranty/focus/api/v2/admin/options/mobile-models/brand/${mobileBrandCode}`;
export const POST_INSERT_MODEL = () =>
  '/warranty/focus/api/v2/admin/options/mobile-models/update/status';
export const GET_MAP_PRODUCT_FULL_SEARCH = (obj) =>
  `/warranty/focus/api/v2/admin/options/map-products/full-search?${getQueryStringIgnoreEmpty(obj)}`;
export const POST_UPDATE_STATUS_MODEL = () =>
  `/warranty/focus/api/v2/admin/options/mobile-models/update/status`;

//product
export const POST_DESTROT_PRODUCT = () => `/warranty/focus/api/v2/admin/options/products/destroy`;
export const GET_EDIT_MANAGEMENT_PAGE = (mapProductId) =>
  `/warranty/focus/api/v2/admin/options/map-products/id/${mapProductId}`;
export const GET_EDIT_MANAGEMENT = (mapProductId) =>
  `/warranty/focus/api/v2/admin/options/map-products/special-warranties/${mapProductId}`;
export const POST_UPDATE_MAP_PRODUCT = () =>
  `/warranty/focus/api/v2/admin/options/map-products/update`;
export const POST_INSERT_MANAGEMENT_DATE = () =>
  '/warranty/focus/api/v2/admin/options/map-products/special-warranties/insert';
export const POST_EDIT_MANAGEMENT_DATE = () =>
  '/warranty/focus/api/v2/admin/options/map-products/special-warranties/update';
export const POST_DESTROY_MANAGEMENT_DATA = () =>
  `/warranty/focus/api/v2/admin/options/map-products/special-warranties/destroy`;

// scan delivery
export const GET_SCAN_DELIVERY = (claimCode) =>
  `/warranty/focus/api/v2/admin/claims/tracking/${claimCode}`;
export const GET_WARRANTY_QR = (warrantyNumberCode) =>
  `/warranty/focus/api/v2/admin/serial-number/verify/${warrantyNumberCode}`;
export const POST_SCAN_DELIVERY = () => `/warranty/focus/api/v2/admin/tracking/update/bulk`;

// cut of warranty
export const POST_CUT_OF_WARRANTY = () => `/warranty/focus/api/v2/admin/claims/claim-or-warranty`;
export const GET_STORE_CUT_OFF = (obj) =>
  `/warranty/focus/api/v2/admin/stores?${getQueryStringIgnoreEmpty(obj)}`;
export const POST_SUBMIT_CUT_OF_WARRANTY = () =>
  `/warranty/focus/api/v2/admin/claims/update/cut-off/bulk`;

// GEN QR
export const GET_GEN_QR_CODE_FULL_SEARCH = (obj) =>
  `/warranty/focus/api/v2/admin/serial-number/task/full-search?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_SERIAL_NUMBER_QR = (obj) =>
  `/warranty/focus/api/v2/admin/serial-number/task/download/serial-number?${getQueryStringIgnoreEmpty(
    obj,
  )}`;
export const POST_INSERT_QR_CODE = () => `/warranty/focus/api/v2/admin/serial-number/task/insert`;

// OPTIONS
export const GET_SHIPPING_SELF = () => `/warranty/focus/api/v2/admin/options/shipping`;

export const GET_CHANNEL_BUYS = () => `/warranty/focus/api/v2/admin/options/channel-buys`;
export const GET_WARRANTY_TYPES = () => `/warranty/focus/api/v2/admin/options/warranty-types`;
export const GET_CLAIMS_ZIPCODE = (zipcode) =>
  `/warranty/focus/api/v2/admin/options/addresses/zipcode/${zipcode}`;
export const GET_CLAIMS_CUSTOMER = (telephone) =>
  `/warranty/focus/api/v2/admin/claims/customers/verify/${telephone}`;
export const GET_CUSTOMER_GROUPS = () => `/warranty/focus/api/v2/admin/options/customer-groups`;
export const GET_STORE_TYPE = () => `/warranty/focus/api/v2/admin/stores/types`;
export const GET_MOBILE_BRAND = () => `/warranty/focus/api/v2/admin/options/mobile-brands`;
export const GET_CATEGORY_LIST = () => `/warranty/focus/api/v2/admin/options/categories`;
export const GET_MODEL_BY_ID = (mobileBrandId) =>
  `/warranty/focus/api/v2/admin/options/mobile-models/brand/id/${mobileBrandId}`;
export const GET_PRODUCT_TYPE = () => `/warranty/focus/api/v2/admin/options/products/types`;
export const GET_PRODUCT_GROUP_CODE = () => `/warranty/focus/api/v2/admin/options/products/groups`;
export const GET_STORE_TYPE_CHANNEL = (channelBuyType) =>
  `/warranty/focus/api/v2/admin/stores/store-type/${channelBuyType}`;
export const GET_CHANNEL_CLAIMS = () => `/warranty/focus/api/v2/admin/options/channel-claims`;
export const GET_STORE_TYPE_CLAIMS = (channelClaimType) =>
  `/warranty/focus/api/v2/admin/stores/store-type/${channelClaimType}`;
export const GET_PROCESS_STATUS = () => `/warranty/focus/api/v2/admin/options/status/claim-process`;

//report
export const GET_REPORT_HISTORY_BY_DATE = (obj) =>
  `/warranty/focus/api/v2/admin/reports/claim/history/by-date?startDate?${getQueryStringIgnoreEmpty(
    obj,
  )}`;
export const GET_REPORT_HISTORY_FULL = (obj) =>
  `/warranty/focus/api/v2/admin/reports/claim/history/full-search?${getQueryStringIgnoreEmpty(
    obj,
  )}`;
export const GET_REPORT_CHANGE_BY_DATE = (obj) =>
  `/warranty/focus/api/v2/admin/reports/claim/change-product/by-date?${getQueryStringIgnoreEmpty(
    obj,
  )}`;
export const GET_REPORT_CHANGE_FULL = (obj) =>
  `/warranty/focus/api/v2/admin/reports/claim/change-product/full-search?${getQueryStringIgnoreEmpty(
    obj,
  )}`;

// User Permission
export const GET_USER_PERMISSION = (obj) =>
  `/warranty/focus/api/v2/admin/auth/users?${getQueryStringIgnoreEmpty(obj)}`;
export const GET_USER_PRIVILEGE = (userId) =>
  `/warranty/focus/api/v2/admin/auth/users/privileges/for-user/${userId}`;
export const POST_UPDATE_USER_PERMISSION = () =>
  `/warranty/focus/api/v2/admin/auth/users/update/privilege`;
export const GET_USER_TYPES = () => `/warranty/focus/api/v2/admin/auth/user-types`;
export const POST_INSERT_TYPES = () => `/warranty/focus/api/v2/admin/auth/users/update/privilege`;
