import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { GET, GET_STORE_FULL_SEARCH } from '../../../services';

export const useGetStore = ({ handleSubmit }) => {
  const [data, setData] = useState([]);

  const { isPending, mutate: onSearchFull } = useMutation({
    mutationFn: async (searchParamState) => {
      const { search } = searchParamState;
      const response = await GET(GET_STORE_FULL_SEARCH({ search: search || '' }));
      return response.result.items || [];
    },
    onSuccess: (response) => {
      setData(response);
    },
  });

  const onFetch = () =>
    handleSubmit((values) => {
      return onSearchFull(values);
    })();

  return { dataSource: data, setData, onSearchFull, loading: isPending, onFetch };
};
