import React from 'react';
import { StyledContainer } from '../../../components/container/styles';
import { TextLarge } from '../../../components/text';
import { Col, Row, Spin } from 'antd';
import { CardTheme } from '../../../components/card';
import { ButtonTheme, RenderForm } from '../../../components';
import { FORM_PRODUCT_REGISTRATION_WARRANTY, FORM_USER_REGISTRATION_WARRANTY } from './form';
import { ContainerButton } from '../../../styles/global-style';
import { useGetWarrantyViewList } from './hooks/useGetWarrantyViewList';

export const WarrantyView = () => {
  const { control, setValue, getValues, loadingWarrantyView, goBack, storeId } =
    useGetWarrantyViewList();

  return (
    <Spin spinning={loadingWarrantyView}>
      <StyledContainer>
        <TextLarge text="แก้ไขข้อมูลรับประกัน" />
        <Row gutter={16}>
          <Col span={14}>
            <CardTheme>
              <TextLarge text="ลูกค้าลงทะเบียนรับประกัน" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_USER_REGISTRATION_WARRANTY()}
              />
            </CardTheme>
          </Col>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="สินค้าลงทะเบียนรับประกัน" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_PRODUCT_REGISTRATION_WARRANTY({ storeId })}
              />
            </CardTheme>
          </Col>
          <ContainerButton left>
            <ButtonTheme useFor="BACK" title="ย้อนกลับ" onClick={goBack} />
          </ContainerButton>
        </Row>
      </StyledContainer>
    </Spin>
  );
};
