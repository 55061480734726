import React from 'react';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../../components';
import { TextLarge, TextSmall } from '../../../../components/text';
import { FORM_PROCESS_STATUS } from '../form';
import { ContainerButton } from '../../../../styles/global-style';
import { useForm } from 'react-hook-form';
import { processStatusQuery } from '../../../../services/react-query/optionMasterQuery';
import { useProcessStatus } from '../hooks/useProcessStatus';
import { UpdateProcessStatus } from '../../../../resources/payload';
import { useEffect } from 'preact/hooks';

export const ProcessStatus = ({ open, onToggle, claimData, onFetch }) => {
  const { control, setValue, getValues, handleSubmit } = useForm();
  const { data: processStatusList } = processStatusQuery();
  const { mutate: onSubmitProcessStatus } = useProcessStatus({ onSuccessCallBack: onFetch });

  useEffect(() => {
    setValue('claimId', claimData.claimId);
    setValue('processStatus', claimData.processStatus);
  }, [claimData]);

  const onSubmit = (values) => {
    const payload = UpdateProcessStatus(values, claimData);
    onSubmitProcessStatus(payload);
    onToggle({ defaultValues: '' });
  };

  return (
    <ModalTheme title=" " open={open} onToggle={onToggle}>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <TextLarge text="แก้ไขสถานะภายใน" />
      </div>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <TextSmall text={claimData.claimCode + ' ' + ' ' + claimData.fullName} />
      </div>
      <RenderForm
        control={control}
        forms={FORM_PROCESS_STATUS({ processStatusList })}
        setValue={setValue}
        getValues={getValues}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
