import {
  createColumnDate,
  createColumnNumber,
  createColumnText,
  createImageColumn,
  widthOptions,
} from '../../../components/table/function';

export const columns = () => {
  return [
    {
      ...createColumnNumber('ลำดับ', 'rowNumber', widthOptions(100)),
      render: (text, record) => (
        <span style={{ color: record?.totalClaims > 5 ? '#ff4d4f' : '#000' }}>{text}</span>
      ),
    },
    {
      ...createColumnText('ชื่อ-นามสกุล', 'fullName', widthOptions(150)),
      render: (text, record) => (
        <span style={{ color: record?.totalClaims > 5 ? '#ff4d4f' : '#000' }}>{text}</span>
      ),
    },

    {
      ...createColumnText('เบอร์โทร', 'telephone', widthOptions(150)),
      render: (text, record) => (
        <span style={{ color: record?.totalClaims > 5 ? '#ff4d4f' : '#000' }}>{text}</span>
      ),
    },
    {
      ...createColumnNumber('จำนวนการเคลม', 'totalClaims', widthOptions(150)),
      render: (text, record) => (
        <span style={{ color: record?.totalClaims > 5 ? '#ff4d4f' : '#000' }}>{text}</span>
      ),
    },
    {
      ...createColumnDate('วันที่เคลมล่าสุด', 'lastClaimDate', widthOptions(150)),
      render: (text, record) => (
        <span style={{ color: record?.totalClaims > 5 ? '#ff4d4f' : '#000' }}>{text}</span>
      ),
    },
  ];
};

export const columnsExpandable = () => {
  return [
    createImageColumn('รูปภาพสินค้าเคลม', 'claimImageUrl', {
      width: 160,
      align: 'center',
      preview: true,
    }),
    createImageColumn('รูปภาพสลิปโอนเงิน', 'receiptImageUrl', {
      width: 160,
      align: 'center',
      preview: true,
    }),
    createColumnText('เลขที่การเคลม', 'claimCode', widthOptions(150)),
    createColumnText('ชื่อสินค้า', 'productName', widthOptions(150)),
    createColumnDate('วันที่แจ้งเคลม', 'claimDate', widthOptions(150)),
    createColumnText('อาการเสีย', 'breakdownName', widthOptions(150)),
    createColumnText('EMS จัดส่ง', 'trackingNumber', widthOptions(150)),
    createColumnText('ประเภทการเคลม', 'claimType', widthOptions(150)),
  ];
};
