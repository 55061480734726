import { Popconfirm } from 'antd';
import { ButtonTheme, ButtonToolTip } from '../../../../components';
import {
  createColumnButtonArr,
  createColumnDate,
  createColumnNumber,
  createColumnTag,
  createColumnText,
  formatNumber,
  widthOptions,
} from '../../../../components/table/function';
import { CHANNEL_BUY, WARRANTY_STATUS } from '../../../../resources/status';
import { ROUTES_PATH } from '../../../../resources/routes-name';

export const columns = ({ handleDelete, privilegeFocusType }) => {
  return [
    createColumnTag(
      'สถานะ',
      { key: 'warrantyStatus', text: 'warrantyStatus', icon: true },
      WARRANTY_STATUS,
    ),
    createColumnText('เลขที่รับประกัน', 'warrantyCode', widthOptions(150)),
    createColumnText('ชื่อ-นามสกุล', 'fullName', widthOptions(150)),
    createColumnText('ชื่อไลน์', 'lineName', widthOptions(120)),
    createColumnText('เบอร์ติดต่อ', 'telephone', widthOptions(150)),
    createColumnText('รหัสสินค้า', 'itemCode', widthOptions(210)),
    createColumnText('ชื่อสินค้า', 'productName', widthOptions(150)),
    createColumnText('รหัสรับประกัน', 'warrantyNumberCode', widthOptions(160)),
    createColumnText('แบรนด์', 'mobileBrandName', widthOptions(100)),
    createColumnText('รุ่น', 'mobileModelName', widthOptions(150)),
    createColumnTag(
      'ช่องทางการซื้อ',
      { key: 'channelBuyType', text: 'channelBuyName' },
      CHANNEL_BUY,
      {
        width: '160px',
      },
    ),
    createColumnText('ร้านค้า', 'storeName', widthOptions(150)),
    createColumnDate('วันที่ลงทะเบียน', 'createDate', widthOptions(170)),
    createColumnDate('วันหมดอายุ', 'expireDate', widthOptions(150)),
    createColumnNumber(
      'วันคงเหลือ',
      'remainingDays',
      widthOptions(150, {
        render: (value) => {
          if (value > 100000) {
            return <div>ตลอดชีวิต</div>;
          } else if (value === 0) {
            return <div>0</div>;
          } else if (value < 0) {
            return <div>หมดอายุ</div>;
          } else {
            return formatNumber(value);
          }
        },
      }),
    ),

    ...(privilegeFocusType === 'READ'
      ? [
          createColumnButtonArr('จัดการ', 'rowNo', {
            others: {
              fixed: 'right',
              width: 170,
            },
            buttonArr: [
              {
                useFor: (record) => (record.warrantyStatus === 'CLAIMING' ? 'DETAIL' : 'EDIT3'),
                render: (text, record) => {
                  return (
                    <ButtonToolTip toolTip="ดูรายละเอียด">
                      <ButtonTheme
                        useFor="DETAIL"
                        onClick={() => {
                          const url = `${ROUTES_PATH.ROUTE_WARRANTY_VIEW.PATH}?code=${record.warrantyCode}`;
                          window.open(url, '_blank');
                        }}
                      />
                    </ButtonToolTip>
                  );
                },
              },
            ],
          }),
        ]
      : [
          createColumnButtonArr('จัดการ', 'rowNo', {
            others: {
              fixed: 'right',
              width: 170,
            },
            buttonArr: [
              {
                useFor: (record) => (record.warrantyStatus === 'CLAIMING' ? 'DETAIL' : 'EDIT3'),
                render: (text, record) => {
                  if (record.warrantyStatus === 'COMPLETED') {
                    return (
                      <ButtonToolTip toolTip="ดูรายละเอียด">
                        <ButtonTheme
                          useFor="DETAIL"
                          onClick={() => {
                            const url = `${ROUTES_PATH.ROUTE_WARRANTY_VIEW.PATH}?code=${record.warrantyCode}`;
                            window.open(url, '_blank');
                          }}
                        />
                      </ButtonToolTip>
                    );
                  }
                  return (
                    <ButtonToolTip toolTip="แก้ไข">
                      <ButtonTheme
                        useFor="EDIT2"
                        onClick={() => {
                          const url = `${ROUTES_PATH.ROUTE_WARRANTY_EDIT.PATH}?code=${record.warrantyCode}`;
                          window.open(url, '_blank');
                        }}
                      />
                    </ButtonToolTip>
                  );
                },
              },
              {
                useFor: 'DELETE',
                render: (text, record) => {
                  if (record.warrantyStatus !== 'ACTIVE') {
                    return null;
                  }
                  return (
                    <ButtonToolTip toolTip="ยกเลิกรับประกัน" placement="topLeft">
                      <Popconfirm
                        title="คุณต้องการยกเลิกการรับประกันหรือไม่?"
                        onConfirm={() => handleDelete(record)}
                        okText="ใช่"
                        cancelText="ไม่"
                      >
                        <ButtonTheme useFor="DELETE" />
                      </Popconfirm>
                    </ButtonToolTip>
                  );
                },
              },
            ],
          }),
        ]),
  ];
};

export const exportHeaders = [
  { dataIndex: 'warrantyCode', title: 'เลขที่รับประกัน' },
  { dataIndex: 'warrantyNumberCode', title: 'รหัสรับประกัน' },
  { dataIndex: 'categoryCode', title: 'หมวดหมู่' },
  { dataIndex: 'warrantyStatus', title: 'สถานะ' },
  { dataIndex: 'mobileBrandName', title: 'แบรนด์' },
  { dataIndex: 'mobileModelName', title: 'รุ่น' },
  { dataIndex: 'itemCode', title: 'รหัสสินค้า' },
  { dataIndex: 'productName', title: 'ชื่อสินค้า' },
  { dataIndex: 'channelBuyName', title: 'ช่องทางการซื้อ' },
  { dataIndex: 'fullName', title: 'ชื่อ-นามสกุล' },
  { dataIndex: 'telephone', title: 'เบอร์โทร' },
  { dataIndex: 'email', title: 'อีเมลล์' },
  { dataIndex: 'createDate', title: 'วันที่ลงทะเบียน' },
  { dataIndex: 'claimCode', title: 'เลขที่เคลม' },
  { dataIndex: 'claimRemark', title: 'สาเหตุการเคลม' },
  { dataIndex: 'claimDate', title: 'วันที่เคลม' },
  { dataIndex: 'lineUid', title: 'ไลน์ UID' },
];
