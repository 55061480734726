import { useMutation } from '@tanstack/react-query';
import { POST, POST_APPROVE_CLAIM } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';

export const useApproveClaim = ({ selectedRowKeys, onSuccessCallback }) =>
  useMutation({
    mutationFn: async () => {
      const payload = {
        claimIdList: selectedRowKeys,
      };
      const response = await POST(POST_APPROVE_CLAIM(), payload);
      return response;
    },

    onSuccess: () => {
      openAlert({ model: 'message', type: 'success', message: 'อนุมัติเรื่องเคลมสำเร็จ' });
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: (response) => {
      openAlert({
        model: 'message',
        type: 'error',
        message: response?.message || 'เกิดข้อผิดพลาด',
      });
    },
  });
