import React from 'react';
import { Layout } from 'antd';
const { Footer } = Layout;
import { color, VERSION } from '../../../../resources';

const FooterLayout = () => {
  return (
    <Footer style={{ textAlign: 'center', background: color.blackFocus, color: color.whiteFocus }}>
      ZettaSoft @2024 version {VERSION}
    </Footer>
  );
};

export default FooterLayout;
