import { useForm } from 'react-hook-form';
import { useSendMessageLine } from '../hooks/useSendMessage';
import { FORM_MESSAGE } from '../form';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../components';
import { TextLarge, TextSmall } from '../../../components/text';
import { ContainerButton } from '../../../styles/global-style';
import { useEffect } from 'preact/hooks';

export const ModalMessage = ({ open, onToggle, initialValues }) => {
  const { control, setValue, handleSubmit } = useForm();

  const { mutate: sendMessage } = useSendMessageLine();

  useEffect(() => {
    if (initialValues) {
      setValue('customerId', initialValues.customerId);
    }
  }, [initialValues]);

  const onSubmit = (values) => {
    sendMessage(values);
    onToggle({ defaultValues: '' });
  };

  return (
    <ModalTheme title=" " open={open} onToggle={onToggle}>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <TextLarge text="ส่งข้อความ" />
      </div>
      <div>
        <TextSmall text={'ชื่อ : ' + initialValues.fullName} />
        <TextSmall text={'รหัสลูกค้า : ' + initialValues.customerCode} />
      </div>
      <RenderForm control={control} forms={FORM_MESSAGE()} setValue={setValue} />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
