import React from 'react';
import { StyledContainer } from '../../components/container/styles';
import { TextLarge } from '../../components/text';
import { CardTheme } from '../../components/card';
import { ResponsivePageLayout } from '../../components/page/responsive-layout/ResponsivePageLayout';
import { ContainerButton } from '../../styles/global-style';
import { ButtonTheme } from '../../components';
import { useGetUserPermissionList } from './hooks/useGetUserPermissionList';
import { Spin } from 'antd';
import { SetUpPermission } from './modal/SetUpPermission';

export const UserPermission = () => {
  const {
    formSearch,
    formSearchObj,
    dataTable,
    isPending,
    onSelectChange,
    selectedRowKeys,
    columnsData,
    onFetch,
    handleSubmitAccident,
    isPendingUpdateUser,
    openConfirmModal,
    loadingUserList,
    openAdd,
    onToggleAdd,
  } = useGetUserPermissionList();

  return (
    <Spin spinning={isPendingUpdateUser || loadingUserList}>
      <StyledContainer>
        <TextLarge text="ปรับสิทธิ์เมนู" />
        <CardTheme>
          <ResponsivePageLayout
            searchLayout={{
              title: 'ค้นหาข้อมูล',
              formSearch,
              useFormParam: formSearchObj,
              ignoreDateEmpty: true,
              onSearch: onFetch,
            }}
            tableLayout={{
              columns: columnsData,
              dataSource: dataTable,
              loading: isPending,
              hasPagination: true,
              rowKey: 'id',
              rowSelection: {
                selectedRowKeys,
                onChange: onSelectChange,
              },
              extraContent: (
                <ContainerButton right>
                  <ButtonTheme
                    useFor="ADD_PERMISSION"
                    title="เปลี่ยนสิทธิ์"
                    onClick={onToggleAdd}
                  />
                  {openAdd && (
                    <SetUpPermission open={openAdd} onToggle={onToggleAdd} onFetch={onFetch} />
                  )}
                  <ButtonTheme
                    useFor="REGISTER2"
                    title="บันทึก"
                    onClick={() => openConfirmModal({ onOk: handleSubmitAccident })}
                    disabled={selectedRowKeys.length === 0}
                    style={{ marginBottom: '30px' }}
                  />
                </ContainerButton>
              ),
            }}
          />
        </CardTheme>
      </StyledContainer>
    </Spin>
  );
};
