import { useQuery } from '@tanstack/react-query';
import {
  GET,
  GET_CATEGORY_LIST,
  GET_CHANNEL_BUYS,
  GET_CHANNEL_CLAIMS,
  GET_CUSTOMER_GROUPS,
  GET_GEN_QR_CODE_FULL_SEARCH,
  GET_MOBILE_BRAND,
  GET_MODEL_BY_ID,
  GET_PROCESS_STATUS,
  GET_PRODUCT_GROUP_CODE,
  GET_PRODUCT_TYPE,
  GET_SHIPPING_SELF,
  GET_STORE_CUT_OFF,
  GET_STORE_TYPE,
  GET_STORE_TYPE_CHANNEL,
  GET_STORE_TYPE_CLAIMS,
  GET_USER_PERMISSION,
  GET_USER_TYPES,
  GET_WARRANTY_TYPES,
} from '..';
import { serviceOptions } from '../../functions/serviceOption';

export const channelBuyQuery = () =>
  useQuery({
    queryKey: ['channelBuyQuery'],
    queryFn: async () => {
      const response = await GET(GET_CHANNEL_BUYS());
      return serviceOptions('CHANNEL_BUY', response.result.items);
    },
  });

export const warrantyTypeQuery = () =>
  useQuery({
    queryKey: ['warrantyTypeQuery'],
    queryFn: async () => {
      const response = await GET(GET_WARRANTY_TYPES());
      return serviceOptions('WARRANTY_TYPE', response.result.items);
    },
  });

export const shippingSelfQuery = () =>
  useQuery({
    queryKey: ['shippingSelfQuery'],
    queryFn: async () => {
      const response = await GET(GET_SHIPPING_SELF());
      return serviceOptions('SHIPPING_SELF', response.result.items);
    },
  });

export const customerGroupsUserQuery = () =>
  useQuery({
    queryKey: ['customerGroupsUserQuery'],
    queryFn: async () => {
      const response = await GET(GET_CUSTOMER_GROUPS());
      return serviceOptions('CUSTOMER_GROUP_USER', response.result.items);
    },
  });

export const storeTypeQuery = () =>
  useQuery({
    queryKey: ['storeTypeQuery'],
    queryFn: async () => {
      const response = await GET(GET_STORE_TYPE());
      return serviceOptions('STORE_TYPE', response.result.items);
    },
  });

export const mobileBrandCodeQuery = () =>
  useQuery({
    queryKey: ['mobileBrandCodeQuery'],
    queryFn: async () => {
      const response = await GET(GET_MOBILE_BRAND());
      return serviceOptions('MOBILE_BRAND_CODE', response.result.items);
    },
  });

export const mobileBrandQuery = () =>
  useQuery({
    queryKey: ['mobileBrandQuery'],
    queryFn: async () => {
      const response = await GET(GET_MOBILE_BRAND());
      return serviceOptions('MOBILE_BRAND', response.result.items);
    },
  });

export const categoryQuery = () =>
  useQuery({
    queryKey: ['categoryQuery'],
    queryFn: async () => {
      const response = await GET(GET_CATEGORY_LIST());
      return serviceOptions('CATEGORY_LIST', response.result.items);
    },
  });

export const brandQuery = () =>
  useQuery({
    queryKey: ['brandQuery'],
    queryFn: async () => {
      const response = await GET(GET_MOBILE_BRAND());
      return serviceOptions('BRAND_LIST', response?.result?.items);
    },
  });

export const modelQuery = (mobileBrandId) =>
  useQuery({
    queryKey: ['modelQuery', `${mobileBrandId}`],
    queryFn: async () => {
      const response = await GET(GET_MODEL_BY_ID(mobileBrandId || ''));
      return serviceOptions('MODEL_LIST', response?.result?.items);
    },
  });

export const productTypeQuery = () =>
  useQuery({
    queryKey: ['productTypeQuery'],
    queryFn: async () => {
      const response = await GET(GET_PRODUCT_TYPE());
      return serviceOptions('PRODUCT_TYPE', response.result.items);
    },
  });

export const productGroupCodeQuery = () =>
  useQuery({
    queryKey: ['productGroupCodeQuery'],
    queryFn: async () => {
      const response = await GET(GET_PRODUCT_GROUP_CODE());
      return serviceOptions('PRODUCT_CODE', response.result.items);
    },
  });

export const storeTypeChannelQuery = (channelBuyType) =>
  useQuery({
    queryKey: ['storeTypeChannelQuery', `${channelBuyType}`],
    queryFn: async () => {
      const response = await GET(GET_STORE_TYPE_CHANNEL(channelBuyType || ''));
      return serviceOptions('STORE_TYPE_CHANNEL', response.result.items);
    },
  });

export const channelClaimQuery = () =>
  useQuery({
    queryKey: ['channelClaimQuery'],
    queryFn: async () => {
      const response = await GET(GET_CHANNEL_CLAIMS());
      return serviceOptions('CHANNEL_CLAIMS', response.result.items);
    },
  });

export const storeTypeClaimsQuery = (channelClaimType) =>
  useQuery({
    queryKey: ['storeTypeChannelQuery', `${channelClaimType}`],
    queryFn: async () => {
      const response = await GET(GET_STORE_TYPE_CLAIMS(channelClaimType || ''));
      return serviceOptions('STORE_TYPE_CHANNEL', response.result.items);
    },
  });

export const processStatusQuery = () =>
  useQuery({
    queryKey: ['processStatusQuery'],
    queryFn: async () => {
      const response = await GET(GET_PROCESS_STATUS());
      return serviceOptions('PROCESS_STATUS', response.result.items);
    },
  });

export const userPermissionQuery = () =>
  useQuery({
    queryKey: ['userPermissionQuery'],
    queryFn: async () => {
      const response = await GET(GET_USER_PERMISSION());
      return serviceOptions('USER', response.result.items);
    },
  });

export const storeCutOffQuery = (obj) =>
  useQuery({
    queryKey: ['storeCutOffQuery', `${obj}`],
    queryFn: async () => {
      const response = await GET(GET_STORE_CUT_OFF(obj));
      return serviceOptions('STORE_CUT_OFF', response.result.items);
    },
  });

export const genQrCodeFullSearchQuery = () =>
  useQuery({
    queryKey: ['genQrCodeFullSearchQuery'],
    queryFn: async () => {
      const response = await GET(GET_GEN_QR_CODE_FULL_SEARCH());
      return response.result.items;
    },
  });

export const userTypeQuery = () =>
  useQuery({
    queryKey: ['userTypeQuery'],
    queryFn: async () => {
      const response = await GET(GET_USER_TYPES());
      return serviceOptions('USER_TYPE', response.result.items);
    },
  });
