import React from 'react';
import { SpaceBetween, StyledContainer } from '../../../components/container/styles';
import { TextLarge } from '../../../components/text';
import { ResponsivePageLayout } from '../../../components/page/responsive-layout/ResponsivePageLayout';
import { Icon } from '../../../resources';
import { useGetBrandList } from './hooks/useGetBrandList';
import { ContainerButton } from '../../../styles/global-style';
import ButtonExcelEmbedded from '../../../components/report-excel/ButtonExcelEmbedded';

export const Brand = () => {
  const { columnData, formSearch, formSearchObj, onFetch, dataBrand, isLoadingBrandList } =
    useGetBrandList();

  return (
    <StyledContainer>
      <TextLarge>แบรนด์มือถือ</TextLarge>
      <ResponsivePageLayout
        searchLayout={{
          title: 'ค้นหาแบรนด์มือถือ',
          icon: <Icon.todo />,
          formSearch,
          useFormParam: formSearchObj,
          onSearch: onFetch,
        }}
        tableLayout={{
          columns: columnData,
          dataSource: dataBrand,
          loading: isLoadingBrandList,
          hasPagination: true,
          extraContent: (
            <SpaceBetween>
              <ContainerButton right>
                <ButtonExcelEmbedded
                  title="Export"
                  header={columnData}
                  data={dataBrand}
                  filename="รายงานแบรนด์มือถือ"
                />
              </ContainerButton>
            </SpaceBetween>
          ),
        }}
      />
    </StyledContainer>
  );
};
