import React from 'react';
import { Image } from 'antd';
import Notfound from '../assets/images/notFound.jpg';

export const CustomImage = ({ src, alt = 'Image', preview = true, style, width, ...rest }) => {
  const [imageSrc, setImageSrc] = React.useState(src);

  const handleError = () => {
    setImageSrc(Notfound);
  };

  return (
    <Image
      src={imageSrc}
      alt={alt}
      preview={preview}
      onError={handleError}
      style={{
        objectFit: 'cover',
        width: width || '100%',
        height: 'auto',
        ...style,
      }}
      {...rest}
    />
  );
};
