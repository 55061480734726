import React from 'react';
import { ModalTheme } from '../../../../components/modal/index.jsx';
import { useForm } from 'react-hook-form';
import { ButtonTheme, RenderForm } from '../../../../components/index.js';
import { ContainerButton } from '../../../../styles/global-style.js';
import { FORM_EDIT_EXPIRED } from '../form/index.js';
import { useEffect } from 'preact/hooks';
import { useUpdateExpired } from '../hooks/useUpdateExpired.js';

export const EditExpiredModal = ({ open, onToggle, dataEdit }) => {
  const { control, setValue, getValues, handleSubmit } = useForm();

  const { mutate: updateExpired } = useUpdateExpired();

  useEffect(() => {
    setValue('warrantyId', dataEdit?.warrantyId);
    setValue('expireDate', dataEdit?.expireDate);
  }, [dataEdit]);

  const onSubmit = (value) => {
    updateExpired(value);
    onToggle({ defaultValues: '' });
  };

  return (
    <ModalTheme title="แก้ไขวันหมดอายุ" open={open} onToggle={onToggle}>
      <RenderForm
        control={control}
        forms={FORM_EDIT_EXPIRED()}
        setValue={setValue}
        getValues={getValues}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
