import React from 'react';
import { CardListComponent } from '../../../card-list/index.jsx';
import { useResponsive } from '../../../../hooks/useResponsive.js';
import TableComponent from '../../../table/index.jsx';
import { CardTheme } from '../../../card/index.jsx';
import { ConfigProvider } from 'antd';

export const ResponsiveTableLayout = ({
  style,
  columns,
  dataSource,
  rowSelection,
  extraContent,
  hasPagination,
  rowKey,
  components,
  ...rest
}) => {
  const { isMobile } = useResponsive();

  let tableTheme = {
    components: {
      Table: {
        rowSelectedBg: '#e6f7ff',
        rowSelectedHoverBg: '#e6f7ff',
        rowHoverBg: '#e6f7ff',
      },
    },
  };

  return isMobile ? (
    <>
      {extraContent}
      <CardListComponent columns={columns} dataSource={dataSource} {...rest} />
    </>
  ) : (
    <CardTheme
      style={{ marginTop: '2%', ...style }}
      content={
        <ConfigProvider theme={tableTheme}>
          {extraContent}
          <TableComponent
            rowKey={rowKey}
            columns={columns}
            dataSource={dataSource}
            hasPagination={hasPagination || false}
            rowSelection={rowSelection}
            components={components}
            {...rest}
          />
        </ConfigProvider>
      }
    />
  );
};
