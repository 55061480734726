import { useMutation } from '@tanstack/react-query';
import { POST, POST_DESTROT_COLOR } from '../../../../services';
import { DeleteColor } from '../../../../resources/payload';
import { openAlert } from '../../../../components/alert/hooks';

export const useDeleteColor = ({ onSuccessCallBack }) =>
  useMutation({
    mutationFn: async (value) => {
      const payload = DeleteColor(value);
      const response = await POST(POST_DESTROT_COLOR(), payload);

      if (onSuccessCallBack) onSuccessCallBack(response);

      return response;
    },
    onSuccess: () => {
      openAlert({ model: 'message', type: 'success', message: 'ลบข้อมูลสี QR สำเร็จ' });
    },
  });
