import { Popconfirm, QRCode } from 'antd';
import { ButtonTheme, ButtonToolTip } from '../../../components';
import {
  createColumnButtonArr,
  createColumnTag,
  createColumnText,
  widthOptions,
} from '../../../components/table/function';
import { STATUS_OPTIONS } from '../../../resources/status';

export const columns = ({ handleDeleteStore, handleEditStore, handleQrCodeStore }) => {
  return [
    createColumnTag(
      'สถานะ',
      { key: 'storeStatus', text: 'storeStatusName', icon: true },
      STATUS_OPTIONS,
      {
        width: '120px',
      },
    ),
    {
      title: 'QR Code',
      key: 'qrCode',
      align: 'center',
      width: 150,
      render: (_, record) => {
        const qrData = JSON.stringify({
          ...record?.qrCode,
        });
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <QRCode value={qrData} size={100} onClick={() => handleQrCodeStore(record)} />
          </div>
        );
      },
    },
    createColumnText('รหัสร้านค้า', 'customerAccountCode', widthOptions(150)),
    createColumnText('ชื่อร้านค้า', 'storeMasterName', widthOptions(150)),
    createColumnText('ประเภทร้านค้า', 'storeTypeNameTh', widthOptions(150)),
    createColumnText('เบอร์โทร', 'phoneNumber', widthOptions(150)),
    createColumnText('สาขา', 'storeName', widthOptions(150)),
    createColumnText('รหัส Sale', 'saleCode', widthOptions(150)),
    createColumnButtonArr('จัดการ', 'rowNo', {
      others: {
        fixed: 'right',
        width: 180,
      },
      buttonArr: [
        {
          useFor: 'EDIT2',
          render: (_, record) => (
            <ButtonToolTip toolTip="แก้ไข" placement="topLeft">
              <ButtonTheme useFor="EDIT2" onClick={() => handleEditStore(record)} />
            </ButtonToolTip>
          ),
        },
        {
          useFor: 'DELETE2',
          render: (_, record) => (
            <ButtonToolTip toolTip="ลบ" placement="topLeft">
              <Popconfirm
                title="คุณต้องการที่จะลบร้านค้าใช่หรือไม่?"
                onConfirm={() => handleDeleteStore(record)}
                okText="ใช่"
                cancelText="ไม่"
              >
                <ButtonTheme useFor="DELETE" />
              </Popconfirm>
            </ButtonToolTip>
          ),
        },
      ],
    }),
  ];
};
