export const FORM_SEARCH = ({ onEnter }) => {
  return [
    {
      name: 'search',
      type: 'INPUT',
      label: 'ค้นหารายการ (กรอกข้อมูลแล้วกด Enter เพื่อค้นหา)',
      placeholder: '',
      span: 13,
      helper:
        'Tips : สามารถค้นหาด้วย รหัสสี QR เช่น AA , รหัสแบรนด์,  รหัสรุ่นมือถือ ,Code G ,กลุ่มสินค้า (ItemG3) ,ประเภทสินค้า (Major Category)',
      onEnter,
    },
  ];
};

export const searchDefaultValues = {
  search: '',
};
