import React from 'react';
import { SpaceBetween, StyledContainer } from '../../../components/container/styles';
import { TextLarge } from '../../../components/text';
import { ContainerButton } from '../../../styles/global-style';
import { ButtonTheme } from '../../../components';
import { ResponsivePageLayout } from '../../../components/page/responsive-layout/ResponsivePageLayout';
import { Icon } from '../../../resources';
import ButtonExcelEmbedded from '../../../components/report-excel/ButtonExcelEmbedded';
import { useGetColorList } from './hooks/useGetColorList';
import { AddColor } from './modals/AddColor';
import { EditColor } from './modals/EditColor';

export const ColorQr = () => {
  const {
    formSearch,
    formSearchObj,
    columnData,
    dataColor,
    onFetch,
    isLoadingColorList,
    openAdd,
    onToggleAdd,
    openEdit,
    onToggleEdit,
    dataEdit,
    loadingDeleteColor,
  } = useGetColorList();

  return (
    <StyledContainer>
      <TextLarge>ค้นหาสี QR</TextLarge>
      <ContainerButton right>
        <ButtonTheme useFor="WARRANTY" title="เพิ่มสี QR" onClick={onToggleAdd} />
        {openAdd && <AddColor open={openAdd} onToggle={onToggleAdd} onFetch={onFetch} />}
      </ContainerButton>
      <ResponsivePageLayout
        searchLayout={{
          title: 'ค้นหาหมวดหมู่สินค้า',
          formSearch,
          useFormParam: formSearchObj,
          icon: <Icon.todo />,
          ignoreDateEmpty: true,
          onSearch: onFetch,
        }}
        tableLayout={{
          columns: columnData,
          dataSource: dataColor,
          loading: isLoadingColorList || loadingDeleteColor,
          extraContent: (
            <SpaceBetween>
              <ContainerButton right>
                <ButtonExcelEmbedded
                  title="Export"
                  header={columnData}
                  data={dataColor}
                  filename="รายงานสี QR"
                />
              </ContainerButton>
            </SpaceBetween>
          ),
        }}
      />
      {openEdit && (
        <EditColor
          open={openEdit}
          onToggle={onToggleEdit}
          onFetch={onFetch}
          initialValues={dataEdit}
        />
      )}
    </StyledContainer>
  );
};
