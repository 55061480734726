import React from 'react';
import { Tag } from 'antd';
import {
  createColumnButtonArr,
  createColumnDate,
  createColumnTag,
  createColumnText,
  widthOptions,
} from '../../../../components/table/function';
import { ButtonTheme, ButtonToolTip } from '../../../../components';
import { color } from '../../../../resources';
import { CLAIM_CHANNEL, CLAIM_STATUS, PROCESS_STATUS } from '../../../../resources/status';

export const columns = ({
  onOpenTracking,
  onOpenLineMessage,
  onOpenRemarkMessage,
  onOpenClaimEdit,
  onOpenClaimView,
  onModalProcessStatus,
  privilegeFocusType,
}) => {
  return [
    createColumnTag(
      'สถานะ',
      { key: 'claimStatus', text: 'claimStatusName', icon: true },
      CLAIM_STATUS,
      {
        width: '150px',
      },
    ),
    createColumnTag(
      'สถานะภายใน',
      { key: 'processStatus', text: 'processStatusName' },
      PROCESS_STATUS,
      {
        width: 220,
        render: (_, record) => {
          const status = PROCESS_STATUS?.find((s) => s.key === record?.processStatus);
          const handleClick = () => {
            onModalProcessStatus(record);
          };

          return (
            <div style={{ cursor: 'pointer' }} onClick={handleClick}>
              <Tag color={status?.color}>{status?.label}</Tag>
            </div>
          );
        },
        filters: PROCESS_STATUS?.map((status) => ({
          text: status?.label,
          value: status?.key,
        })),
        onFilter: (value, record) => record?.processStatus === value,
      },
    ),
    createColumnText('เลขที่เคลม', 'claimCode', widthOptions(150)),
    createColumnText('ชื่อ-นามสกุล', 'fullName', widthOptions(150)),
    createColumnText('ชื่อไลน์', 'lineName', widthOptions(140)),
    createColumnText('เบอร์ติดต่อ', 'telephone', widthOptions(150)),
    createColumnDate('วันที่แจ้ง', 'claimDate', widthOptions(150)),
    createColumnText('รหัสสินค้า', 'itemCode', widthOptions(140)),
    createColumnText('ชื่อสินค้า', 'productName', widthOptions(150)),
    createColumnText('รุ่นมือถือ', 'mobileModelName', widthOptions(130)),
    createColumnText(
      'เลขพัสดุ',
      'trackingNumber',
      widthOptions(150, {
        render: (text, record) => {
          const handleClick = () => {
            onOpenTracking(record);
          };
          if (record.trackingNumber) {
            return (
              <div style={{ cursor: 'pointer' }} onClick={handleClick}>
                <Tag color={color?.blueFocus}>{text}</Tag>
              </div>
            );
          }
        },
      }),
    ),
    createColumnDate('วันที่จัดส่ง', 'shippingDate', widthOptions(150)),
    createColumnDate('วันที่เข้ารับสินค้า', 'bookingDate', widthOptions(180)),
    createColumnTag(
      'ช่องทางการเคลม',
      { key: 'channelClaimType', text: 'channelClaimName' },
      CLAIM_CHANNEL,
      {
        width: 170,
      },
    ),
    createColumnText('สาขาที่เคลม', 'storeName', widthOptions(150)),
    createColumnText('หมายเหตุ', 'remark', widthOptions(140)),
    ...(privilegeFocusType === 'READ'
      ? [
          createColumnButtonArr('จัดการ', 'rowNo', {
            others: {
              fixed: 'right',
              width: 230,
            },
            buttonArr: [
              {
                useFor: 'DETAIL',
                render: (text, record) => {
                  const handleClick = () => {
                    onOpenClaimView(record);
                  };
                  return (
                    <ButtonToolTip toolTip="ดูรายละเอียด" placement="topLeft">
                      <ButtonTheme useFor="DETAIL" onClick={handleClick} />
                    </ButtonToolTip>
                  );
                },
              },
            ],
          }),
        ]
      : [
          createColumnButtonArr('จัดการ', 'rowNo', {
            others: {
              fixed: 'right',
              width: 230,
            },
            buttonArr: [
              {
                useFor: 'EDIT2',
                render: (text, record) => {
                  const handleClick = () => {
                    onOpenClaimEdit(record);
                  };
                  if (record.claimStatus !== 'TRANSPORTED') {
                    return (
                      <ButtonToolTip toolTip="แก้ไข" placement="topLeft">
                        <ButtonTheme useFor="EDIT2" onClick={handleClick} />
                      </ButtonToolTip>
                    );
                  }
                },
              },
              {
                useFor: 'DETAIL',
                render: (text, record) => {
                  const handleClick = () => {
                    onOpenClaimView(record);
                  };
                  if (record.claimStatus === 'TRANSPORTED') {
                    return (
                      <ButtonToolTip toolTip="ดูรายละเอียด" placement="topLeft">
                        <ButtonTheme useFor="DETAIL" onClick={handleClick} />
                      </ButtonToolTip>
                    );
                  }
                },
              },
              {
                useFor: 'LINE2',
                render: (text, record) => (
                  <ButtonToolTip toolTip="ส่งข้อความ" placement="topLeft">
                    <ButtonTheme
                      useFor="LINE2"
                      onClick={() => {
                        onOpenLineMessage(record);
                      }}
                    />
                  </ButtonToolTip>
                ),
              },
              {
                useFor: 'EVERNOTE',
                render: (text, record) => (
                  <ButtonToolTip toolTip="หมายเหตุ" placement="topLeft">
                    <ButtonTheme
                      useFor="EVERNOTE"
                      onClick={() => {
                        onOpenRemarkMessage(record);
                      }}
                    />
                  </ButtonToolTip>
                ),
              },
            ],
          }),
        ]),
  ];
};

export const exportHeaders = [
  { dataIndex: 'claimCode', title: 'เลขที่เคลม' },
  { dataIndex: 'fullName', title: 'ชื่อ-นามสกุล' },
  { dataIndex: 'lineName', title: 'ชื่อไลน์' },
  { dataIndex: 'telephone', title: 'เบอร์โทร' },
  { dataIndex: 'claimDate', title: 'วันที่เคลม' },
  { dataIndex: 'mobileBrandName', title: 'แบรนด์' },
  { dataIndex: 'mobileModelName', title: 'รุ่น' },
  { dataIndex: 'itemCode', title: 'รหัสสินค้า' },
  { dataIndex: 'productName', title: 'ชื่อสินค้า' },
  { dataIndex: 'breakdownName', title: 'สาเหตุการเคลม' },
  { dataIndex: 'detail1', title: 'รายละเอียด (อาการเสีย)' },
  { dataIndex: 'remainingDays', title: 'วันคงเหลือ' },
  { dataIndex: 'claimStatusName', title: 'สถานะ' },
  { dataIndex: 'channelClaimName', title: 'ช่องทางการเคลม' },
  { dataIndex: 'processStatusName', title: 'สถานะภายใน' },
  { dataIndex: 'trackingNumber', title: 'เลขพัสดุ' },
  { dataIndex: 'shippingDate', title: 'วันที่จัดส่ง' },
  { dataIndex: 'addressFullText', title: 'ที่อยู่จัดส่ง' },
  { dataIndex: 'lineUid', title: 'ไลน์ UID' },
];
