import { useMutation } from '@tanstack/react-query';
import { POST, POST_SEND_MESSAGE } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';
import { sendMessage } from '../../../../resources/payload';

export const useSendMessage = ({ onSuccessCallBack }) => {
  return useMutation({
    mutationFn: async (values) => {
      const payload = sendMessage(values);
      const response = await POST(POST_SEND_MESSAGE(), payload);
      if (onSuccessCallBack) onSuccessCallBack(response);

      return response;
    },
    onSuccess: () => {
      openAlert({ model: 'message', type: 'success', message: 'ส่งข้อความสำเร็จ' });
    },
  });
};
