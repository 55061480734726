export const FORM_SEARCH = ({ onEnter }) => {
  return [
    {
      name: 'search',
      label: 'ค้นหารายการ (กรอกข้อมูลแล้วกด Enter เพื่อค้นหา)',
      type: 'INPUT',
      helper: 'Tips : สามารถค้นหาด้วย รหัสแบรนด์, ชื่อแบรนด์',
      placeholder: 'ค้นหาแบรนด์มือถือ',
      span: 12,
      onEnter,
    },
  ];
};

export const searchDefaultValues = {
  search: '',
};

export const FORM_EDIT_BRAND = ({ statusOptions }) => {
  const span = 24;
  return [
    {
      name: 'mobileBrandId',
      type: 'HIDDEN',
    },
    {
      name: 'mobileBrandCode',
      label: 'รหัสแบรนด์',
      type: 'INPUT',
      disabled: true,
      span,
    },
    {
      name: 'mobileBrandNameTh',
      label: 'ชื่อแบรนด์ (ไทย)',
      type: 'INPUT',
      disabled: true,
      span,
    },
    {
      name: 'mobileBrandNameEn',
      label: 'ชื่อแบรนด์ (eng)',
      type: 'INPUT',
      disabled: true,
      span,
    },
    {
      name: 'mobileBrandStatus',
      label: 'สถานะ',
      type: 'DROPDOWN',
      properties: {
        options: statusOptions || [],
      },
      span,
    },
    {
      name: 'remark',
      label: 'หมายเหตุ (ระบุได้ไม่เกิน 250 ตัวอักษร)',
      type: 'INPUT_AREA',
      placeholder: 'กรุณากรอกหมายเหตุ',
      rules: {
        maxLength: 250,
      },
      span,
    },
  ];
};
