import React from 'react';
import { SpaceBetween, StyledContainer } from '../../components/container/styles';
import { TextLarge } from '../../components/text';
import { CardTheme } from '../../components/card';
import { ResponsivePageLayout } from '../../components/page/responsive-layout/ResponsivePageLayout';
import { ContainerButton } from '../../styles/global-style';
import ButtonExcelEmbedded from '../../components/report-excel/ButtonExcelEmbedded';
import { Icon } from '../../resources';
import { useGetUserInfoList } from './hooks/useGetUserInfoList';
import { ModalMessage } from './modals/ModalMessage';
import { EditUserInfo } from './modals/EditUserInfo';

export const UserInfo = () => {
  const {
    formSearchObj,
    formSearch,
    dataUserInfo,
    isLoadingUserInfo,
    onFetch,
    columnsData,
    openMessage,
    onToggleMessage,
    data,
    openEdit,
    onToggleEdit,
  } = useGetUserInfoList();

  return (
    <StyledContainer>
      <TextLarge text="ข้อมูลลูกค้า" />
      <CardTheme>
        <ResponsivePageLayout
          searchLayout={{
            title: 'ค้นหาข้อมูลลูกค้า',
            formSearch: formSearch,
            useFormParam: formSearchObj,
            icon: <Icon.todo />,
            onSearch: onFetch,
            ignoreDateEmpty: true,
          }}
          tableLayout={{
            columns: columnsData,
            dataSource: dataUserInfo,
            hasPagination: true,
            loading: isLoadingUserInfo,
            extraContent: (
              <SpaceBetween>
                <ContainerButton right>
                  <ButtonExcelEmbedded
                    title="Export Excel"
                    header={columnsData}
                    data={dataUserInfo}
                    filename="รายงานข้อมูลลูกค้า"
                  />
                </ContainerButton>
              </SpaceBetween>
            ),
          }}
        />
      </CardTheme>
      {openMessage && (
        <ModalMessage
          open={openMessage}
          onToggle={onToggleMessage}
          initialValues={data}
          onFetch={onFetch}
        />
      )}
      {openEdit && (
        <EditUserInfo
          open={openEdit}
          onToggle={onToggleEdit}
          initialValues={data}
          onFetch={onFetch}
        />
      )}
    </StyledContainer>
  );
};
