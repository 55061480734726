import { useMutation } from '@tanstack/react-query';
import { POST, POST_INSERT_COLOR } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';
import { InsertColor } from '../../../../resources/payload';

export const useInsertColorList = ({ onSuccessCallBack }) => {
  return useMutation({
    mutationFn: async (values) => {
      const payload = InsertColor(values);
      const response = await POST(POST_INSERT_COLOR(), payload);
      if (onSuccessCallBack) onSuccessCallBack(response);

      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'เพิ่ม Category สำเร็จ',
      });
    },
    onError: (error) => {
      openAlert({
        model: 'message',
        type: 'error',
        message: error?.message,
      });
    },
  });
};
