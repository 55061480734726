import { useForm } from 'react-hook-form';
import { FORM_SEARCH, searchDefaultValues } from '../form';
import { useGetColor } from './useGetColor';
import { useModal } from '../../../../components/modal/hooks/useModal';
import { useDeleteColor } from './useDeleteCategoryList';
import { useState } from 'preact/hooks';
import { columns } from '../columns';

export const useGetColorList = () => {
  const [dataEdit, setDataEdit] = useState(null);
  const { open: openAdd, onToggle: onToggleAdd } = useModal();
  const { open: openEdit, onToggle: onToggleEdit } = useModal();
  const formSearchObj = useForm({ defaultValues: searchDefaultValues });

  const {
    dataSource: dataColor,
    onFetch,
    loading: isLoadingColorList,
  } = useGetColor({
    handleSubmit: formSearchObj.handleSubmit,
  });

  const { mutate: onSubmitDeleteColor, isPending: loadingDeleteColor } = useDeleteColor({
    onSuccessCallBack: onFetch,
  });

  const onEditColor = (record) => {
    setDataEdit(record);
    onToggleEdit({ defaultValues: record });
  };

  const handleOnEnter = (value) => {
    onFetch(value);
  };

  const columnData = columns({ onSubmitDeleteColor, onEditColor });
  const formSearch = FORM_SEARCH({ onEnter: handleOnEnter });

  return {
    formSearch,
    formSearchObj,
    columnData,
    dataColor,
    onFetch,
    loadingDeleteColor,
    isLoadingColorList,
    openAdd,
    onToggleAdd,
    openEdit,
    onToggleEdit,
    dataEdit,
  };
};
