import { useForm } from 'react-hook-form';
import { channelBuyQuery } from '../../../../services/react-query/optionMasterQuery';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../../components';
import { ContainerButton } from '../../../../styles/global-style';
import { useEffect } from 'preact/hooks';
import { FORM_EDIT_MANAGEMENT_SPECIAL } from '../forms';
import { useEditManageData } from '../hooks/useEditManageData';

export const EditModal = ({ open, onToggle, initailValues }) => {
  const { control, setValue, getValues, handleSubmit } = useForm();
  const { data: channelBuyList } = channelBuyQuery();

  const { mutate: editData } = useEditManageData();

  useEffect(() => {
    if (initailValues) {
      Object.entries(initailValues).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [initailValues, setValue]);

  const onSubmit = (value) => {
    editData(value);
    onToggle({ defaultValues: '' });
  };

  return (
    <ModalTheme title="แก้ไขช่องทางรับประกัน" open={open} onToggle={onToggle}>
      <RenderForm
        control={control}
        forms={FORM_EDIT_MANAGEMENT_SPECIAL({ channelBuyList })}
        setValue={setValue}
        getValues={getValues}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
