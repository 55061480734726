import { useMutation } from '@tanstack/react-query';
import { POST, POST_CLAIM_UPDATE } from '../../../../services';
import { ROUTES_PATH } from '../../../../resources/routes-name';
import { openAlert } from '../../../../components/alert/hooks';
import { navigateTo } from '../../../../utils/navigation';

export const onUploadFile = ({ claimData }) =>
  useMutation({
    mutationFn: async (formData) => {
      const response = await POST(POST_CLAIM_UPDATE(), formData);
      return response;
    },
    onSuccess: (response) => {
      openAlert({ model: 'message', type: 'success', message: response.message });
      setTimeout(() => {
        navigateTo({
          pathname: ROUTES_PATH.ROUTE_CLAIM_EDIT.PATH,
          search: `?code=${claimData?.claimCode}`,
        });
      }, 1000);
    },
    onError: (response) => {
      openAlert({ model: 'message', type: 'error', message: response?.message });
    },
  });
