import { useForm } from 'react-hook-form';
import { FORM_REPORT_CLAIM, searchDefaultValues } from '../form';
import { useGetReportByDate } from './useGetReportByDate';
import { columns, columnsExpandable } from '../columns';
import { useEffect, useState } from 'preact/hooks';
import { useGetReportClaimFull } from './useGetReportClaimFull';

export const useGetReportPageList = () => {
  const [searchType, setSearchType] = useState(null);
  const formSearchObj = useForm({ defaultValues: searchDefaultValues });

  const {
    dataSource: dataDate,
    onFetch,
    isLoading,
  } = useGetReportByDate({ handleSubmit: formSearchObj.handleSubmit });

  const {
    dataSource: dataClaimFull,
    onFetchFull,
    loading: loadingClaimFull,
  } = useGetReportClaimFull({
    handleSubmit: formSearchObj.handleSubmit,
  });

  useEffect(() => {
    onSearchByField(searchDefaultValues);
  }, []);

  const onSearchByField = (values) => {
    if (values.search) {
      setSearchType('search');
      onFetchFull(values);
    } else if (values.startDate) {
      setSearchType('date');
      onFetch(values);
    }
  };

  const formatDataForExcel = (data) => {
    const result = [];

    data.forEach((record) => {
      // ข้อมูลหลัก
      const mainRow = {
        ลำดับ: record?.rowNumber,
        'ชื่อ-นามสกุล': record?.fullName,
        เบอร์โทร: record?.telephone,
        จำนวนการเคลม: record?.totalClaims,
        วันที่เคลมล่าสุด: record?.lastClaimDate,
      };

      // ถ้าไม่มีข้อมูลย่อย ให้เพิ่มแถวหลักและแถวว่าง
      if (!record?.claimReportHistoryDetails || record?.claimReportHistoryDetails?.length === 0) {
        result.push(mainRow);
        result.push({}); // แถวว่างคั่น
        return;
      }

      // ถ้ามีข้อมูลย่อย ให้เพิ่มข้อมูลย่อยต่อจากข้อมูลหลัก
      record?.claimReportHistoryDetails?.forEach((detail, detailIndex) => {
        const detailRow = {
          ...mainRow,
          รูปภาพสินค้าเคลม: detail?.claimImageUrl,
          รูปภาพสลิปโอนเงิน: detail?.receiptImageUrl,
          เลขที่การเคลม: detail?.claimCode,
          ชื่อสินค้า: detail?.productName,
          วันที่แจ้งเคลม: detail?.claimDate,
          อาการเสีย: detail?.breakdownName,
          'EMS จัดส่ง': detail?.trackingNumber,
          ประเภทการเคลม: detail?.claimType,
        };

        // ถ้าเป็นรายการแรกของข้อมูลย่อย ให้แสดงข้อมูลหลักด้วย
        // ถ้าไม่ใช่รายการแรก ให้ลบข้อมูลหลักออก เพื่อไม่ให้ซ้ำกัน
        if (detailIndex > 0) {
          delete detailRow.ลำดับ;
          delete detailRow['ชื่อ-นามสกุล'];
          delete detailRow['เบอร์โทร'];
          delete detailRow['จำนวนการเคลม'];
          delete detailRow['วันที่เคลมล่าสุด'];
        }

        result.push(detailRow);
      });

      // เพิ่มแถวว่างหลังจากจบข้อมูลของแต่ละรายการหลัก
      result.push({});
    });

    return result;
  };

  const currentData = searchType === 'date' ? dataDate : dataClaimFull || [];
  const maxDetails = Math.max(
    ...currentData.map((record) => record.claimReportHistoryDetails?.length || 0),
  );

  const createExpandableHeaders = () => {
    // สร้างส่วนหัวคอลัมน์ในรูปแบบแนวตั้ง
    return [
      { title: 'รูปภาพสินค้าเคลม', dataIndex: 'รูปภาพสินค้าเคลม' },
      { title: 'รูปภาพสลิปโอนเงิน', dataIndex: 'รูปภาพสลิปโอนเงิน' },
      { title: 'เลขที่การเคลม', dataIndex: 'เลขที่การเคลม' },
      { title: 'ชื่อสินค้า', dataIndex: 'ชื่อสินค้า' },
      { title: 'วันที่แจ้งเคลม', dataIndex: 'วันที่แจ้งเคลม' },
      { title: 'อาการเสีย', dataIndex: 'อาการเสีย' },
      { title: 'EMS จัดส่ง', dataIndex: 'EMS จัดส่ง' },
      { title: 'ประเภทการเคลม', dataIndex: 'ประเภทการเคลม' },
    ];
  };

  const columnsData = columns();
  const columnsExpandableData = columnsExpandable();
  const formSearch = FORM_REPORT_CLAIM({ onEnter: formSearchObj.handleSubmit(onSearchByField) });

  return {
    formSearch,
    formSearchObj,
    dataDate,
    onFetch,
    isLoading,
    columnsData,
    dataClaimFull,
    onFetchFull,
    loadingClaimFull,
    searchType,
    onSearchByField,
    columnsExpandableData,
    formatDataForExcel,
    maxDetails,
    createExpandableHeaders,
  };
};
