import { useForm } from 'react-hook-form';
import { columns } from '../columns';
import { FORM_SEARCH, searchDefaultValues } from '../form';
import { useGetProduct } from './useGetProduct';
import { ROUTES_PATH } from '../../../../resources/routes-name';

export const useGetProductList = () => {
  const formSearchObj = useForm({ defaultValues: searchDefaultValues });

  const {
    dataSource: dataProduct,
    onFetch,
    loading: isLoadingProductList,
  } = useGetProduct({ handleSubmit: formSearchObj.handleSubmit });

  const handleOnEnter = (value) => {
    onFetch(value);
  };

  const onOpenEditProduct = (record) => {
    const url = `${ROUTES_PATH.ROUTE_EDIT_PRODUCT.PATH}?code=${record.mapProductId}`;
    window.open(url, '_blank');
  };

  const columnData = columns({ onOpenEditProduct });
  const formSearch = FORM_SEARCH({ onEnter: handleOnEnter });

  return {
    columnData,
    formSearch,
    formSearchObj,
    dataProduct,
    onFetch,
    isLoadingProductList,
  };
};
