export const serviceOptions = (key, options = []) => {
  switch (key) {
    case 'MOBILE_BRAND':
      return options?.map((brand) => ({
        label: brand.mobileBrandNameTh,
        value: brand.mobileBrandId,
        mobileModels: brand.mobileModels,
      }));
    case 'MOBILE_MODEL':
      return options?.map((model) => ({
        label: model.mobileModelNameTh,
        value: model.mobileModelId,
        products: model.products,
      }));
    case 'PRODUCT':
      const defaultOption = options?.map((item) => ({
        label: item.productFullName,
        value: item.mapProductId,
      }));
      return defaultOption;
    case 'CHANNEL_BUY':
      return options?.map((item) => ({
        label: item.channelBuyNameTh,
        value: item.channelBuyId,
        channelBuyType: item.channelBuyType,
      }));
    case 'WARRANTY_TYPE':
      return options?.map((item) => ({
        label: item.warrantyTypeNameTh,
        value: item.warrantyTypeId,
      }));
    case 'SHIPPING_SELF':
      return options?.map((el) => ({ label: el.shippingName, value: el.shippingId }));
    case 'CATEGORY_OPTIONS':
      return options?.map((el) => ({
        label: `${el.categoryCode} :: ${el.categoryName}`,
        value: el.categoryId,
        mobilebrands: el.mobilebrands,
      }));
    case 'MOBILE_BRAND':
      return options?.map((brand) => ({
        label: brand.mobileBrandNameTh,
        value: brand.mobileBrandId,
        mobileModels: brand.mobileModels,
      }));
    case 'MOBILE_MODEL':
      return options?.map((model) => ({
        label: model.mobileModelNameTh,
        value: model.mobileModelId,
        products: model.products,
      }));
    case 'PROVINCE':
      const provinceOptions = options?.map((item) => ({
        label: item.provinceNameTh,
        value: item.provinceId,
      }));
      return provinceOptions;
    case 'DISTRICT':
      const districtOptions = options?.map((item) => ({
        label: item.districtNameTh,
        value: item.districtId,
      }));
      return districtOptions;
    case 'SUBDISTRICT':
      const subDistrictOptions = options?.map((item) => ({
        label: item.subDistrictNameTh,
        value: item.subDistrictId,
      }));
      return subDistrictOptions;
    case 'ADDRESS':
      const addressOptions = options?.map((address) => ({
        label: address.addressFullText,
        value: address.customerAddressId,
      }));
      return addressOptions;
    case 'CLAIM_PRODUCT':
      return options?.map((item) => ({
        label: `${item.mobileModelName} || ${item.productName}`,
        value: item.warrantyId,
        breakdowns: item.breakdowns,
      }));
    case 'BREAKDOWN':
      const breakdownOptions = options?.map((item) => ({
        label: item?.breakdownNameTh,
        value: item?.breakdownId,
      }));
      return breakdownOptions;
    case 'CUSTOMER_GROUP_USER':
      return options?.map((el) => ({ label: el.customerGroupCode, value: el.customerGroupCode }));
    case 'STORE_TYPE':
      return options?.map((el) => ({ label: el.storeTypeNameTh, value: el.storeTypeId }));
    case 'MOBILE_BRAND_CODE':
      return options?.map((brand) => ({
        label: brand.mobileBrandNameTh,
        value: brand.mobileBrandCode,
      }));
    case 'CATEGORY_LIST':
      return options?.map((el) => ({
        label: `${el.categoryCode} :: ${el.categoryName}`,
        value: el.categoryId,
        defaultWarrantyPeriod: el.defaultWarrantyPeriod,
      }));
    case 'BRAND_LIST':
      return options?.map((el) => ({
        label: `${el.mobileBrandCode} :: ${el.mobileBrandNameTh}`,
        value: el.mobileBrandId,
      }));
    case 'MODEL_LIST':
      return options?.map((el) => ({
        label: `${el.mobileModelCode} :: ${el.mobileModelNameTh}`,
        value: el.mobileModelId,
      }));
    case 'PRODUCT_TYPE':
      return options?.map((el) => ({ label: el.productTypeNameTh, value: el.productTypeCode }));
    case 'PRODUCT_CODE':
      return options?.map((el) => ({
        label: el.productGroupNameEn,
        value: el.productGroupCode,
      }));
    case 'STORE_TYPE_CHANNEL':
      return options?.map((el) => ({
        label: el.storeName,
        value: el.storeId,
      }));
    case 'CHANNEL_CLAIMS':
      return options?.map((el) => ({
        label: el?.channelClaimNameTh,
        value: el?.channelClaimId,
        channelClaimType: el?.channelClaimType,
      }));
    case 'PROCESS_STATUS':
      return options?.map((el) => ({
        label: el.optionStatusNameTh,
        value: el.optionStatusCode,
      }));
    case 'USER':
      return options?.map((item) => ({
        label: `${item.userCode}: ${item.fullName}`,
        value: item.userId,
      }));
    case 'STORE_CUT_OFF':
      return options?.map((item) => ({
        label: item.storeName,
        value: item.storeId,
      }));
    case 'USER_TYPE':
      return options?.map((item) => ({
        label: item.userTypeName,
        value: item.userTypeId,
        privilegeData: item.privilegeData,
      }));
    default:
      return [];
  }
};
