import { useMutation } from '@tanstack/react-query';
import { POST, POST_CLAIMS_CANCEL } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';
import { cancelClaim } from '../../../../resources/payload';
import { navigateTo } from '../../../../utils/navigation';
import { ROUTES_PATH } from '../../../../resources/routes-name';

export const useCancleClaim = () =>
  useMutation({
    mutationFn: async (value) => {
      const payload = cancelClaim(value);
      const response = await POST(POST_CLAIMS_CANCEL(), payload);
      return response;
    },
    onSuccess: (response) => {
      openAlert({ type: 'success', message: response?.message });
      setTimeout(() => {
        navigateTo({ pathname: ROUTES_PATH.ROUTE_CLAIM.PATH });
      }, 1000);
    },
    onError: (error) => {
      openAlert({ type: 'error', message: error?.message });
    },
  });
