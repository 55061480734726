import { useCallback, useEffect, useState } from 'preact/hooks';
import { useForm } from 'react-hook-form';
import { navigateTo } from '../../../../utils/navigation';
import { ROUTES_PATH } from '../../../../resources/routes-name';
import { useGetWarrantyEdit } from './useGetWarrantyEdit';
import { useOptions } from './useOptions';
import {
  channelBuyQuery,
  storeTypeChannelQuery,
} from '../../../../services/react-query/optionMasterQuery';
import { onUploadFile } from './onUploadFile';
import { UpdateWarrantyEdit } from '../../../../resources/payload';
import { useModal } from '../../../../components/modal/hooks/useModal';

export const useGetWarrantyEditList = () => {
  const [showStoreField, setShowStoreField] = useState('');
  const { control, setValue, getValues, handleSubmit, reset, watch } = useForm();
  const [state, setState] = useState();
  const [dataEdit, setDataEdit] = useState();
  const queryParams = new URLSearchParams(window.location.search);
  const warrantyCode = queryParams.get('code');
  const { open: openEditExpired, onToggle: onToggleEditExpired } = useModal();
  const watchChannelBuy = watch('channelBuyId');

  useEffect(() => {
    setState({ warrantyCode });
  }, [warrantyCode]);

  const { loading: isLoadingWarrantyEdit, dataSource } = useGetWarrantyEdit({
    warrantyCode: state?.warrantyCode,
    reset,
    setValue,
    getValues,
  });

  const { mobileBrandOptions, mobileModelOptions, productOptions } = useOptions({ watch });
  const { data: channelBuyOptions } = channelBuyQuery();
  const { data: storeTypeChannelOptions } = storeTypeChannelQuery(showStoreField);
  const { mutate: onEditWarranty, isPending: isPendingEditWarranty } = onUploadFile();

  const onChange = useCallback(
    (_, item) => {
      switch (item.name) {
        case 'mobileBrandId':
          setValue('mobileModelId', '');
          setValue('mapProductId', '');
          break;
        case 'mobileModelId':
          setValue('mapProductId', '');
          break;
        case 'channelBuyId':
          setValue('storeId', '');
          break;
        default:
          break;
      }
    },
    [setValue],
  );

  useEffect(() => {
    if (watchChannelBuy && channelBuyOptions) {
      const selectedChannel = channelBuyOptions.find((option) => option.value === watchChannelBuy);
      const channelType = selectedChannel?.channelBuyType;
      if (['FOCUS', 'STORE'].includes(channelType)) {
        setShowStoreField(channelType);
      } else {
        setShowStoreField('');
      }
    }
  }, [watchChannelBuy, channelBuyOptions]);

  useEffect(() => {
    const dataWarrantyEdit = dataSource?.warranty;
    setDataEdit(dataWarrantyEdit);
  }, [dataSource]);

  const goBack = () => {
    navigateTo({
      pathname: ROUTES_PATH.ROUTE_WARRANTY.PATH,
      state: getValues(),
    });
  };

  const onSubmit = (data) => {
    const formData = UpdateWarrantyEdit(data);
    onEditWarranty(formData);
  };

  return {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    watch,
    isLoadingWarrantyEdit,
    dataSource,
    mobileBrandOptions,
    mobileModelOptions,
    productOptions,
    channelBuyOptions,
    storeTypeChannelOptions,
    onSubmit,
    goBack,
    dataEdit,
    openEditExpired,
    onToggleEditExpired,
    showStoreField,
    onChange,
    isPendingEditWarranty,
  };
};
