import { reducerBuilder } from '../../functions/redux';
import * as type from './types';

const initialState = {
  open: false,
  loading: false,
  options: {
    type: 'success',
    model: 'notification',
    title: '',
    message: 'message here!',
    onFinish: () => {
      console.log('loading');
    },
  },
};

export const alertReducer = reducerBuilder(initialState, (builder) =>
  builder
    .addCase(type.ALERT_REQ, (state, { payload }) => {
      const nextOption = { ...state.options, ...payload.payload };
      state.options = nextOption;
      state.open = true;
    })
    .addCase(type.ALERT_CLOSE, (state) => {
      state.options = initialState.options;
      state.open = false;
    })
    .addCase(type.ALERT_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(type.ALERT_LOADING_CLOSE, (state) => {
      state.loading = false;
    }),
);
