import dayjs from 'dayjs';
export const FORM_SEARCH = ({ onEnter }) => {
  const span = 12;
  return [
    {
      label: 'ค้นหารายการ (กรอกข้อมูลแล้วกด Enter เพื่อค้นหา)',
      name: 'search',
      type: 'INPUT',
      onEnter,
      span,
    },
    {
      label: 'ค้นหาผ่านวันที่',
      name: 'startDate',
      type: 'DATE_RANGE',
      span,
    },
  ];
};

export const searchDefaultValues = {
  search: '',
  startDate: [dayjs().startOf('month'), dayjs()],
};
