import { useMutation } from '@tanstack/react-query';
import { POST, POST_INSERT_STORE } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';
import { createFocusShop } from '../../../resources/payload';

export const useInsertStore = ({ onSuccessCallBack }) =>
  useMutation({
    mutationFn: async ({ ...values }) => {
      const payload = createFocusShop(values);
      const response = await POST(POST_INSERT_STORE(), payload);
      if (onSuccessCallBack) onSuccessCallBack(response);

      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'เพิ่มร้านค้าสำเร็จ',
      });
    },
  });
