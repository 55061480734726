import { useForm } from 'react-hook-form';
import { FORM_SEARCH, searchDefaultValue } from '../form';
import { useGetStore } from './useGetStore';
import { useDeleteStore } from './useDeleteStore';
import { useModal } from '../../../components/modal/hooks/useModal';
import { useCallback, useState } from 'preact/hooks';
import { columns } from '../columns';

export const useGetStoreList = () => {
  const [datasetStore, setDatasetStore] = useState(null);
  const { open: openQrCode, onToggle: onToggleQrCode } = useModal();
  const { open: openAdd, onToggle: onToggleAdd } = useModal();
  const { open: openEdit, onToggle: onToggleEdit } = useModal();
  const formSearchObj = useForm({
    defaultValues: searchDefaultValue,
  });
  const {
    dataSource: dataStore,
    onFetch,
    loading: isLoadingStore,
  } = useGetStore({
    handleSubmit: formSearchObj.handleSubmit,
  });

  const { mutate: handleDelete, isPending: loadingDeleteStore } = useDeleteStore({
    onSuccessCallBack: onFetch,
  });

  const handleDeleteStore = (record) => {
    handleDelete(record);
  };

  const handleEditStore = (record) => {
    setDatasetStore(record);
    onToggleEdit({ defaultValues: record });
  };

  const handleQrCodeStore = (record) => {
    setDatasetStore(record);
    onToggleQrCode({ defaultValues: record });
  };

  const onEnter = useCallback(
    async (values) => {
      await onFetch(values);
    },
    [onFetch],
  );

  const columnData = columns({ handleDeleteStore, handleEditStore, handleQrCodeStore });
  const formSearch = FORM_SEARCH({ onEnter });

  return {
    formSearch,
    formSearchObj,
    columnData,
    dataStore,
    isLoadingStore,
    onFetch,
    openAdd,
    onToggleAdd,
    openEdit,
    onToggleEdit,
    datasetStore,
    openQrCode,
    onToggleQrCode,
    loadingDeleteStore,
  };
};
