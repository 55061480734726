import { useMutation } from '@tanstack/react-query';
import { GET, GET_SCAN_DELIVERY } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';

export const useGetScanDelivery = ({ reset, getValues }) =>
  useMutation({
    mutationFn: async (value) => {
      const response = await GET(GET_SCAN_DELIVERY(value));
      return response.result;
    },
    onSuccess: (value) => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'ค้นหาเลขที่เคลมสำเร็จ',
      });

      const currentShippingId = getValues('shippingId');

      const claimFields = [
        'claimId',
        'claimCode',
        'fullName',
        'telephone',
        'addressFullText',
        'mobileBrandName',
        'mobileModelName',
        'remark',
        'claimStatusName',
        'categoryCode',
      ];

      const claimData = claimFields.reduce((acc, field) => {
        acc[field] = value.claim[field];
        return acc;
      }, {});

      reset({
        ...getValues(),
        ...claimData,
        shippingId: currentShippingId || claimData.shippingId,
      });
    },
    onError: (error) => {
      openAlert({
        model: 'message',
        type: 'error',
        message: error.message,
      });
    },
  });
