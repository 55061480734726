import React from 'react';
import { ImCheckmark } from 'react-icons/im';
import { BiEdit } from 'react-icons/bi';
import { RiDeleteBin6Line, RiTimeLine } from 'react-icons/ri';
import { color } from '../../../resources';
import { Icon } from '../../../resources/icon';

export const requestStatusObg = {
  [10]: {
    icon: <BiEdit />,
    color: color.blueFocus,
    label: 'รอ GEN QR (WAIT GEN)',
  },

  [20]: {
    icon: <ImCheckmark />,
    color: color.yellow,
    label: 'กำลัง GEN QR (CREATING)',
  },
  [30]: {
    icon: <Icon.box />,
    color: color.submit,
    label: 'สำเร็จ (COMPLETED)',
  },
  [40]: {
    icon: <RiTimeLine />,
    color: '#9e9e9e',
    label: 'GEN QR ล้มเหลว (CREATE FAILED)',
  },
  [50]: {
    icon: <RiDeleteBin6Line />,
    color: color.edit,
    label: 'ส่งอีเมลล้มเหลว (SEND FAILED)',
  },
};
