import React from 'react';
import { SpaceBetween, StyledContainer } from '../../../components/container/styles';
import { TextLarge } from '../../../components/text';
import { ResponsivePageLayout } from '../../../components/page/responsive-layout/ResponsivePageLayout';
import { Icon } from '../../../resources';
import { ContainerButton } from '../../../styles/global-style';
import ButtonExcelEmbedded from '../../../components/report-excel/ButtonExcelEmbedded';
import { useGetProductList } from './hooks/useGetProductList';

export const Product = () => {
  const { columnData, formSearch, formSearchObj, dataProduct, onFetch, isLoadingProductList } =
    useGetProductList();
  return (
    <div>
      <StyledContainer>
        <TextLarge>ค้นหาสินค้า</TextLarge>
        <ResponsivePageLayout
          searchLayout={{
            title: 'ค้นหาสินค้า',
            formSearch,
            useFormParam: formSearchObj,
            icon: <Icon.todo />,
            ignoreDateEmpty: true,
            onSearch: onFetch,
          }}
          tableLayout={{
            columns: columnData,
            dataSource: dataProduct || [],
            hasPagination: true,
            loading: isLoadingProductList,
            extraContent: (
              <SpaceBetween>
                <ContainerButton right>
                  <ButtonExcelEmbedded
                    title="Export"
                    header={columnData}
                    data={dataProduct}
                    filename="รายงานสินค้า"
                  />
                </ContainerButton>
              </SpaceBetween>
            ),
          }}
        />
      </StyledContainer>
    </div>
  );
};
