import { useForm } from 'react-hook-form';
import { columns } from '../columns';
import { shippingSelfQuery } from '../../../services/react-query/optionMasterQuery';
import { useGetScanDelivery } from './useGetScanDelivery';
import { useCallback, useRef, useState } from 'preact/hooks';
import { useGetWarrantyQr } from './useGetWarrantyQr';
import { openAlert } from '../../../components/alert/hooks';
import { useUpdateTracking } from './useUpdateTracking';

export const useGetScan = () => {
  const [scanData, setScanData] = useState({
    claim: null,
    warranty: null,
  });
  const [tableData, setTableData] = useState([]);
  const { control, setValue, getValues, reset, handleSubmit } = useForm();
  const { data: shippingSelfOptions } = shippingSelfQuery();
  const { mutate: getScanDelivery, isPending: loadingScanDelivery } = useGetScanDelivery({
    reset,
    getValues,
  });
  const { mutate: getWarrantyQr, isPending: loadingWarrantyQr } = useGetWarrantyQr();
  const { mutate: updateTracking, isPending: loadingUpdateTracking } = useUpdateTracking();

  const trackingInputRef = useRef(null);
  const itemCodeInputRef = useRef(null);
  const claimCodeInputRef = useRef(null);
  const warrantyNumberCodeRef = useRef(null);

  const handleOnEnter = useCallback(
    async (event, fieldName) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        let value = getValues(fieldName);

        switch (fieldName) {
          case 'claimCode':
            if (value) {
              const isDuplicate = tableData.some((item) => item.claimCode === value);

              if (isDuplicate) {
                openAlert({
                  type: 'error',
                  message: 'เลขที่เคลมซ้ำ ไม่สามารถเพิ่มข้อมูลได้',
                });
                reset({
                  claimCode: '',
                });
                claimCodeInputRef.current?.focus();
                return;
              }

              getScanDelivery(value, {
                onSuccess: (dataScan) => {
                  setScanData((prev) => ({
                    ...prev,
                    claim: dataScan?.claim,
                  }));

                  setValue('claimCode', getValues('claimCode'));
                  setValue('shippingId', dataScan?.claim?.shippingId || getValues('shippingId'));
                  setValue('trackingNumber', dataScan?.claim?.trackingNumber);

                  if (dataScan?.claim?.shippingId && dataScan?.claim?.trackingNumber) {
                    warrantyNumberCodeRef.current?.focus();
                  } else {
                    trackingInputRef.current?.focus();
                  }
                },
                onError: () => {
                  reset({
                    claimCode: '',
                  });
                },
              });
            }
            break;
          case 'trackingNumber':
            if (value) {
              const isDuplicate = tableData.some((item) => item.trackingNumber === value);

              if (isDuplicate) {
                openAlert({
                  type: 'error',
                  message: 'เลขที่พัสดุซ้ำ ไม่สามารถเพิ่มข้อมูลได้',
                });
                setValue('trackingNumber', '');
                trackingInputRef.current?.focus();
                return;
              }
              itemCodeInputRef.current?.focus();
            }
            break;

          case 'itemCode':
            if (value) {
              warrantyNumberCodeRef.current?.focus();
            }
            break;
          case 'warrantyNumberCode':
            if (value) {
              if (value.includes('?fw=')) {
                try {
                  const url = new URL(value);
                  value = url.searchParams.get('fw');
                } catch (error) {
                  openAlert({
                    type: 'error',
                    message: 'รูปแบบ URL ไม่ถูกต้อง',
                  });
                  setValue('warrantyNumberCode', '');
                  warrantyNumberCodeRef.current?.focus();
                  return;
                }
              }

              const claimCode = getValues('claimCode');
              const trackingNumber = getValues('trackingNumber');

              if (!claimCode || !trackingNumber) {
                openAlert({
                  type: 'error',
                  message: 'กรุณากรอกเลขที่เคลมและเลขที่พัสดุก่อน',
                });
                setValue('warrantyNumberCode', '');
                claimCodeInputRef.current?.focus();
                return;
              }

              const isDuplicate = tableData.some((item) => item.warrantyNumberCode === value);

              if (isDuplicate) {
                openAlert({
                  type: 'error',
                  message: 'QR Code ที่ตัดการรับประกันซ้ำ ไม่สามารถเพิ่มข้อมูลได้',
                });
                setValue('warrantyNumberCode', '');
                warrantyNumberCodeRef.current?.focus();
                return;
              }
              warrantyNumberCodeRef.current?.focus();
              getWarrantyQr(value, {
                onSuccess: (dataQr) => {
                  const newScanData = {
                    ...scanData,
                    warranty: dataQr?.warranty,
                  };
                  setScanData(newScanData);

                  const newTableRow = {
                    key: tableData.length + 1,
                    itemCode: newScanData.claim?.categoryCode,
                    claimId: newScanData.claim?.claimId,
                    claimCode: getValues('claimCode'),
                    fullName: newScanData.claim?.fullName,
                    telephone: newScanData.claim?.telephone,
                    mobileModelName: newScanData.claim?.mobileModelName,
                    trackingNumber: getValues('trackingNumber'),
                    warrantyNumberCode: value,
                    shippingId: getValues('shippingId'),
                    itemCode: getValues('itemCode'),
                  };

                  setTableData([newTableRow, ...tableData]);
                  claimCodeInputRef.current?.focus();

                  reset({
                    shippingId: getValues('shippingId'),
                    claimCode: '',
                    trackingNumber: '',
                    itemCode: '',
                    warrantyNumberCode: '',
                  });
                },
              });
            }
            break;
        }
      }
    },
    [getScanDelivery, getValues, scanData, tableData, reset],
  );

  const onDeleteRecordScanDelivery = (record) => {
    const newTableData = tableData.filter((item) => item.key !== record.key);

    setTableData(newTableData);

    claimCodeInputRef.current?.focus();
    reset({
      claimCode: '',
      trackingNumber: '',
      warrantyNumberCode: '',
    });
  };

  const onSubmit = () => {
    if (tableData.length === 0) {
      openAlert({
        type: 'error',
        message: 'กรุณาเพิ่มข้อมูลอย่างน้อย 1 รายการ',
      });
      return;
    }

    const payload = tableData?.map((item) => ({
      claimId: item?.claimId,
      claimCode: item?.claimCode,
      trackingNumber: item?.trackingNumber,
      warrantyNumberCode: item?.warrantyNumberCode,
      shippingId: item?.shippingId,
      itemCode: item?.itemCode,
    }));

    updateTracking(payload, {
      onSuccess: () => {
        setTableData([]);
        reset({
          claimCode: '',
          trackingNumber: '',
          warrantyNumberCode: '',
        });
      },
    });
  };

  const columnData = columns({ onDeleteRecordScanDelivery });
  const formUpdateObj = useForm({ defaultValues: {} });

  return {
    control,
    setValue,
    getValues,
    columnData,
    handleOnEnter,
    formUpdateObj,
    shippingSelfOptions,
    trackingInputRef,
    claimCodeInputRef,
    warrantyNumberCodeRef,
    onSubmit,
    handleSubmit,
    loadingScanDelivery,
    loadingWarrantyQr,
    tableData,
    itemCodeInputRef,
    loadingUpdateTracking,
  };
};
