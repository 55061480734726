import React from 'react';
import { StyledContainer } from '../../../components/container/styles';
import { TextLarge } from '../../../components/text';
import { Col, Row, Spin } from 'antd';
import { CardTheme } from '../../../components/card';
import { ButtonTheme, RenderForm } from '../../../components';
import { ContainerButton } from '../../../styles/global-style';
import { FORM_CLAIM_EDIT_CLAIM, FORM_CLAIM_EDIT_PERSONAL, FORM_CLAIM_EDIT_PICTURE } from './form';
import { useGetClaimEditList } from './hooks/useGetClaimEditList';
import { TimelineComponent } from '../../../components/timeline';

export const ClaimEdit = () => {
  const {
    control,
    setValue,
    getValues,
    timelineList,
    isLoadingClaimDetail,
    callZipCode,
    provinceOptions,
    districtOptions,
    subDistrictOptions,
    breakdownList,
    onSubmit,
    isPendingUpload,
    channelClaimOptions,
    storeTypeClaimOptions,
    showStoreField,
    isPendingZipCode,
  } = useGetClaimEditList();

  return (
    <Spin spinning={isLoadingClaimDetail || isPendingUpload || isPendingZipCode}>
      <StyledContainer>
        <TextLarge text="รายละเอียดรายการเคลมสินค้า" />
        <Row gutter={[24, 24]}>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="ข้อมูลส่วนตัว" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_CLAIM_EDIT_PERSONAL({
                  onEnter: callZipCode,
                  provinceOptions,
                  districtOptions,
                  subDistrictOptions,
                })}
              />
            </CardTheme>
          </Col>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="ข้อมูลการแจ้งเคลม" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_CLAIM_EDIT_CLAIM({
                  breakdownList,
                  channelClaimOptions,
                  storeTypeClaimOptions,
                  showStoreField,
                })}
              />
            </CardTheme>
          </Col>
          <Col span={4}>
            <TextLarge text="สถานะการเคลม" /> <br />
            <TimelineComponent timelineList={timelineList} />
          </Col>
        </Row>
        <br />
        <Row gutter={[24, 24]}>
          <Col span={10}>
            <CardTheme>
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_CLAIM_EDIT_PICTURE()}
              />
            </CardTheme>
          </Col>
        </Row>
        <ContainerButton center>
          <ButtonTheme useFor="SUBMIT" onClick={onSubmit} />
        </ContainerButton>
      </StyledContainer>
    </Spin>
  );
};
