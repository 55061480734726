import { put } from 'redux-saga/effects';
import { GET, GET_PROFILE, POST } from '../../services';
import { LOGIN, LOGIN_GOOGLE } from './../../services/index';
import * as type from './types';
import { closeLoading, openAlert, openLoading } from '../../components/alert/hooks/useAlert';
import { navigateTo } from '../../utils/navigation';
import { ROUTES_PATH, ROUTE_LOGIN_MANUAL } from '../../resources/routes-name';
import { setCookies, removeCookies } from './../../store/useCookies';

export function* login(payload) {
  try {
    openLoading();
    const users = yield POST(LOGIN, payload.payload);
    yield put({ type: type.AUTHEN_LOGIN_SUCCESS, users });
    setCookies('accessToken', users.result.accessToken);
    navigateTo({ pathname: ROUTES_PATH.ROUTE_MAIN.PATH });
  } catch (e) {
    openAlert({ type: 'error', message: e.message });
    yield put({ type: type.AUTHEN_LOGIN_FAIL, message: e.message });
  } finally {
    closeLoading();
  }
}

export function* logout() {
  try {
    yield put({ type: type.AUTHEN_LOGOUT_SUCCESS });
    removeCookies('accessToken');
  } catch (e) {
    openAlert({ type: 'error', message: e.message });
    yield put({ type: type.AUTHEN_LOGOUT_FAIL, message: e.message });
  } finally {
    navigateTo({ pathname: ROUTE_LOGIN_MANUAL });
  }
}

export function* loginGoogle(payload) {
  try {
    openLoading();
    const result = yield POST(LOGIN_GOOGLE, payload.payload);
    yield put({ type: type.AUTHEN_LOGIN_GOOGLE_SUCCESS });
    setCookies('accessToken', result.result.accessToken);
    navigateTo({ pathname: ROUTES_PATH.ROUTE_MAIN.PATH });
  } catch (e) {
    openAlert({ type: 'error', message: e.message });
    yield put({ type: type.AUTHEN_LOGIN_GOOGLE_FAIL, message: e.message });
  } finally {
    closeLoading();
  }
}

export function* getProfile() {
  try {
    openLoading();
    const resultGetProfile = yield GET(GET_PROFILE);
    const { privilegeFocusDataList, ...rest } = resultGetProfile.result.item;
    const menuData = privilegeFocusDataList
      ?.filter((e) => e.status === 'ACTIVE')
      .map((e) => e.code);
    yield put({
      type: type.AUTHEN_GET_PROFILE_SUCCESS,
      users: rest,
      menu: menuData,
    });
  } catch (e) {
    openAlert({ type: 'error', message: e.message });
    yield put({ type: type.AUTHEN_GET_PROFILE_FAIL, message: e.message });
  } finally {
    closeLoading();
  }
}
