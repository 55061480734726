import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { GET, GET_REPORT_HISTORY_FULL } from '../../../services';

export const useGetReportClaimFull = ({ handleSubmit }) => {
  const [data, setData] = useState([]);

  const { isPending, mutate: onSearchFull } = useMutation({
    mutationFn: async (searchParamState) => {
      const { search } = searchParamState;
      const response = await GET(
        GET_REPORT_HISTORY_FULL({
          search: search || '',
        }),
      );
      return response?.result?.item?.claimHistory || [];
    },
    onSuccess: (response) => {
      setData(response);
    },
  });

  const onFetchFull = () =>
    handleSubmit((values) => {
      return onSearchFull(values);
    })();

  return { dataSource: data, setData, onSearchFull, loading: isPending, onFetchFull };
};
