import React from 'react';
import Pages from '../screens/all-page';
import { color } from './color.js';
import { Icon } from './icon';
import { FaCog } from 'react-icons/fa';

export const ROUTE_LOGIN = 'https://central.zetta-system.com';
export const ROUTE_LOGIN_MANUAL = '/account/login';
export const ROUTE_HANDLE = '/account/handle';
export const KEY_ROUTE_MAIN = '@ROUTES/ROUTE_MAIN';
export const KEY_ROUTE_HOME = '@ROUTES/ROUTE_HOME';
export const KEY_ROUTE_WARRANTY = '@ROUTES/ROUTE_WARRANTY';
export const KEY_ROUTE_WARRANTY_ADD = '@ROUTES/ROUTE_WARRANTY_ADD';
export const KEY_ROUTE_WARRANTY_EDIT = '@ROUTES/ROUTE_WARRANTY_EDIT';
export const KEY_ROUTE_WARRANTY_VIEW = '@ROUTES/ROUTE_WARRANTY_VIEW';
export const KEY_ROUTE_CLAIM = '@ROUTES/ROUTE_CLAIM';
export const KEY_ROUTE_CLAIM_ADD = '@ROUTES/ROUTE_CLAIM_ADD';
export const KEY_ROUTE_CLAIM_EDIT = '@ROUTES/ROUTE_CLAIM_EDIT';
export const KEY_ROUTE_CLAIM_VIEW = '@ROUTES/ROUTE_CLAIM_VIEW';
export const KEY_ROUTE_USER_INFO = '@ROUTES/ROUTE_USER_INFO';
export const KEY_ROUTE_STORE_SHOP = '@ROUTES/ROUTE_STORE_SHOP';
export const KEY_ROUTE_PRODUCT_WARRANTY = '@ROUTES/ROUTE_PRODUCT_WARRANTY';
export const KEY_ROUTE_COLOR_QR = '@ROUTES/ROUTE_COLOR_QR';
export const KEY_ROUTE_BRAND = '@ROUTES/ROUTE_BRAND';
export const KEY_ROUTE_MODEL = '@ROUTES/ROUTE_MODEL';
export const KEY_ROUTE_PRODUCT = '@ROUTES/ROUTE_PRODUCT';
export const KEY_ROUTE_EDIT_PRODUCT = '@ROUTES/ROUTE_EDIT_PRODUCT';
export const KEY_ROUTE_SCAN_DELIVERY = '@ROUTES/ROUTE_SCAN_DELIVERY';
export const KEY_ROUTE_CUT_OF_WARRANTY = '@ROUTES/ROUTE_CUT_OF_WARRANTY';
export const KEY_ROUTE_REPORT_ALL = '@ROUTES/ROUTE_REPORT_ALL';
export const KEY_ROUTE_REPORT = '@ROUTES/ROUTE_REPORT';
export const KEY_ROUTE_REPORT_CLAIM = '@ROUTES/ROUTE_REPORT_CLAIM';
export const KEY_ROUTE_USER_PERMISSION = '@ROUTES/ROUTE_USER_PERMISSION';
export const KEY_ROUTE_GEN_QR_CODE = '@ROUTES/ROUTE_GEN_QR_CODE';

function renderStyle(comp) {
  return <div style={{ marginRight: '1px', color: color.blueFocus }}>{comp}</div>;
}

export const ROUTES_PATH = {
  ROUTE_MAIN: {
    KEY: KEY_ROUTE_MAIN,
    PATH: '/',
    LABEL: 'หน้าหลัก',
    CODE: 'PE001',
    COMPONENT: Pages.MainPage,
  },
  // Warranty
  ROUTE_WARRANTY: {
    KEY: KEY_ROUTE_WARRANTY,
    ICON: renderStyle(<Icon.registered />),
    PATH: '/warranty',
    LABEL: 'รายการลงทะเบียน',
    CODE: 'PE101',
    COMPONENT: Pages.Warranty,
  },
  ROUTE_WARRANTY_ADD: {
    KEY: KEY_ROUTE_WARRANTY_ADD,
    PATH: '/warranty-add',
    LABEL: 'ลงทะเบียนสินค้า',
    CODE: 'PE111',
    COMPONENT: Pages.WarrantyAdd,
  },
  ROUTE_WARRANTY_EDIT: {
    KEY: KEY_ROUTE_WARRANTY_EDIT,
    PATH: '/warranty-edit',
    LABEL: 'แก้ไขรายการลงทะเบียน',
    CODE: 'PE112',
    COMPONENT: Pages.WarrantyEdit,
  },
  ROUTE_WARRANTY_VIEW: {
    KEY: KEY_ROUTE_WARRANTY_VIEW,
    PATH: '/warranty-view',
    LABEL: 'ดูข้อมูลลงทะเบียน',
    CODE: 'PE113',
    COMPONENT: Pages.WarrantyView,
  },
  // Claim
  ROUTE_CLAIM: {
    KEY: KEY_ROUTE_CLAIM,
    ICON: renderStyle(<Icon.fileSearch />),
    PATH: '/claim',
    LABEL: 'เคลมสินค้า',
    CODE: 'PE201',
    COMPONENT: Pages.ClaimList,
  },
  ROUTE_CLAIM_VIEW: {
    KEY: KEY_ROUTE_CLAIM_VIEW,
    PATH: '/claim-view',
    LABEL: 'ดูการเคลมสินค้า',
    CODE: 'PE210',
    COMPONENT: Pages.ClaimView,
  },
  ROUTE_CLAIM_ADD: {
    KEY: KEY_ROUTE_CLAIM_ADD,
    PATH: '/claim-add',
    LABEL: 'เคลมสินค้า',
    CODE: 'PE211',
    COMPONENT: Pages.ClaimAdd,
  },
  ROUTE_CLAIM_EDIT: {
    KEY: KEY_ROUTE_CLAIM_EDIT,
    PATH: '/claim-edit',
    LABEL: 'แก้ไขเคลมสินค้า',
    CODE: 'PE212',
    COMPONENT: Pages.ClaimEdit,
  },
  //Scan Delivery
  ROUTE_SCAN_DELIVERY: {
    KEY: KEY_ROUTE_SCAN_DELIVERY,
    ICON: renderStyle(<Icon.qrcode />),
    PATH: '/scan-delivery',
    LABEL: 'ยิงจ่ายพัสดุ',
    CODE: 'PE301',
    COMPONENT: Pages.ScanDelivery,
  },
  //Cut of Warranty
  ROUTE_CUT_OF_WARRANTY: {
    KEY: KEY_ROUTE_CUT_OF_WARRANTY,
    ICON: renderStyle(<Icon.cut />),
    PATH: '/cut-off-warranty',
    LABEL: 'ตัดสินค้ารับประกัน',
    CODE: 'PE302',
    COMPONENT: Pages.CutOfWarranty,
  },
  // User Info
  ROUTE_USER_INFO: {
    KEY: KEY_ROUTE_USER_INFO,
    ICON: renderStyle(<Icon.users />),
    PATH: '/user',
    LABEL: 'ข้อมูลลูกค้า',
    CODE: 'PE401',
    COMPONENT: Pages.UserInfo,
  },
  ROUTE_STORE_SHOP: {
    KEY: KEY_ROUTE_STORE_SHOP,
    ICON: renderStyle(<Icon.store />),
    PATH: '/shop',
    LABEL: 'ร้านค้า',
    CODE: 'PE403',
    COMPONENT: Pages.StoreShop,
  },
  ROUTE_COLOR_QR: {
    KEY: KEY_ROUTE_COLOR_QR,
    ICON: renderStyle(<Icon.ticket />),
    PATH: '/color-qr',
    LABEL: 'สี QR',
    CODE: 'PE501',
    COMPONENT: Pages.ColorQr,
  },
  ROUTE_BRAND: {
    KEY: KEY_ROUTE_BRAND,
    ICON: renderStyle(<Icon.ticket />),
    PATH: '/brand',
    LABEL: 'แบรนด์มือถือ',
    CODE: 'PE502',
    COMPONENT: Pages.Brand,
  },
  ROUTE_MODEL: {
    KEY: KEY_ROUTE_MODEL,
    ICON: renderStyle(<Icon.ticket />),
    PATH: '/model',
    LABEL: 'รุ่นมือถือ',
    CODE: 'PE503',
    COMPONENT: Pages.Model,
  },
  ROUTE_PRODUCT: {
    KEY: KEY_ROUTE_PRODUCT,
    ICON: renderStyle(<Icon.ticket />),
    PATH: '/product',
    LABEL: 'สินค้า',
    CODE: 'PE504',
    COMPONENT: Pages.Product,
  },
  ROUTE_EDIT_PRODUCT: {
    KEY: KEY_ROUTE_EDIT_PRODUCT,
    PATH: '/edit-product',
    LABEL: 'แก้ไขสินค้า',
    CODE: 'PE512',
    COMPONENT: Pages.EditProduct,
  },
  // Report
  ROUTE_REPORT: {
    KEY: KEY_ROUTE_REPORT,
    ICON: renderStyle(<Icon.ticket />),
    PATH: '/report',
    LABEL: 'รายงานประวัติการเคลม',
    CODE: 'PE601',
    COMPONENT: Pages.ReportPage,
  },
  ROUTE_REPORT_CLAIM: {
    KEY: KEY_ROUTE_REPORT_CLAIM,
    ICON: renderStyle(<Icon.ticket />),
    PATH: '/report-claim',
    LABEL: 'รายงานเปลี่ยนสินค้าข้ามรุ่น',
    CODE: 'PE602',
    COMPONENT: Pages.ReportClaim,
  },
  // User Permission
  ROUTE_USER_PERMISSION: {
    KEY: KEY_ROUTE_USER_PERMISSION,
    ICON: renderStyle(<FaCog />),
    PATH: '/manage-permission',
    LABEL: 'ปรับสิทธิ์เมนู',
    CODE: 'PE701',
    COMPONENT: Pages.UserPermission,
  },
  //Gen QR Code
  ROUTE_GEN_QR_CODE: {
    KEY: KEY_ROUTE_GEN_QR_CODE,
    ICON: renderStyle(<Icon.qrcode />),
    PATH: '/gen-qr-code',
    LABEL: 'สร้าง QR Code',
    CODE: 'PE801',
    COMPONENT: Pages.GenQrCode,
  },
};

function getKeyLowerCase(obj) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k.toLowerCase(), v]));
}

export const ROUTES_MENU = [
  getKeyLowerCase(ROUTES_PATH.ROUTE_WARRANTY),
  getKeyLowerCase(ROUTES_PATH.ROUTE_CLAIM),
  getKeyLowerCase(ROUTES_PATH.ROUTE_SCAN_DELIVERY),
  getKeyLowerCase(ROUTES_PATH.ROUTE_CUT_OF_WARRANTY),
  getKeyLowerCase(ROUTES_PATH.ROUTE_USER_INFO),
  getKeyLowerCase(ROUTES_PATH.ROUTE_STORE_SHOP),
  {
    key: KEY_ROUTE_PRODUCT_WARRANTY,
    icon: renderStyle(<Icon.formExample />),
    label: 'สินค้ารับประกัน',
    children: [
      getKeyLowerCase(ROUTES_PATH.ROUTE_COLOR_QR),
      getKeyLowerCase(ROUTES_PATH.ROUTE_BRAND),
      getKeyLowerCase(ROUTES_PATH.ROUTE_MODEL),
      getKeyLowerCase(ROUTES_PATH.ROUTE_PRODUCT),
    ],
  },
  {
    key: KEY_ROUTE_REPORT_ALL,
    icon: renderStyle(<Icon.formExample />),
    label: 'รายงาน',
    children: [
      getKeyLowerCase(ROUTES_PATH.ROUTE_REPORT),
      getKeyLowerCase(ROUTES_PATH.ROUTE_REPORT_CLAIM),
    ],
  },
  getKeyLowerCase(ROUTES_PATH.ROUTE_GEN_QR_CODE),
  getKeyLowerCase(ROUTES_PATH.ROUTE_USER_PERMISSION),
];
