import { useEffect, useState } from 'preact/hooks';
import { useForm } from 'react-hook-form';
import { useGetWarrantyView } from './useGetWarrantyView';
import { navigateTo } from '../../../../utils/navigation';
import { ROUTES_PATH } from '../../../../resources/routes-name';

export const useGetWarrantyViewList = () => {
  const { control, setValue, getValues, reset, watch } = useForm();
  const [state, setState] = useState();
  const queryParams = new URLSearchParams(window.location.search);
  const warrantyCode = queryParams.get('code');
  const storeId = watch('storeId');

  useEffect(() => {
    setState({ warrantyCode });
  }, [warrantyCode]);

  const { dataSource: dataWarrantyView, loading: loadingWarrantyView } = useGetWarrantyView({
    warrantyCode: state?.warrantyCode,
    reset,
    getValues,
  });

  useEffect(() => {
    if (storeId) {
      setValue('storeId', dataWarrantyView?.warranty?.storeName);
    }
  }, [dataWarrantyView, storeId]);

  const goBack = () => {
    navigateTo({
      pathname: ROUTES_PATH.ROUTE_WARRANTY.PATH,
      state: getValues(),
    });
  };
  return {
    control,
    setValue,
    getValues,
    loadingWarrantyView,
    goBack,
    state,
    reset,
    storeId,
  };
};
