import React from 'react';
import { SpaceBetween, StyledContainer } from '../../../components/container/styles';
import { TextLarge } from '../../../components/text';
import { CardTheme } from '../../../components/card';
import { ResponsivePageLayout } from '../../../components/page/responsive-layout/ResponsivePageLayout';
import { InfoRegistration } from './views/InfoRegistration';
import { Icon } from '../../../resources/icon';
import { ContainerButton } from '../../../styles/global-style';
import ButtonExcelEmbedded from '../../../components/report-excel/ButtonExcelEmbedded';
import { ButtonTheme } from '../../../components';
import { columns, exportHeaders } from './columns';
import { useWarrantyList } from './hooks/useWarrantyList';

export const Warranty = () => {
  const {
    searchType,
    formSearchObj,
    dataDate,
    dataFull,
    isLoading,
    isLoadingFull,
    onSearch,
    gotoWarrantyAdd,
    handleDelete,
    formSearch,
    transformDataForExcel,
    privilegeFocusType,
  } = useWarrantyList();

  return (
    <StyledContainer>
      <TextLarge text="รายงานการลงทะเบียน" />
      <CardTheme>
        <ContainerButton right>
          <ButtonTheme
            useFor="WARRANTY"
            title="ลงทะเบียนสินค้า"
            onClick={() => gotoWarrantyAdd()}
          />
        </ContainerButton>
        <ResponsivePageLayout
          searchLayout={{
            title: 'ค้นหารายการลงทะเบียน',
            icon: <Icon.todo />,
            formSearch,
            useFormParam: formSearchObj,
            onSearch: formSearchObj.handleSubmit(onSearch),
            onClear: formSearchObj.reset,
          }}
          content={<InfoRegistration />}
          tableLayout={{
            columns: columns({ handleDelete, privilegeFocusType }),
            dataSource: searchType === 'search' ? dataFull : dataDate,
            hasPagination: true,
            loading: searchType === 'search' ? isLoadingFull : isLoading,
            extraContent: (
              <SpaceBetween>
                <ContainerButton right>
                  <ButtonExcelEmbedded
                    title="Export"
                    filename="รายงานการลงทะเบียน"
                    header={exportHeaders}
                    data={transformDataForExcel(searchType === 'search' ? dataFull : dataDate)}
                  />
                </ContainerButton>
              </SpaceBetween>
            ),
          }}
        />
      </CardTheme>
    </StyledContainer>
  );
};
