import React from 'react';
import { StyledContainer } from '../../components/container/styles';
import { TextLarge } from '../../components/text';
import { CardTheme } from '../../components/card';
import { ButtonTheme, RenderForm } from '../../components';
import { useGenQrCodeList } from './hooks/useGenQrCodeList';
import { Col, Row, Spin } from 'antd';
import { ContainerButton } from '../../styles/global-style';
import { ResponsiveTableLayout } from '../../components/page/responsive-layout/views/ResponsiveTableLayout';
import { InfoGenQrCode } from './views/InfoGenQrCode';

export const GenQrCode = () => {
  const {
    control,
    setValue,
    getValues,
    formSearch,
    columnsData,
    dataQr,
    onSubmit,
    handleSubmit,
    loadingInsertQr,
    loadingCategory,
    loadingDataQr,
    loadingGetQrExcel,
  } = useGenQrCodeList();
  return (
    <Spin spinning={loadingInsertQr || loadingCategory || loadingDataQr || loadingGetQrExcel}>
      <StyledContainer>
        <TextLarge text="สร้าง QR Code" />
        <CardTheme>
          <Row gutter={16}>
            <Col span={12}>
              <CardTheme>
                <RenderForm
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  forms={formSearch}
                />
                <ContainerButton center>
                  <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
                </ContainerButton>
              </CardTheme>
            </Col>
            <Col span={12}></Col>
            <InfoGenQrCode />
          </Row>
          <ResponsiveTableLayout
            columns={columnsData || []}
            dataSource={dataQr}
            hasPagination={true}
          />
        </CardTheme>
      </StyledContainer>
    </Spin>
  );
};
