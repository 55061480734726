import { Popconfirm } from 'antd';
import { ButtonTheme, ButtonToolTip } from '../../../../components';
import {
  createColumnButtonArr,
  createColumnNumber,
  createColumnText,
  widthOptions,
} from '../../../../components/table/function';

export const columns = ({ onEditSpecialWarranty, mutateDelete }) => {
  return [
    createColumnText('รหัสช่องทางการสั่งซื้อ', 'channelBuyCode', widthOptions(190)),
    createColumnText('ชื่อช่องทางการสั่งซื้อ', 'channelBuyNameTh', widthOptions(180)),
    createColumnNumber('ระยะเวลารับประกัน', 'specialWarrantyPeriod', widthOptions(180)),
    createColumnButtonArr('จัดการ', 'rowNo', {
      others: {
        fixed: 'right',
        width: 170,
      },
      buttonArr: [
        {
          useFor: 'EDIT2',
          render: (_, record) => (
            <ButtonToolTip toolTip="แก้ไข" placement="topLeft">
              <ButtonTheme useFor="EDIT2" onClick={() => onEditSpecialWarranty(record)} />
            </ButtonToolTip>
          ),
        },
        {
          useFor: 'DELETE',
          render: (_, record) => (
            <ButtonToolTip toolTip="ลบ" placement="topLeft">
              <Popconfirm
                title="คุณต้องการที่จะลบร้านค้าใช่หรือไม่?"
                onConfirm={() => mutateDelete(record)}
                okText="ใช่"
                cancelText="ไม่"
              >
                <ButtonTheme useFor="DELETE" />
              </Popconfirm>
            </ButtonToolTip>
          ),
        },
      ],
    }),
  ];
};
