import React from 'react';
import { useForm } from 'react-hook-form';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../../components';
import { TextLarge, TextSmall } from '../../../../components/text';
import { FORM_CANCLE } from '../form';
import { ContainerButton } from '../../../../styles/global-style';
import { useEffect } from 'preact/hooks';
import { useCancleClaim } from '../hooks/useCancleClaim';

export const CancleClaim = ({ open, onToggle, claimData }) => {
  const { control, setValue, getValues, handleSubmit } = useForm({});
  const { mutate: cancelClaim } = useCancleClaim();

  useEffect(() => {
    if (claimData) {
      setValue('claimId', claimData?.claimId);
    }
  }, [claimData]);

  const onSubmit = (values) => {
    cancelClaim(values);
    onToggle({ defaultValue: '' });
  };

  return (
    <ModalTheme title=" " open={open} onToggle={onToggle}>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <TextLarge text="หมายเหตุ" />
      </div>
      <div style={{ textAlign: 'left', width: '100%' }}>
        <TextSmall text={'ชื่อ : ' + claimData?.fullName} />
        <TextSmall text={'เลขที่เคลม : ' + claimData?.claimCode} />
      </div>
      <RenderForm
        control={control}
        forms={FORM_CANCLE()}
        setValue={setValue}
        getValues={getValues}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
