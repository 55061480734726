import React from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import * as ROUTES from '../../resources/routes-name';

export const BreadcrumbRoutes = () => {

  const renderLabel = () => {
    for (const mn in ROUTES.ROUTES_PATH) {
      if (ROUTES.ROUTES_PATH[mn].PATH === window.location.pathname) {
        return ROUTES.ROUTES_PATH[mn].LABEL
      }
    }
  }

  return (
    <div className='breadcrumb-box'>
      <Breadcrumb>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
        </Breadcrumb.Item>
        {(window.location.pathname).split('/').map(path =>
          path && (<Breadcrumb.Item href={`/${path}`}>
            <span>{renderLabel()}</span>
          </Breadcrumb.Item>)
        )}
      </Breadcrumb>
    </div>
  )
}