import { useEffect } from 'react';
import { logout, login, getProfile } from '../redux/authen/action';
import { ROUTE_LOGIN, ROUTE_LOGIN_MANUAL } from '../resources/routes-name';
import { navigateTo, windowNavigateReplaceTo } from '../utils/navigation';
import { useDispatch } from 'react-redux';
import { getCookies } from '../store/useCookies';

export const useAuthEffect = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    //
  }, [dispatch]);
};

export const useAuthLogin = () => {
  const dispatch = useDispatch();

  function toLogin() {
    windowNavigateReplaceTo({ pathname: ROUTE_LOGIN });
  }

  const onLogin = async (values) => {
    dispatch(login(values));
  };

  function checkLoginToken() {
    const accessToken = getCookies('accessToken');
    if (accessToken) {
      return dispatch(getProfile());
    }
    return dispatch(logout());
  }

  function redirectToMain() {
    const accessToken = getCookies('accessToken');
    if (accessToken) {
      navigateTo({ pathname: ROUTE_LOGIN_MANUAL });
    }
  }

  return {
    toLogin,
    checkLoginToken,
    redirectToMain,
    onLogin,
  };
};
