import { useMutation } from '@tanstack/react-query';
import { POST, POST_INSERT_TYPES } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';

export const useInsertTypes = ({ onSuccessCallback }) => {
  return useMutation({
    mutationFn: async ({ payload }) => {
      const response = await POST(POST_INSERT_TYPES(), payload);
      return response;
    },

    onSuccess: (response) => {
      openAlert({ model: 'message', type: 'success', message: response?.message });
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: (err) => {
      openAlert({ model: 'message', type: 'error', message: err?.message });
    },
  });
};
