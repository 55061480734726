import { useForm } from 'react-hook-form';
import { mobileBrandCodeQuery } from '../../../../services/react-query/optionMasterQuery';
import { FORM_SEARCH, searchDefaultValues } from '../form';
import { columns } from '../columns';
import { useGetModel } from './useGetModel';
import { useModal } from '../../../../components/modal/hooks/useModal';
import { useUpdateStatusModel } from './useUpdateStatusModel';
import { UpdateStatusModel } from '../../../../resources/payload';

export const useGetModelList = () => {
  const { open: openEdit, onToggle: onToggleEdit } = useModal();
  const { data: mobileBrandList } = mobileBrandCodeQuery();
  const formSearchObj = useForm({ defaultValues: searchDefaultValues });

  const {
    dataSource: dataModel,
    onFetch,
    loading: isLoadingModelList,
  } = useGetModel({
    handleSubmit: formSearchObj.handleSubmit,
  });

  const { mutate: updateStatusModel } = useUpdateStatusModel({
    onSuccessCallBack: () => {
      onFetch();
    },
  });

  const onToggleStatus = (checked, record) => {
    const payload = UpdateStatusModel(record, checked);
    updateStatusModel(payload);
  };

  const columnData = columns({ onToggleStatus });
  const formSearch = FORM_SEARCH({ mobileBrandList });

  return {
    formSearch,
    formSearchObj,
    columnData,
    dataModel,
    isLoadingModelList,
    onFetch,
    openEdit,
    onToggleEdit,
  };
};
