import { brandQuery, modelQuery } from '../../../../services/react-query/optionMasterQuery';

export const useManagementData = (selectedBrandId) => {
  const { data: brandList, isPending: loadingBrand } = brandQuery();
  const { data: modelList, isPending: loadingModel } = selectedBrandId
    ? modelQuery(selectedBrandId)
    : '';

  return {
    brandList,
    modelList,
    loadingBrand,
    loadingModel,
  };
};
