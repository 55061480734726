import React from 'react';
import { useForm } from 'react-hook-form';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../../components';
import { FORM_INSERT_COLOR } from '../form';
import { ContainerButton } from '../../../../styles/global-style';
import { useInsertColorList } from '../hooks/useInsertColorList';
import { Spin } from 'antd';

export const AddColor = ({ open, onToggle, onFetch }) => {
  const { control, setValue, getValues, handleSubmit } = useForm();

  const { mutate: onSubmitInsertColor, isPending: loadingInsertColor } = useInsertColorList({
    onSuccessCallBack: onFetch,
  });

  const onSubmit = (value) => {
    onSubmitInsertColor(value);
    onToggle({ defaultValues: '' });
  };

  return (
    <Spin spinning={loadingInsertColor}>
      <ModalTheme title="เพิ่มสี QR" open={open} onToggle={onToggle}>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          forms={FORM_INSERT_COLOR()}
        />
        <ContainerButton right>
          <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
          <ButtonTheme useFor="CANCEL" onClick={onToggle} />
        </ContainerButton>
      </ModalTheme>
    </Spin>
  );
};
