import { useMutation } from '@tanstack/react-query';
import { POST, POST_CLAIMS_INSERT } from '../../../../services';
import { insertClaim } from '../../../../resources/payload';
import { openAlert } from '../../../../components/alert/hooks';

export const onUploadFile = () =>
  useMutation({
    mutationFn: async (values) => {
      const formData = new FormData();
      const payload = insertClaim(values);

      Object.entries(payload).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          formData.append(key, value);
        }
      });

      const response = await POST(POST_CLAIMS_INSERT(), formData);
      return response;
    },
    onSuccess: (result) => {
      openAlert({ model: 'message', type: 'success', message: result.message });
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    },
    onError: (response) => {
      openAlert({
        model: 'message',
        type: 'error',
        message: response?.message || 'เกิดข้อผิดพลาด',
      });
    },
  });
