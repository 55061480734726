import React, { useEffect } from 'react';
import { ModalTheme } from '../../../../components/modal/index.jsx';
import { useForm } from 'react-hook-form';
import { ContainerButton } from '../../../../styles/global-style.js';
import { FORM_TRACKING_NUMBER } from '../form/index.js';
import { TextLarge, TextSmall } from '../../../../components/text/index.jsx';
import { ButtonTheme, RenderForm } from '../../../../components/index.js';
import { shippingSelfQuery } from '../../../../services/react-query/optionMasterQuery.js';
import { useUpdateTracking } from '../hooks/useUpdateTrackNumber.js';

export const ChangeTrackNumber = ({
  open,
  onToggle,
  claimData,
  onFetch,
  onFetchClaimFull,
  searchType,
}) => {
  const { control, setValue, getValues, handleSubmit } = useForm({});
  const { data: shippingOptions } = shippingSelfQuery();

  const { mutate: updateTracking } = useUpdateTracking({
    onSuccessCallBack: onFetch,
  });

  useEffect(() => {
    setValue('trackingNumber', claimData?.trackingNumber);
    setValue('shippingId', claimData?.shippingId);
    setValue('claimId', claimData?.claimId);
  }, [claimData]);

  const onSubmit = (values) => {
    updateTracking(values, {
      onSuccess: () => {
        searchType === 'search' ? onFetchClaimFull() : onFetch();
        onToggle({ defaultValue: '' });
      },
    });
  };

  return (
    <ModalTheme title=" " open={open} onToggle={onToggle}>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <TextLarge text="แก้ไขเลขพัสดุ" />
      </div>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <TextSmall text={claimData?.claimCode + ' ' + claimData?.fullName} />
      </div>
      <RenderForm
        control={control}
        forms={FORM_TRACKING_NUMBER({ shippingOptions })}
        setValue={setValue}
        getValues={getValues}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
