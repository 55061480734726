import { Popconfirm } from 'antd';
import { ButtonTheme, ButtonToolTip } from '../../../components';
import {
  createColumnButtonArr,
  createColumnText,
  widthOptions,
} from '../../../components/table/function';

export const columns = ({ onDeleteRecordScanDelivery }) => {
  return [
    createColumnText('เลขที่ลงทะเบียน', 'warrantyCode', widthOptions(170)),
    createColumnText('เลขที่เคลม', 'claimCode', widthOptions(150)),
    createColumnText('ชื่อ-นามสกุล', 'fullName', widthOptions(150)),
    createColumnText('เบอร์โทรศัพท์', 'telephone', widthOptions(150)),
    createColumnText('ชื่อสินค้า', 'productName', widthOptions(150)),
    createColumnText('ประเภท', 'claimType', widthOptions(150)),
    createColumnText('สาขา', 'storeName', widthOptions(120)),
    createColumnText('รหัสรับประกันที่ยกเลิก', 'warrantyQr', widthOptions(200)),
    createColumnText('สถานะ', 'claimStatusName', widthOptions(150)),
    createColumnButtonArr('จัดการ', 'rowNo', {
      others: {
        fixed: 'right',
        width: 180,
      },
      buttonArr: [
        {
          useFor: 'DELETE',
          render: (_, record) => (
            <ButtonToolTip toolTip="ลบ" placement="topLeft">
              <Popconfirm
                title="คุณต้องการที่จะลบสี QR ใช่หรือไม่?"
                onConfirm={() => onDeleteRecordScanDelivery(record)}
                okText="ใช่"
                cancelText="ไม่"
              >
                <ButtonTheme useFor="DELETE" />
              </Popconfirm>
            </ButtonToolTip>
          ),
        },
      ],
    }),
  ];
};
