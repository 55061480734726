import { Popconfirm } from 'antd';
import { ButtonTheme, ButtonToolTip } from '../../../components';
import {
  createColumnButtonArr,
  createColumnText,
  widthOptions,
} from '../../../components/table/function';

export const columns = ({ onDeleteRecordScanDelivery }) => {
  return [
    createColumnText('เลขที่เคลม', 'claimCode', widthOptions(150)),
    createColumnText('QR Code ที่ตัดรับประกัน', 'warrantyNumberCode', widthOptions(200)),
    createColumnText('เลขพัสดุ', 'trackingNumber', widthOptions(150)),
    createColumnText('รหัสสินค้า', 'itemCode', widthOptions(150)),
    createColumnText('ชื่อสกุล', 'fullName', widthOptions(150)),
    createColumnText('เบอร์', 'telephone', widthOptions(150)),
    createColumnText('สินค้า', 'mobileModelName', widthOptions(150)),
    createColumnText('Item Code', 'itemCode', widthOptions(150)),
    createColumnButtonArr('จัดการ', 'rowNo', {
      others: {
        fixed: 'right',
        width: 180,
      },
      buttonArr: [
        {
          useFor: 'DELETE',
          render: (_, record) => (
            <ButtonToolTip toolTip="ลบ" placement="topLeft">
              <Popconfirm
                title="คุณต้องการที่จะลบสี QR ใช่หรือไม่?"
                onConfirm={() => onDeleteRecordScanDelivery(record)}
                okText="ใช่"
                cancelText="ไม่"
              >
                <ButtonTheme useFor="DELETE" />
              </Popconfirm>
            </ButtonToolTip>
          ),
        },
      ],
    }),
  ];
};
