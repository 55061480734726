import React, { Fragment } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useController } from 'react-hook-form';
import { Button, Upload, Modal, Form } from 'antd';
import { Required, RowComponent } from '../../../styles/global-style';
import { TextXSMall, TextInputLabel } from '../../text';
import { UploadOutlined } from '@ant-design/icons';
import { renderTypeError } from '../index.jsx';
import { useState } from 'react';
import heic2any from 'heic2any';

/**
 * Convert HEIF/HEIC images to JPEG before processing
 * @param {File} file - The image file to convert if needed
 * @returns {Promise<File>} - A promise that resolves to the converted file (or original if not HEIF)
 */
const convertHeicToJpeg = async (file) => {
  // Check if the file is a HEIC/HEIF format by extension or type
  if (
    file.name.toLowerCase().endsWith('.heic') ||
    file.name.toLowerCase().endsWith('.heif') ||
    file.type === 'image/heic' ||
    file.type === 'image/heif'
  ) {
    try {
      // Convert the HEIC file to JPEG using heic2any
      const jpegBlob = await heic2any({
        blob: file,
        toType: 'image/jpeg',
        quality: 0.8,
      });

      // Get original filename without extension
      const originalName = file.name.substring(0, file.name.lastIndexOf('.')) || file.name;

      // Create a new File from the blob
      const jpegFile = new File([jpegBlob], `${originalName}.jpg`, {
        type: 'image/jpeg',
        lastModified: Date.now(),
      });

      return jpegFile;
    } catch (error) {
      // If conversion fails, return the original file
      return file;
    }
  }

  // If not a HEIC/HEIF file, return as is
  return file;
};

/**
 * Resizes an image file to ensure it doesn't exceed the maximum size
 * and converts it to JPG format
 * @param {File} file - The image file to resize
 * @param {number} maxSizeKB - Maximum file size in KB (default: 250KB)
 * @returns {Promise<File>} - A promise that resolves to the resized file in JPG format
 */
const resizeImage = async (file, maxSizeKB = 250) => {
  // Only resize if needed and not already a small JPG
  if (file.size <= maxSizeKB * 1024 && file.type === 'image/jpeg') {
    return file;
  }

  return new Promise((resolve) => {
    // Create image element to load the file
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      img.src = e.target.result;

      img.onload = () => {
        // Create canvas for resizing
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;
        let quality = 0.9; // Start with high quality
        const maxSize = maxSizeKB * 1024;

        // Try resizing with better quality first
        const resizeAndCheck = () => {
          canvas.width = width;
          canvas.height = height;

          // Draw image on canvas
          const ctx = canvas.getContext('2d');
          // For non-JPG images, set white background to avoid transparency issues
          if (file.type !== 'image/jpeg') {
            ctx.fillStyle = '#FFFFFF';
            ctx.fillRect(0, 0, width, height);
          }
          ctx.drawImage(img, 0, 0, width, height);

          // Always convert to JPEG format
          canvas.toBlob(
            (blob) => {
              if (blob.size <= maxSize) {
                // Get original filename without extension
                const originalName =
                  file.name.substring(0, file.name.lastIndexOf('.')) || file.name;
                // Create new file from blob with .jpg extension
                const resizedFile = new File([blob], `${originalName}.jpg`, {
                  type: 'image/jpeg',
                  lastModified: Date.now(),
                });
                resolve(resizedFile);
              } else {
                // Reduce quality first (as it preserves more detail)
                if (quality > 0.5) {
                  quality -= 0.1;
                  resizeAndCheck();
                } else {
                  // If quality reduction isn't enough, reduce dimensions
                  const scaleFactor = Math.sqrt(maxSize / blob.size) * 0.9;
                  width = Math.floor(width * scaleFactor);
                  height = Math.floor(height * scaleFactor);
                  resizeAndCheck();
                }
              }
            },
            'image/jpeg',
            quality,
          );
        };

        resizeAndCheck();
      };
    };

    reader.readAsDataURL(file);
  });
};

const UploadFiles = ({ control, item, setValue, getValues }) => {
  const { rules, name, defaultValue, label, disabled, dataCy, ...propsInput } = item;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const { fieldState, field } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  const onChange = async (event) => {
    // Process uploaded files for resizing and format conversion
    const fileList = [...event.fileList];

    // Process each file in the list that has an originFileObj
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      if (file.originFileObj && file.originFileObj instanceof File) {
        try {
          // Only process image files
          if (
            file.originFileObj.type.startsWith('image/') ||
            file.originFileObj.name.toLowerCase().endsWith('.heic') ||
            file.originFileObj.name.toLowerCase().endsWith('.heif')
          ) {
            // First, convert HEIC/HEIF to JPEG if needed
            const convertedFile = await convertHeicToJpeg(file.originFileObj);

            // Then resize the image
            const resizedFile = await resizeImage(convertedFile);

            // Replace the original file with the processed one
            file.originFileObj = resizedFile;

            // Update the filename displayed in the UI
            file.name = resizedFile.name;
          }
        } catch (error) {
          console.error('Error processing image:', error);
        }
      }
    }

    // Set the processed file list to the form
    setValue(name, fileList);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <Fragment>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <Upload
        id={name}
        beforeUpload={() => false}
        listType={item.listType || 'picture'} /** picture-card, picture */
        disabled={disabled || false}
        maxCount={item.maxCount || 1}
        multiple={item.maxCount > 1 || false}
        accept={item.accept || '*'}
        onPreview={handlePreview}
        fileList={getValues(name)}
        onChange={onChange}
        {...propsInput}
      >
        {value?.length === item.maxCount ? null : item.listType !== 'picture-card' ? (
          <Button icon={<UploadOutlined />} {...dataCy}>
            {item.placeholder || 'Upload'}
          </Button>
        ) : (
          uploadButton
        )}
      </Upload>
      <Modal
        destroyOnClose={true}
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const MemoizedUpload = UploadFiles; //memo(, compareRender);

const PreviewFiles = ({ control, item, getValues }) => {
  const { rules, name, defaultValue, label, disabled, ...propsInput } = item;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const { fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleCancel = () => setPreviewOpen(false);

  return (
    <Fragment>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <Upload
        id={name}
        listType={item.listType || 'picture-card'}
        disabled={disabled || false}
        accept={item.accept || '*'}
        onPreview={handlePreview}
        fileList={getValues(name) || []}
        showUploadList={{
          showPreviewIcon: true,
          showRemoveIcon: false, // ซ่อนปุ่มลบ
        }}
        {...propsInput}
      />
      <Modal
        destroyOnClose={true}
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const MemoizedPreview = PreviewFiles;
