import { useForm } from 'react-hook-form';
import { FORM_GEN_QR_CODE } from '../form';
import { columns } from '../columns';
import {
  categoryQuery,
  genQrCodeFullSearchQuery,
} from '../../../services/react-query/optionMasterQuery';
import { useGetQrExcel } from './useGetQrExcel';
import { useInsertQr } from './useInsertQr';

export const useGenQrCodeList = () => {
  const { control, setValue, getValues, handleSubmit } = useForm();
  const { data: categoryList, isPending: loadingCategory } = categoryQuery();
  const { data: dataQr, isPending: loadingDataQr } = genQrCodeFullSearchQuery();

  const { onGetQrExcel, loadingGetQrExcel } = useGetQrExcel();
  const { mutate: onInsertQr, isPending: loadingInsertQr } = useInsertQr();

  const onSubmit = (values) => {
    onInsertQr(values);
  };

  const columnsData = columns({ onGetQrExcel });
  const formSearch = FORM_GEN_QR_CODE({ categoryList });

  return {
    control,
    setValue,
    getValues,
    formSearch,
    columnsData,
    dataQr,
    onSubmit,
    handleSubmit,
    loadingCategory,
    loadingDataQr,
    loadingGetQrExcel,
    loadingInsertQr,
  };
};
