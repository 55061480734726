import React from 'react';
import { SpaceBetween, StyledContainer } from '../../components/container/styles';
import { TextLarge } from '../../components/text';
import { CardTheme } from '../../components/card';
import { ResponsivePageLayout } from '../../components/page/responsive-layout/ResponsivePageLayout';
import { ContainerButton } from '../../styles/global-style';
import ButtonExcelEmbedded from '../../components/report-excel/ButtonExcelEmbedded';
import { Icon } from '../../resources';
import { useGetStoreList } from './hooks/useGetStoreList';
import { ButtonTheme } from '../../components';
import { AddStoreModal } from './modals/AddStoreModal';
import { EditStoreModal } from './modals/EditStoreModal';
import { QrCodeStore } from './modals/QrCodeStore';

export const StoreShop = () => {
  const {
    formSearch,
    formSearchObj,
    columnData,
    dataStore,
    isLoadingStore,
    onFetch,
    openAdd,
    onToggleAdd,
    openEdit,
    onToggleEdit,
    datasetStore,
    openQrCode,
    onToggleQrCode,
    loadingDeleteStore,
  } = useGetStoreList();

  return (
    <StyledContainer>
      <TextLarge text="ข้อมูลร้านค้า" />
      <CardTheme>
        <ContainerButton right>
          <ButtonTheme useFor="NEW_STORE" onClick={onToggleAdd} />
          {openAdd && <AddStoreModal open={openAdd} onToggle={onToggleAdd} onFetch={onFetch} />}
        </ContainerButton>
        <ResponsivePageLayout
          searchLayout={{
            title: 'ค้นหาข้อมูลร้านค้า',
            formSearch: formSearch,
            useFormParam: formSearchObj,
            icon: <Icon.todo />,
            onSearch: onFetch,
            ignoreDateEmpty: true,
          }}
          tableLayout={{
            columns: columnData,
            dataSource: dataStore,
            hasPagination: true,
            loading: isLoadingStore || loadingDeleteStore,
            extraContent: (
              <SpaceBetween>
                <ContainerButton right>
                  <ButtonExcelEmbedded
                    title="Export Excel"
                    header={columnData}
                    data={dataStore}
                    filename="รายงานข้อมูลลูกค้า"
                  />
                </ContainerButton>
              </SpaceBetween>
            ),
          }}
        />
      </CardTheme>
      {openEdit && (
        <EditStoreModal
          open={openEdit}
          onToggle={onToggleEdit}
          initialValues={datasetStore}
          onFetch={onFetch}
        />
      )}
      {openQrCode && (
        <QrCodeStore open={openQrCode} onToggle={onToggleQrCode} initialValues={datasetStore} />
      )}
    </StyledContainer>
  );
};
