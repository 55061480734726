import React from 'react';
import { StyledContainer } from '../../../components/container/styles';
import { TextLarge } from '../../../components/text';
import { Col, Row, Spin } from 'antd';
import { CardTheme } from '../../../components/card';
import { ButtonTheme, RenderForm } from '../../../components';
import { ContainerButton } from '../../../styles/global-style';
import {
  FORM_CLAIM_DETAIL_CLAIM,
  FORM_CLAIM_DETAIL_PERSONAL,
  FORM_CLAIM_EDIT_DETAIL_PICTURE,
} from './form';
import { TimelineComponent } from '../../../components/timeline';
import { useGetClaimViewList } from './hooks/useGetClaimViewList';
import { RejectMessage } from './modals/RejectMessage';
import { ChangeProductModal } from './modals/changeProduct';
import { CancleClaim } from './modals/CancleClaim';

export const ClaimView = () => {
  const {
    goBack,
    control,
    setValue,
    getValues,
    timelineList,
    isLoadingClaimDetail,
    claimData,
    onSubmitApprove,
    isPendingApproveClaim,
    openReject,
    onToggleReject,
    onModalReject,
    gotoEditClaim,
    openChangeProduct,
    onToggleChangeProduct,
    data,
    openCancle,
    onToggleCancle,
    DISABLED_APPROVE_STATUSES,
    privilegeFocusType,
  } = useGetClaimViewList();

  return (
    <Spin spinning={isLoadingClaimDetail}>
      <StyledContainer>
        <TextLarge text="รายละเอียดรายการเคลมสินค้า" />
        <Row gutter={[24, 24]}>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="ข้อมูลส่วนตัว" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_CLAIM_DETAIL_PERSONAL()}
              />
            </CardTheme>
          </Col>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="ข้อมูลการแจ้งเคลม" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_CLAIM_DETAIL_CLAIM()}
              />
            </CardTheme>
          </Col>
          <Col span={4}>
            <TextLarge text="สถานะการเคลม" /> <br />
            <TimelineComponent timelineList={timelineList} />
          </Col>
        </Row>
        <br />
        <Row gutter={[24, 24]}>
          <Col span={10}>
            <CardTheme>
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_CLAIM_EDIT_DETAIL_PICTURE()}
              />
            </CardTheme>
          </Col>
        </Row>
        <Row justify="space-between" align="middle" style={{ marginTop: '16px' }}>
          <Col>
            <ContainerButton>
              <ButtonTheme useFor="BACK" title="ย้อนกลับ" onClick={goBack} />
              {privilegeFocusType !== 'READ' && (
                <ButtonTheme useFor="CANCLE" title="ยกเลิกรายการ" onClick={onToggleCancle} />
              )}
              {openCancle && (
                <CancleClaim open={openCancle} onToggle={onToggleCancle} claimData={claimData} />
              )}
            </ContainerButton>
          </Col>
          {privilegeFocusType !== 'READ' && claimData?.claimStatus !== 'TRANSPORTED' && (
            <Col>
              <ContainerButton>
                {isPendingApproveClaim ? (
                  <Spin spinning={isPendingApproveClaim}></Spin>
                ) : (
                  <ButtonTheme
                    useFor="CONFIRM"
                    title="อนุมัติ"
                    onClick={onSubmitApprove}
                    disabled={DISABLED_APPROVE_STATUSES?.includes(claimData?.claimStatus)}
                    style={{ marginRight: '8px' }}
                  />
                )}
                <ButtonTheme
                  useFor="REJECT"
                  title="ไม่อนุมัติ"
                  onClick={() => onModalReject(claimData)}
                  disabled={claimData?.claimStatus !== 'WAIT'}
                />
              </ContainerButton>
            </Col>
          )}
          <Col>
            <ContainerButton>
              {privilegeFocusType !== 'READ' && (
                <ButtonTheme
                  useFor="CHANGE"
                  title="เปลี่ยนสินค้า"
                  onClick={onToggleChangeProduct}
                  style={{ marginRight: '8px' }}
                />
              )}
              {openChangeProduct && (
                <ChangeProductModal
                  open={openChangeProduct}
                  onToggle={onToggleChangeProduct}
                  initialValues={data}
                />
              )}
              {privilegeFocusType !== 'READ' && claimData?.claimStatus !== 'TRANSPORTED' && (
                <ButtonTheme useFor="EDIT2" title="แก้ไข" onClick={gotoEditClaim} />
              )}
            </ContainerButton>
          </Col>
        </Row>
        {openReject && (
          <RejectMessage open={openReject} onToggle={onToggleReject} claimData={claimData} />
        )}
      </StyledContainer>
    </Spin>
  );
};
