import { useMemo } from 'preact/hooks';
import { serviceOptions } from '../../../../functions/serviceOption';

export const useOptions = ({ watch }) => {
  const {
    address,
    province,
    provinceId,
    district,
    districtId,
    subDistrict,
    addresses,
    warranties,
    warrantyId,
  } = watch();

  const addressFullOptions = useMemo(() => serviceOptions('ADDRESS', addresses), [addresses]);

  const provinceOptions = useMemo(() => serviceOptions('PROVINCE', province), [province]);

  const districtOptions = useMemo(
    () => (provinceId ? serviceOptions('DISTRICT', district) : []),
    [provinceId, district],
  );

  const subDistrictOptions = useMemo(
    () => (districtId ? serviceOptions('SUBDISTRICT', subDistrict) : []),
    [districtId, subDistrict],
  );

  const addressData = address;

  const warrantyOptions = useMemo(() => serviceOptions('CLAIM_PRODUCT', warranties), [warranties]);

  const selectedWarranty = warranties?.find((item) => item?.warrantyId === warrantyId);

  const breakdownOptions = useMemo(
    () =>
      selectedWarranty?.breakdowns ? serviceOptions('BREAKDOWN', selectedWarranty?.breakdowns) : [],
    [selectedWarranty?.breakdowns],
  );

  return {
    addressFullOptions,
    provinceOptions,
    districtOptions,
    subDistrictOptions,
    addressData,
    warrantyOptions,
    breakdownOptions,
  };
};
