import {
  createColumnSwitch,
  createColumnText,
  widthOptions,
} from '../../../../components/table/function';

export const columns = ({ onToggleStatus }) => {
  return [
    createColumnText('แบรนด์', 'mobileBrandNameTh', widthOptions(150)),
    createColumnText('รหัสรุ่นมือถือ', 'mobileModelCode', widthOptions(150)),
    createColumnText('ชื่อรุ่นมือถือ', 'mobileModelNameEn', widthOptions(150)),
    createColumnSwitch('สถานะ', 'mobileModelStatus', {
      onChange: onToggleStatus,
      textChecked: 'Active',
      textUnChecked: 'Inactive',
      others: {
        fixed: 'right',
        width: 100,
      },
    }),
  ];
};
