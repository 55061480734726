import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { convertDateInputToPayload } from '../../../functions/convert';
import { GET, GET_REPORT_HISTORY_BY_DATE } from '../../../services';

export const useGetReportByDate = ({ handleSubmit }) => {
  const [data, setData] = useState([]);
  const { isPending, mutate: onSearch } = useMutation({
    mutationFn: async (searchParamState) => {
      const { startDate, tel, fullname, customerCode, claimType } = searchParamState;
      const [startDateFrom, startDateTo] = startDate || ['', ''];

      const response = await GET(
        GET_REPORT_HISTORY_BY_DATE({
          tel: tel || '',
          fullname: fullname || '',
          customerCode: customerCode || '',
          startDate: convertDateInputToPayload(startDateFrom),
          endDate: convertDateInputToPayload(startDateTo),
          claimType: claimType || '',
        }),
      );
      return response?.result?.item?.claimHistory || [];
    },
    onSuccess: (response) => {
      setData(response);
    },
  });

  const onFetch = () =>
    handleSubmit((values) => {
      return onSearch(values);
    })();

  return { dataSource: data, setData, onSearch, isLoading: isPending, onFetch };
};
