import {
  createColumnDate,
  createColumnNumber,
  createColumnText,
  widthOptions,
} from '../../../components/table/function';

export const columns = () => {
  return [
    createColumnNumber('ลำดับ', 'rowNumber', widthOptions(50)),
    createColumnText('ชื่อ-นามสกุล', 'fullName', widthOptions(150)),
    createColumnText('รหัสลงทะเบียน', 'warrantyCode', widthOptions(150)),
    createColumnText('ผู้ทำรายการ', 'logBy', widthOptions(150)),
    createColumnNumber('จำนวนการเปลี่ยน', 'totalChanges', widthOptions(170)),
    createColumnDate('วันที่เปลี่ยน', 'logDate', widthOptions(170)),
  ];
};

export const columnBefore = () => {
  return [
    createColumnText('รหัสเคลมสินค้า', 'itemCode', widthOptions(150)),
    createColumnText('ชื่อสินค้า', 'productName', widthOptions(150)),
    createColumnText('รุ่นสินค้า', 'mobileModelName', widthOptions(150)),
    createColumnText('แบรนด์', 'mobileBrandName', widthOptions(150)),
  ];
};

export const columnAfter = () => {
  return [
    createColumnText('รหัสเคลมสินค้า', 'itemCode', widthOptions(150)),
    createColumnText('ชื่อสินค้า', 'productName', widthOptions(150)),
    createColumnText('รุ่นสินค้า', 'mobileModelName', widthOptions(150)),
    createColumnText('แบรนด์', 'mobileBrandName', widthOptions(150)),
    createColumnText('หมายเหตุ', 'remark', widthOptions(150)),
  ];
};
