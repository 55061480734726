import { useMutation } from '@tanstack/react-query';
import { POST, POST_UPDATE_STORE } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';
import { updateFocusShop } from '../../../resources/payload';

export const useUpdateStore = ({ onSuccessCallBack }) =>
  useMutation({
    mutationFn: async ({ ...values }) => {
      const payload = updateFocusShop(values);
      const response = await POST(POST_UPDATE_STORE(), payload);
      if (onSuccessCallBack) onSuccessCallBack(response);

      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'แก้ไขข้อมูลสำเร็จ',
      });
    },
    onError: (error) => {
      openAlert({
        model: 'message',
        type: 'error',
        message: error?.message,
      });
    },
  });
