export const FORM_CLAIM_CREATE = ({
  watch,
  showOldAddress,
  onEnter,
  addressFullOptions,
  provinceOptions,
  districtOptions,
  subDistrictOptions,
  showStoreField,
}) => {
  const span = 12;

  const SHOW_RADIO = [
    ...(!['FOCUS', 'STORE'].includes(showStoreField)
      ? [
          {
            name: 'isCreateNewAddress',
            label: 'ที่อยู่เดิม',
            type: 'RADIO',
            span: 24,
            defaultValue: 'false',
            properties: {
              options: [
                { label: 'ที่อยู่เดิม', value: 'false' },
                { label: 'เพิ่มที่อยู่ใหม่', value: 'true' },
              ],
            },
            rules: {
              required: true,
            },
          },
        ]
      : []),
  ];

  return [
    {
      name: 'telephone',
      label: 'หมายเลขโทรศัพท์',
      type: 'INPUT_PHONE',
      placeholder: 'กรุณากรอกหมายเลขโทรศัพท์',
      span,
      onEnter,
      maxLength: 10,
      rules: {
        required: true,
        pattern: /^[0-9]*$/,
      },
    },
    {
      name: 'fullName',
      label: 'ชื่อ-นามสกุล',
      type: 'INPUT',
      placeholder: 'กรุณากรอกชื่อ-นามสกุล',
      span,
      rules: {
        required: true,
      },
    },
    ...(showOldAddress ? SHOW_RADIO : []),
    ...(watch('isCreateNewAddress') !== 'true' && !['FOCUS', 'STORE'].includes(showStoreField)
      ? [
          {
            name: 'customerAddressId',
            label: 'เลือกที่อยู่',
            type: 'DROPDOWN',
            placeholder: 'กรุณาเลือก',
            properties: {
              options: addressFullOptions || [],
            },
            rules: {
              required: true,
            },
            span: 24,
          },
        ]
      : []),
    ...(!['FOCUS', 'STORE'].includes(showStoreField)
      ? [
          {
            name: 'info',
            label: 'หากต้องการเพิ่มที่อยู่ใหม่กรอกรหัสไปรษณีย์แล้วกด Enter',
            type: 'TEXT',
            span: 24,
            style: {
              textDecoration: 'underline',
            },
          },
          {
            name: 'zipCode',
            label: 'รหัสไปรษณีย์',
            type: 'INPUT',
            placeholder: 'กรุณากรอกรหัสไปรษณีย์',
            span,
            onEnter,
            rules: {
              required: true,
            },
          },
          {
            name: 'provinceId',
            label: 'จังหวัด',
            type: 'DROPDOWN',
            placeholder: 'กรุณาเลือกจังหวัด',
            properties: {
              options: provinceOptions || [],
            },
            span,
            rules: {
              required: true,
            },
          },
          {
            name: 'districtId',
            label: 'เขต/อำเภอ',
            type: 'DROPDOWN',
            placeholder: 'กรุณาเลือกเขต/อำเภอ',
            properties: {
              options: districtOptions || [],
            },
            span,
            rules: {
              required: true,
            },
          },
          {
            name: 'subDistrictId',
            label: 'แขวง/ตำบล',
            type: 'DROPDOWN',
            placeholder: 'กรุณาเลือกแขวง/ตำบล',
            properties: {
              options: subDistrictOptions || [],
            },
            span,
            rules: {
              required: true,
            },
          },
          {
            name: 'other',
            label: 'เพิ่มเติม',
            type: 'INPUT',
            placeholder: '',
            rules: {
              required: true,
            },
            span: 24,
          },
        ]
      : []),
  ];
};

export const FORM_CLAIM_DATA = ({
  warrantyOptions,
  breakdownOptions,
  channelClaim,
  storeTypeChannelOptions,
  showStoreField,
}) => {
  const span = 24;
  const accept = [`image/jpeg`, 'image/png', 'image/jpg', 'image/bmp', 'image/tiff'];

  const SHOW_STORE = [
    {
      name: 'storeId',
      label: 'ร้านค้า',
      type: 'DROPDOWN',
      placeholder: '',
      properties: {
        options: storeTypeChannelOptions || [],
      },
      rules: {
        required: true,
      },
      span,
    },
  ];

  const SHOW_DATE_SERVICE = [
    {
      name: 'bookingDate',
      label: 'วันที่บริการ',
      type: 'DATE_PICKER',
      rules: {
        required: true,
      },
      span,
    },
  ];

  return [
    {
      name: 'warrantyId',
      label: 'เลือกสินค้าที่ต้องการเคลม',
      type: 'DROPDOWN',
      properties: {
        options: warrantyOptions || [],
      },
      span,
      style: {
        height: 'auto',
        maxHeight: '200px',
        width: '100%',
        overflowY: 'auto',
      },
      rules: {
        required: true,
        message: 'กรุณาเลือกสินค้า',
      },
    },

    {
      name: 'breakdownId',
      label: 'อาการเสีย',
      type: 'DROPDOWN',
      properties: {
        options: breakdownOptions || [],
      },
      rules: {
        required: true,
        message: 'กรุณาเลือกอาการเสีย',
      },
      span,
    },
    {
      name: 'detail1',
      label: 'รายละเอียด',
      type: 'INPUT',
      placeholder: '',
      span,
    },
    {
      name: 'channelClaimId',
      label: 'ช่องทางการเคลม',
      type: 'DROPDOWN',
      properties: {
        options: channelClaim || [],
      },
      rules: {
        required: true,
      },
      span,
    },
    ...(['STORE', 'FOCUS'].includes(showStoreField) ? SHOW_STORE : []),
    ...(showStoreField === 'FOCUS' ? SHOW_DATE_SERVICE : []),
    {
      name: 'claimImage',
      label: 'รูปภาพสินค้า (.png, .jpg, .jpeg, .bmp)',
      type: 'UPLOAD',
      listType: 'picture-card',
      accept: accept.join(','),
      span,
      rules: {
        required: true,
      },
      maxCount: 1,
    },
    {
      name: 'receiptImage',
      label: 'รูปภาพสลิปค่าขนส่ง (.png, .jpg, .jpeg, .bmp)',
      type: 'UPLOAD',
      listType: 'picture-card',
      accept: accept.join(','),
      span,
      maxCount: 1,
    },
  ];
};
