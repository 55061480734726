import { convertYearInputToPayload } from '../../functions/convert';

export const UpdateWarrantyEdit = (values) => {
  const formData = new FormData();

  formData.append('warrantyId', values.warrantyId);
  formData.append('telephone', values.telephone);
  formData.append('fullName', values.fullName);
  formData.append('warrantyNumberCode', values.warrantyNumberCode);
  formData.append('mobileBrandId', values.mobileBrandId);
  formData.append('mobileModelId', values.mobileModelId);
  formData.append('mapProductId', values.mapProductId);
  formData.append('channelBuyId', values.channelBuyId);
  formData.append('warrantyTypeId', values.warrantyTypeId);
  formData.append('storeId', values.storeId);

  if (values.invImageUrl && values.invImageUrl.length > 0) {
    const file = values.invImageUrl[0];
    if (file.originFileObj) {
      formData.append('invImage', file.originFileObj);
    } else if (file.url) {
      formData.append('invImageUrl', file.url);
    }
  }

  if (values.packageImageUrl && values.packageImageUrl.length > 0) {
    const file = values.packageImageUrl[0];
    if (file.originFileObj) {
      formData.append('packageImage', file.originFileObj);
    } else if (file.url) {
      formData.append('packageImageUrl', file.url);
    }
  }

  return formData;
};

export const InsertWarranty = (value) => {
  return {
    invImage: value.invImage
      ? value.invImage.originFileObj ||
        (value.invImage[0] && value.invImage[0].originFileObj) ||
        value.invImage
      : null,
    packageImage: value.packageImage
      ? value.packageImage.originFileObj ||
        (value.packageImage[0] && value.packageImage[0].originFileObj) ||
        value.packageImage
      : null,
    telephone: value.telephone,
    fullName: value.fullName,
    warrantyNumberCode: value.warrantyNumberCode,
    mobileBrandId: value.mobileBrandId,
    mobileModelId: value.mobileModelId,
    mapProductId: value.mapProductId,
    channelBuyId: value.channelBuyId,
    storeId: value.storeId || '',
  };
};

export const updateExpired = (values) => {
  return {
    warrantyId: values.warrantyId,
    expireDate: values.expireDate,
    remark: values.remark,
  };
};

export const updateTracking = (value) => {
  return {
    claimId: value.claimId,
    shippingId: value.shippingId,
    trackingNumber: value.trackingNumber,
    shippingDate: convertYearInputToPayload(value.shippingDate),
  };
};

export const sendMessage = (value) => {
  return {
    claimId: value.claimId,
    message: value.message,
  };
};

export const sendRemark = (value) => {
  return {
    claimId: value.claimId,
    remark: value.remark,
  };
};

export const rejectClaim = (value) => {
  return {
    claimId: value.claimId,
    rejectReason: value.rejectReason,
  };
};

export const cancelClaim = (value) => {
  return {
    claimId: value.claimId,
    cancelReason: value.cancelReason,
  };
};

export const updateProduct = (values) => {
  return {
    warrantyNumberCode: values.warrantyNumberCode,
    claimId: values.claimId,
    claimCode: values.claimCode,
    categoryId: values.categoryId,
    mobileBrandId: values.mobileBrandId,
    mobileModelId: values.mobileModelId,
    mapProductId: values.mapProductId,
    remark: values.remark,
  };
};

export const updateClaim = (values) => {
  const formData = new FormData();
  formData.append('claimId', values?.claimId);
  formData.append('customerCode', values?.customerCode);
  formData.append('detail1', values?.detail1);
  formData.append('detail2', values?.detail2);
  formData.append('breakdownId', values?.breakdownId);
  formData.append('addressLine1', values?.addressLine1);
  formData.append('addressLine2', values?.addressLine2);
  formData.append('subDistrictId', values?.subDistrictId);
  formData.append('zipCode', values?.zipCode);
  formData.append('remark', values?.remark);
  formData.append('other', values?.other);
  formData.append('channelClaimId', values?.channelClaimId);
  formData.append('storeId', values?.storeId);
  formData.append('bookingDate', convertYearInputToPayload(values?.bookingDate));

  if (values?.claimImageUrl && values?.claimImageUrl.length > 0) {
    const file = values?.claimImageUrl[0];
    if (file.originFileObj) {
      formData.append('claimImage', file.originFileObj);
    } else if (file.url) {
      formData.append('claimImageUrl', file.url);
    }
  }

  if (values?.receiptImageUrl && values?.receiptImageUrl.length > 0) {
    const file = values?.receiptImageUrl[0];
    if (file.originFileObj) {
      formData.append('receiptImage', file.originFileObj);
    } else if (file.url) {
      formData.append('receiptImageUrl', file.url);
    }
  }

  return formData;
};

export const insertClaim = (value) => {
  return {
    claimImage: value.claimImage ? value.claimImage.originFileObj : null,
    receiptImage: value.receiptImage ? value.receiptImage.originFileObj : null,
    warrantyId: value.warrantyId,
    customerCode: value.customerCode,
    detail1: value.detail1,
    detail2: value.detail2,
    breakdownId: value.breakdownId,
    addressLine1: value.addressLine1,
    addressLine2: value.addressLine2,
    subDistrictId: value.subDistrictId,
    zipCode: value.zipCode,
    other: value.other,
    isCreateNewAddress: value.isCreateNewAddress,
    storeId: value.storeId,
    channelClaimId: value.channelClaimId,
    bookingDate: convertYearInputToPayload(value.bookingDate),
  };
};

export const sendMessageLine = (values) => {
  return {
    customerId: values.customerId,
    message: values.message,
  };
};

export const updateUserInfo = (values) => {
  return {
    customerId: values.customerId,
    fullName: values.fullName,
    email: values.email,
    birthday: values.birthday,
    customerLevel: values.customerLevel,
  };
};

export const updateTel = (value) => {
  return {
    customerId: value.customerId,
    telephone: value.telephone,
  };
};

export const createFocusShop = (value) => {
  return {
    customerAccountCode: value.customerAccountCode,
    saleCode: value.saleCode,
    storeMasterName: value.storeMasterName,
    storeName: value.storeName,
    phoneNumber: value.phoneNumber,
    email: value.email,
    storeTypeId: value.storeTypeId,
  };
};

export const updateFocusShop = (value) => {
  return {
    storeMasterName: value.storeMasterName,
    storeName: value.storeName,
    phoneNumber: value.phoneNumber,
    email: value.email,
    storeTypeId: value.storeTypeId,
    storeId: value.storeId,
    storeStatus: value.storeStatus === 'ACTIVE' ? true : false,
  };
};

export const InsertColor = (value) => {
  return {
    categoryCode: value.categoryCode,
    categoryName: value.categoryName,
    detail1: value.detail1,
    detail2: value.detail2,
    remark: value.remark,
    defaultWarrantyPeriod: parseInt(value.defaultWarrantyPeriod, 10) || 0,
    defaultClaimQty: parseInt(value.defaultClaimQty, 10) || 0,
  };
};

export const DeleteColor = (value) => {
  return {
    categoryId: value.categoryId,
    remark: value.remark,
  };
};

export const UpdateCategory = (value) => {
  return {
    categoryId: value.categoryId,
    categoryCode: value.categoryCode,
    categoryName: value.categoryName,
    detail1: value.detail1,
    detail2: value.detail2,
    remark: value.remark,
    defaultWarrantyPeriod: parseInt(value.defaultWarrantyPeriod, 10) || 0,
    defaultClaimQty: parseInt(value.defaultClaimQty, 10) || 0,
  };
};

export const UpdateBrand = (values) => {
  const formData = new FormData();

  formData.append('mobileBrandId', values.mobileBrandId);
  formData.append('mobileBrandCode', values.mobileBrandCode);
  formData.append('seqNo', values.seqNo);
  formData.append('mobileBrandNameTh', values.mobileBrandNameTh);
  formData.append('mobileBrandNameEn', values.mobileBrandNameEn);
  formData.append('mobileBrandStatus', values.mobileBrandStatus);

  if (values.mobileBrandImageUrl && values.mobileBrandImageUrl.length > 0) {
    const file = values.mobileBrandImageUrl[0];
    if (file.originFileObj) {
      formData.append('mobileBrandImage', file.originFileObj);
    } else if (file.url) {
      formData.append('mobileBrandImageUrl', file.url);
    }
  }

  return formData;
};

export const UpdateModel = (values) => {
  const formData = new FormData();

  formData.append('mobileModelId', values.mobileModelId);
  formData.append('mobileModelCode', values.mobileModelCode);
  formData.append('mobileModelNameTh', values.mobileModelNameTh);
  formData.append('mobileModelNameEn', values.mobileModelNameEn);
  formData.append('mobileModelStatus', values.mobileModelStatus);
  formData.append('remark', values.remark);
  formData.append('seqNo', values.seqNo);

  if (values?.mobileModelImageUrl && values?.mobileModelImageUrl?.length > 0) {
    const file = values?.mobileModelImageUrl[0];
    if (file?.originFileObj) {
      formData.append('mobileModelImage', file.originFileObj);
    } else if (file?.url) {
      formData.append('mobileModelImageUrl', file.url);
    }
  }

  return formData;
};

export const UpdateManagement = (value) => {
  return {
    productImage: value.productImage ? value.productImageUrl.originFileObj : null,
    mapProductId: value.mapProductId,
    categoryId: value.categoryId,
    mobileBrandId: value.mobileBrandId,
    mobileModelId: value.mobileModelId,
    itemCode: value.itemCode,
    productGroupCode: value.productGroupCode,
    productTypeCode: value.productTypeCode,
    productName: value.productName,
    salebotDescription: value.salebotDescription,
    salebotDescriptionTh: value.salebotDescriptionTh,
    salebotColorTh: value.salebotColorTh,
    warrantyPeriod: parseInt(value.warrantyPeriod, 10) || 0,
    specialWarrantyStatus: value.specialWarrantyStatus,
    isSpecialWarrantyStatus: value.specialWarrantyStatus === 'ACTIVE' ? true : false,
    mapProductStatus: value.mapProductStatus,
    salebotColor: value.salebotColor,
    seqNo: parseInt(value.seqNo, 10) || 0,
  };
};

export const InsertManagementData = (value) => {
  return {
    mapProductId: value.mapProductId,
    channelBuyId: value.channelBuyId,
    specialWarrantyPeriod: parseInt(value.specialWarrantyPeriod, 10) || 0,
  };
};

export const UpdateManagementData = (value) => {
  return {
    mapProductSpecialWarrantyId: value.mapProductSpecialWarrantyId,
    mapProductId: value.mapProductId,
    channelBuyId: value.channelBuyId,
    specialWarrantyPeriod: parseInt(value.specialWarrantyPeriod, 10) || 0,
  };
};

export const DeleteManagementData = (value) => {
  return {
    mapProductSpecialWarrantyId: value.mapProductSpecialWarrantyId,
    remark: value.remark,
  };
};

export const UpdateStatusBrand = (record, checked) => {
  return {
    mobileBrandId: record.mobileBrandId,
    mobileBrandStatus: checked ? 'ACTIVE' : 'INACTIVE',
  };
};

export const UpdateStatusModel = (record, checked) => {
  return {
    mobileModelId: record.mobileModelId,
    mobileModelStatus: checked ? 'ACTIVE' : 'INACTIVE',
  };
};

export const UpdateProcessStatus = (values) => {
  return {
    claimId: values.claimId,
    processStatus: values.processStatus,
  };
};

export const updateTrack = (values) => {
  return {
    claimId: values.claimId,
    claimCode: values.claimCode,
    trackingNumber: values.trackingNumber,
    warrantyNumberCode: values.warrantyNumberCode,
    shippingId: values.shippingId,
    itemCode: values.itemCode,
  };
};

export const updatePermission = ({ userId, selectedRowKeys, dataSource }) => {
  return {
    userId: userId,
    privilegeFocusData: dataSource?.map((item) => {
      return {
        code: item.code,
        status: selectedRowKeys.includes(item.id) ? 'ACTIVE' : 'INACTIVE',
      };
    }),
  };
};

export const postCutOfWarranty = (values) => {
  return {
    claimCodeOrWarrantyCode: values.claimCodeOrWarrantyCode,
  };
};

export const postCutOfWarrantyBulk = (values) => {
  return {
    claimId: values.claimId,
    claimCode: values.claimCode,
    warrantyNumberCode: values.warrantyNumberCode,
    storeId: values.storeId,
  };
};

export const InsertQr = (values) => {
  return {
    categoryId: values.categoryId,
    recipientEmail: values.recipientEmail,
    urlLink: values.urlLink,
    serialNumberAmount: parseInt(values.serialNumberAmount, 10) || 0,
    remark: values.remark,
    startDate: values.startDate,
    expireDate: values.expireDate,
  };
};
