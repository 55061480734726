import { useMutation } from '@tanstack/react-query';
import { POST, POST_RESET_LINE } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';

export const useResetLine = ({ onSuccessCallBack }) => {
  return useMutation({
    mutationFn: async ({ customerId }) => {
      const payload = {
        customerId,
      };
      const response = await POST(POST_RESET_LINE(), payload);
      if (onSuccessCallBack) onSuccessCallBack(response);

      return response.result.item;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'รีเซ็ตไลน์สำเร็จ',
      });
    },
    onError: (err) => {
      openAlert({
        model: 'message',
        type: 'error',
        message: err.message || 'รีเซ็ตไลน์ไม่สำเร็จ',
      });
    },
  });
};
