export const FORM_SEARCH = ({ mobileBrandList }) => {
  return [
    {
      name: 'search',
      label: 'แบรนด์มือถือ',
      type: 'DROPDOWN',
      properties: {
        options: mobileBrandList || [],
      },
      rules: {
        required: true,
      },
      span: 8,
    },
  ];
};

export const searchDefaultValues = {
  search: '',
};

export const FORM_EDIT_MODEL = ({ mobileBrandList, statusOptions }) => {
  const span = 24;
  return [
    {
      name: 'mobileBrandId',
      label: 'แบรนด์',
      type: 'DROPDOWN',
      properties: {
        options: mobileBrandList || [],
      },
      disabled: true,
      span,
    },
    {
      name: 'mobileModelCode',
      label: 'รหัสรุ่นมือถือ',
      type: 'INPUT',
      disabled: true,
      span,
    },
    {
      name: 'mobileModelNameEn',
      label: 'ชื่อรุ่นมือถือ',
      type: 'INPUT',
      disabled: true,
      span,
    },
    {
      name: 'mobileModelStatus',
      label: 'สถานะ',
      type: 'DROPDOWN',
      properties: {
        options: statusOptions || [],
      },
      span,
    },
    {
      name: 'remark',
      label: 'หมายเหตุ (ระบุได้ไม่เกิน 250 ตัวอักษร)',
      type: 'INPUT_AREA',
      placeholder: 'กรุณากรอกหมายเหตุ',
      rules: {
        maxLength: 250,
      },
      span,
    },
  ];
};
