import { useForm } from 'react-hook-form';
import { FORM_SEARCH, searchDefaultValue } from '../form';
import { useGetUserInfo } from './useGetUserInfo';
import { useCallback, useState } from 'preact/hooks';
import { useModal } from '../../../components/modal/hooks/useModal';
import { columns } from '../columns';

export const useGetUserInfoList = () => {
  const [data, setData] = useState([]);
  const formSearchObj = useForm({ defaultValues: searchDefaultValue });
  const { open: openEdit, onToggle: onToggleEdit } = useModal();
  const { open: openMessage, onToggle: onToggleMessage } = useModal();

  const {
    dataSource: dataUserInfo,
    onFetch,
    loading: isLoadingUserInfo,
  } = useGetUserInfo({
    handleSubmit: formSearchObj.handleSubmit,
  });

  const onEnterUserInfo = useCallback(
    async (values) => {
      await onFetch(values);
    },
    [onFetch],
  );

  const onModalMessage = (record) => {
    setData(record);
    onToggleMessage({ record });
  };

  const onModalEditUserInfo = (record) => {
    setData(record);
    onToggleEdit({ record });
  };

  const columnsData = columns({ onModalMessage, onModalEditUserInfo });
  const formSearch = FORM_SEARCH({ onEnter: onEnterUserInfo });

  return {
    formSearchObj,
    formSearch,
    columnsData,
    dataUserInfo,
    isLoadingUserInfo,
    onFetch,
    data,
    openMessage,
    onToggleMessage,
    onModalMessage,
    openEdit,
    onToggleEdit,
    onModalEditUserInfo,
  };
};
