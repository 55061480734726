export const handleSubmitForm = (values, mutateUpload) => {
  const uploadData = {
    productImageUrl: values.productImageUrl[0] || null,
    productImage: values.productImageUrl[0] || null,
    mapProductId: values.mapProductId,
    categoryId: values.categoryId,
    mobileBrandId: values.mobileBrandId,
    mobileModelId: values.mobileModelId,
    itemCode: values.itemCode,
    productGroupCode: values.productGroupCode,
    productTypeCode: values.productTypeCode,
    productName: values.productName,
    salebotDescription: values.salebotDescription,
    salebotDescriptionTh: values.salebotDescriptionTh,
    salebotColorTh: values.salebotColorTh,
    warrantyPeriod: parseInt(values.warrantyPeriod, 10) || 0,
    specialWarrantyStatus: values.specialWarrantyStatus,
    isSpecialWarrantyStatus: values.specialWarrantyStatus === 'ACTIVE' ? true : false,
    mapProductStatus: values.mapProductStatus,
    salebotColor: values.salebotColor,
    seqNo: parseInt(values.seqNo, 10) || 0,
  };
  mutateUpload(uploadData);
};
