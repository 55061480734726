import React from 'react';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../components';
import { ContainerButton } from '../../../styles/global-style';
import { useEffect } from 'preact/hooks';
import { FORM_EDIT_STORE } from '../form';
import { useForm } from 'react-hook-form';
import { storeTypeQuery } from '../../../services/react-query/optionMasterQuery';
import { allOptions } from '../../../functions/optionAll';
import { useUpdateStore } from '../hooks/useUpdateStore';

export const EditStoreModal = ({ open, onToggle, initialValues, onFetch }) => {
  const { control, setValue, getValues, handleSubmit } = useForm();
  const { mutate: updateStore } = useUpdateStore({ onSuccessCallBack: onFetch });
  const { data: storeTypeList } = storeTypeQuery();
  const { statusOptions } = allOptions();

  useEffect(() => {
    if (!initialValues) {
      return;
    }
    Object.keys(initialValues).forEach((key) => {
      setValue(key, initialValues[key]);
    });
  }, [initialValues, setValue]);

  const onSubmit = (value) => {
    updateStore(value);
    onToggle({ defaultValues: '' });
  };

  return (
    <ModalTheme title="แก้ไขร้านค้า" open={open} onToggle={onToggle} initialValues={initialValues}>
      <RenderForm
        control={control}
        forms={FORM_EDIT_STORE({ storeTypeList, statusOptions })}
        setValue={setValue}
        getValues={getValues}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
