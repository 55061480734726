import { useMutation } from '@tanstack/react-query';
import { POST, POST_SEND_MESSAGE_USER_INFO } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';
import { sendMessageLine } from '../../../resources/payload';

export const useSendMessageLine = () => {
  return useMutation({
    mutationFn: async (values) => {
      const payload = sendMessageLine(values);
      const response = await POST(POST_SEND_MESSAGE_USER_INFO(), payload);
      return response;
    },
    onSuccess: () => {
      openAlert({ model: 'message', type: 'success', message: 'ส่งข้อความสำเร็จ' });
    },
    onError: (err) => {
      openAlert({ model: 'message', type: 'error', message: err.message || 'ส่งข้อความไม่สำเร็จ' });
    },
  });
};
