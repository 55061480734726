import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ButtonTheme, ButtonToolTip } from '../../../../components';
import {
  createColumnButtonArr,
  createColumnNumber,
  createColumnTag,
  createColumnText,
  widthOptions,
} from '../../../../components/table/function';
import { ROUTES_PATH } from '../../../../resources/routes-name';
import { MAP_PRODUCT_STATUS } from '../../../../resources/status';

export const columns = ({ onOpenEditProduct }) => {
  const history = useHistory();
  return [
    createColumnTag(
      'สถานะ',
      { key: 'mapProductStatus', text: 'mapProductStatus' },
      MAP_PRODUCT_STATUS,
      {
        width: '150px',
      },
    ),
    createColumnNumber('ลำดับที่', 'seqNo', widthOptions(150)),
    createColumnText('Code G', 'itemCode', widthOptions(120)),
    createColumnText('สี QR', 'categoryName', widthOptions(120)),
    createColumnText('แบรนด์', 'mobileBrandNameEn', widthOptions(150)),
    createColumnText('รุ่นมือถือ', 'mobileModelNameEn', widthOptions(150)),
    createColumnText('ประเภทสินค้า (Major Category)', 'productTypeCode', widthOptions(200)),
    createColumnText('ชื่อสินค้า (Product Name)', 'productName', widthOptions(160)),
    createColumnText('Salebot Description', 'salebotDescriptionTh', widthOptions(160)),
    createColumnNumber('วันรับประกัน', 'warrantyPeriod', widthOptions(150)),

    createColumnButtonArr('จัดการ', 'rowNo', {
      others: {
        fixed: 'right',
        width: 180,
      },
      buttonArr: [
        {
          useFor: 'EDIT2',
          render: (_, record) => (
            <ButtonToolTip toolTip="แก้ไข" placement="topLeft">
              <ButtonTheme
                useFor="EDIT2"
                onClick={() => {
                  onOpenEditProduct(record);
                }}
              />
            </ButtonToolTip>
          ),
        },
      ],
    }),
  ];
};
