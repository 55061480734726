import { useMutation } from '@tanstack/react-query';
import { POST, POST_INSERT_QR_CODE } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';
import { InsertQr } from '../../../resources/payload';

export const useInsertQr = () => {
  return useMutation({
    mutationFn: async (values) => {
      const payload = InsertQr(values);
      const response = await POST(POST_INSERT_QR_CODE(), payload);

      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'บันทึกข้อมูล QR สำเร็จ',
      });
    },
    onError: (error) => {
      openAlert({
        model: 'message',
        type: 'error',
        message: error?.message || 'แก้ไขข้อมูลไม่สำเร็จ',
      });
    },
  });
};
