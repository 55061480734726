import { useMutation } from '@tanstack/react-query';
import { POST, POST_UPDATE_EXPIRED } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';
import { updateExpired } from '../../../../resources/payload';

export const useUpdateExpired = () =>
  useMutation({
    mutationFn: async ({ ...values }) => {
      const payload = updateExpired(values);
      const response = await POST(POST_UPDATE_EXPIRED(), payload);

      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'แก้ไขข้อมูลสำเร็จ',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    onError: (error) => {
      openAlert({ model: 'message', type: 'error', message: error?.message });
    },
  });
