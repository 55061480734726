import { useForm } from 'react-hook-form';
import { columns } from '../columns';
import { useGetWarrantyQr } from './useGetWarrantyQr';
import { useRef, useState } from 'preact/hooks';
import { openAlert } from '../../../components/alert/hooks';
import { usePostCutOfWarranty } from './usePostCutOfWarranty';
import { storeCutOffQuery } from '../../../services/react-query/optionMasterQuery';
import { useUpdateCutOfWarranty } from './useUpdateCutOfWarranty';
import { useSelector } from 'react-redux';

export const useGetCutOfWarranty = () => {
  const [qrCodeData, setQrCodeData] = useState({
    claim: null,
    qrCode: null,
  });
  const [tableData, setTableData] = useState([]);
  const { control, setValue, getValues, watch, reset, handleSubmit } = useForm();
  const showBranch = watch('cutOfWarranty') === 'false' ? true : false;
  const qrCodeInputRef = useRef(null);
  const warrantyCodeInputRef = useRef(null);
  const claimCodeInputRef = useRef(null);
  const { data: storeCutOffData, isPending: loadingStoreCutOff } = storeCutOffQuery();
  const userProfile = useSelector((state) => state.authen.users);
  const { privilegeFocusType } = userProfile;
  const { mutate: postCutOfWarranty, isPending: loadingPostCutOfWarranty } = usePostCutOfWarranty({
    reset,
    getValues,
  });

  const { mutate: getWarrantyQr, isPending: loadingWarrantyQr } = useGetWarrantyQr();
  const { mutate: updateCutOfWarranty, isPending: loadingUpdateCutOfWarranty } =
    useUpdateCutOfWarranty();

  const onEnter = (type, value) => {
    if (!value) return;
    switch (type) {
      case 'claimCodeOrWarrantyCode':
        if (value) {
          const isDuplicateClaimCode = tableData?.some((item) => item.claimCode === value);

          if (isDuplicateClaimCode) {
            openAlert({
              type: 'error',
              message: 'เลขที่เคลมซ้ำ ไม่สามารถเพิ่มข้อมูลได้',
            });
            reset({
              claimCodeOrWarrantyCode: '',
            });
            warrantyCodeInputRef.current?.focus();
            return;
          }

          const isDuplicateWarrantyCode = tableData?.some((item) => item.warrantyCode === value);

          if (isDuplicateWarrantyCode) {
            openAlert({
              type: 'error',
              message: 'เลขที่ลงทะเบียนซ้ำ ไม่สามารถเพิ่มข้อมูลได้',
            });
            reset({
              storeId: getValues('storeId'),
              claimCodeOrWarrantyCode: '',
            });
            warrantyCodeInputRef.current?.focus();
            return;
          }

          postCutOfWarranty(
            { claimCodeOrWarrantyCode: value },
            {
              onSuccess: (dataClaim) => {
                const newClaimData = dataClaim?.result?.item || {};
                setQrCodeData((prev) => ({
                  ...prev,
                  claim: newClaimData,
                }));

                const claimFields = [
                  'warrantyCode',
                  'claimCode',
                  'fullName',
                  'telephone',
                  'addressFullText',
                  'productName',
                  'mobileModelName',
                  'status',
                  'storeId',
                  'warrantyNumberCode',
                  'claimStatusName',
                ];

                const claimData = claimFields?.reduce((acc, field) => {
                  if (newClaimData[field] !== undefined) {
                    acc[field] = newClaimData[field];
                  }
                  return acc;
                }, {});

                const currentCutOfWarranty = getValues('cutOfWarranty');

                reset({
                  ...claimData,
                  claimCodeOrWarrantyCode: value,
                  warrantyNumberCode: '',
                  cutOfWarranty: currentCutOfWarranty,
                  storeId: getValues('storeId'),
                });
                setValue('storeId', dataClaim?.result?.item?.storeId || getValues('storeId'));
                qrCodeInputRef.current?.focus();
              },
              onError: () => {
                const currentCutOfWarranty = getValues('cutOfWarranty');
                reset({
                  claimCodeOrWarrantyCode: '',
                  cutOfWarranty: currentCutOfWarranty,
                });
              },
            },
          );
        }
        break;

      case 'warrantyNumberCode':
        let warrantyQrValue = value;

        const isDuplicate = tableData?.some((item) => item.warrantyQr === value);

        if (isDuplicate) {
          openAlert({
            type: 'error',
            message: 'รหัสรับประกันที่ยกเลิกซ้ำ ไม่สามารถเพิ่มข้อมูลได้',
          });
          setValue('warrantyNumberCode', '');
          qrCodeInputRef.current?.focus();
          return;
        }
        if (value.includes('?fw=')) {
          try {
            const url = new URL(value);
            warrantyQrValue = url.searchParams.get('fw');
          } catch (error) {
            openAlert({
              type: 'error',
              message: 'รูปแบบ URL ไม่ถูกต้อง',
            });
            setValue('warrantyNumberCode', '');
            qrCodeInputRef.current?.focus();
            return;
          }
        }

        const claimCodeOrWarrantyCode = getValues('claimCodeOrWarrantyCode');

        if (!claimCodeOrWarrantyCode) {
          openAlert({
            type: 'error',
            message: 'กรุณากรอกเลขที่ลงทะเบียน หรือ เลขที่เคลมก่อน',
          });
          setValue('warrantyNumberCode', '');
          claimCodeInputRef.current?.focus();
          return;
        }

        getWarrantyQr(warrantyQrValue, {
          onSuccess: (data) => {
            const newQrCodeData = {
              ...qrCodeData,
              qrCode: data?.warranty,
              claim: qrCodeData,
            };
            setQrCodeData(newQrCodeData);

            const warrantyData = qrCodeData?.warranty || {};
            const claimData = qrCodeData?.claim || {};
            const selectedStoreId = getValues('storeId');
            const storeInfo = storeCutOffData?.find((store) => store.value === selectedStoreId);

            const newTableRow = {
              key: tableData?.length + 1,
              warrantyCode: warrantyData?.warrantyCode || claimData?.warrantyCode,
              fullName: warrantyData?.fullName || claimData?.fullName,
              claimId: warrantyData?.claimId || claimData?.claimId,
              claimCode: warrantyData?.claimCode || claimData?.claimCode,
              telephone: warrantyData?.telephone || claimData?.telephone,
              productName: warrantyData?.productName || claimData?.productName,
              claimType: warrantyData?.claimType || claimData?.claimType,
              storeId: selectedStoreId,
              storeName: storeInfo?.label || '',
              warrantyNumberCode: warrantyData?.warrantyNumberCode || claimData?.warrantyNumberCode,
              warrantyQr: warrantyQrValue,
              claimStatusName: warrantyData?.claimStatusName || claimData?.claimStatusName,
            };

            setTableData([newTableRow, ...tableData]);

            const currentCutOfWarranty = getValues('cutOfWarranty');
            reset({
              claimCodeOrWarrantyCode: '',
              cutOfWarranty: currentCutOfWarranty,
            });

            claimCodeInputRef.current?.focus();
          },
          onError: () => {
            const currentCutOfWarranty = getValues('cutOfWarranty');
            reset({
              claimCodeOrWarrantyCode: getValues('claimCodeOrWarrantyCode'),
              warrantyNumberCode: '',
              cutOfWarranty: currentCutOfWarranty,
            });
          },
        });
        break;

      default:
        break;
    }
  };

  const onDeleteRecordScanDelivery = (record) => {
    const newTableData = tableData?.filter((item) => item.key !== record.key);

    setTableData(newTableData);

    claimCodeInputRef.current?.focus();
    reset({
      claimCodeOrWarrantyCode: '',
      warrantyNumberCode: '',
    });
  };

  const onSubmit = () => {
    if (tableData.length === 0) {
      openAlert({
        type: 'error',
        message: 'กรุณาเพิ่มข้อมูลอย่างน้อย 1 รายการ',
      });
      return;
    }

    const payload = tableData?.map((item) => ({
      claimId: item?.claimId,
      claimCode: item?.claimCode,
      warrantyNumberCode: item?.warrantyNumberCode,
      storeId: item?.storeId,
    }));

    updateCutOfWarranty(payload, {
      onSuccess: () => {
        setTableData([]);
        reset({
          claimCode: '',
          trackingNumber: '',
          warrantyNumberCode: '',
        });
      },
    });
  };

  const dataColumn = columns({ onDeleteRecordScanDelivery });

  return {
    control,
    setValue,
    getValues,
    dataColumn,
    showBranch,
    onEnter,
    qrCodeInputRef,
    loadingWarrantyQr,
    tableData,
    warrantyCodeInputRef,
    claimCodeInputRef,
    loadingPostCutOfWarranty,
    storeCutOffData,
    onSubmit,
    handleSubmit,
    loadingUpdateCutOfWarranty,
    loadingStoreCutOff,
    privilegeFocusType,
  };
};
