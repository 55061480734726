import { useMutation } from '@tanstack/react-query';
import { GET, GET_WARRANTY_REGISTER } from '../../../../services';
import { useState } from 'preact/hooks';
import { openAlert } from '../../../../components/alert/hooks';

export const useWarrantyQuery = ({ reset, getValues }) => {
  const [data, setData] = useState([]);
  const mutation = useMutation({
    mutationFn: async (value) => {
      const response = await GET(GET_WARRANTY_REGISTER(value));
      return response.result;
    },
    onSuccess: (result) => {
      if (result) {
        openAlert({
          type: 'success',
          message: 'ค้นหารหัสรับประกันสินค้าสำเร็จ',
        });
        reset({
          ...getValues(),
          ...result,
        });
        setData(result);
      }
    },
    onError: (error) => {
      openAlert({
        type: 'error',
        message: error?.message || 'เกิดข้อผิดพลาดในการค้นหารหัสรับประกัน',
      });
    },
  });

  return { mutate: mutation.mutate, dataSource: setData, data };
};
