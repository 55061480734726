import React from 'react';
import { useForm } from 'react-hook-form';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../components';
import { FORM_CREATE_STORE } from '../form';
import { ContainerButton } from '../../../styles/global-style';
import { storeTypeQuery } from '../../../services/react-query/optionMasterQuery';
import { useInsertStore } from '../hooks/useInsertStore';
import { Spin } from 'antd';

export const AddStoreModal = ({ open, onToggle, onFetch }) => {
  const { control, setValue, getValues, handleSubmit } = useForm();
  const { mutate: onSubmitInsertStore, isPending: loadingInsertStore } = useInsertStore({
    onSuccessCallBack: onFetch,
  });

  const { data: storeTypeList, isPending: loadingStoreType } = storeTypeQuery();

  const onSubmit = (value) => {
    onSubmitInsertStore(value);
    onToggle({ defaultValues: '' });
  };

  return (
    <Spin spinning={loadingInsertStore || loadingStoreType}>
      <ModalTheme title="เพิ่มร้านค้า" open={open} onToggle={onToggle}>
        <RenderForm
          control={control}
          forms={FORM_CREATE_STORE({ storeTypeList })}
          setValue={setValue}
          getValues={getValues}
        />
        <ContainerButton right>
          <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
          <ButtonTheme useFor="CANCEL" onClick={onToggle} />
        </ContainerButton>
      </ModalTheme>
    </Spin>
  );
};
