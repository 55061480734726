import { useMutation } from '@tanstack/react-query';
import { POST, POST_APPROVE_CLAIM_AUTO } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';

export const useAutoTracking = ({ selectedRowKeys }) =>
  useMutation({
    mutationFn: async () => {
      const payload = {
        claimIdList: selectedRowKeys,
      };
      const response = await POST(POST_APPROVE_CLAIM_AUTO(), payload);
      return response;
    },

    onSuccess: () => {
      openAlert({ model: 'message', type: 'success', message: 'เลือกขนส่งสำเร็จ' });
    },
    onError: (response) => {
      openAlert({
        model: 'message',
        type: 'error',
        message: response?.message || 'เกิดข้อผิดพลาด',
      });
    },
  });
