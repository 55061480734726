import React from 'react';
import { StyledContainer } from '../../../components/container/styles';
import { TextLarge } from '../../../components/text';
import { Col, Row, Spin } from 'antd';
import { CardTheme } from '../../../components/card';
import { ButtonTheme, RenderForm } from '../../../components';
import { FORM_PRODUCT_REGISTRATION_WARRANTY, FORM_USER_REGISTRATION_WARRANTY } from './form';
import { ContainerButton } from '../../../styles/global-style';
import { EditExpiredModal } from './modal/EditExpiredModal';
import { useGetWarrantyEditList } from './hooks/useGetWarrantyEditList';

export const WarrantyEdit = () => {
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    isLoadingWarrantyEdit,
    mobileBrandOptions,
    mobileModelOptions,
    productOptions,
    channelBuyOptions,
    storeTypeChannelOptions,
    onSubmit,
    goBack,
    dataEdit,
    openEditExpired,
    onToggleEditExpired,
    showStoreField,
    onChange,
    isPendingEditWarranty,
  } = useGetWarrantyEditList();

  return (
    <Spin spinning={isLoadingWarrantyEdit || isPendingEditWarranty}>
      <StyledContainer>
        <TextLarge text="แก้ไขข้อมูลรับประกัน" />
        <Row gutter={16}>
          <Col span={14}>
            <CardTheme>
              <TextLarge text="ลูกค้าลงทะเบียนรับประกัน" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_USER_REGISTRATION_WARRANTY()}
              />
              <ContainerButton right>
                <ButtonTheme useFor="EDIT2" title="แก้ไขวันหมดอายุ" onClick={onToggleEditExpired} />
                {openEditExpired && (
                  <EditExpiredModal
                    open={openEditExpired}
                    onToggle={onToggleEditExpired}
                    dataEdit={dataEdit}
                  />
                )}
              </ContainerButton>
            </CardTheme>
          </Col>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="สินค้าลงทะเบียนรับประกัน" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                onChange={onChange}
                forms={FORM_PRODUCT_REGISTRATION_WARRANTY({
                  mobileBrandOptions,
                  mobileModelOptions,
                  productOptions,
                  channelBuyOptions,
                  storeTypeChannelOptions,
                  showStoreField,
                })}
              />
            </CardTheme>
          </Col>
        </Row>
        <Row gutter={16} justify="space-between" align="middle" style={{ marginTop: '2%' }}>
          <Col>
            <ButtonTheme useFor="BACK" title="ย้อนกลับ" onClick={goBack} />
          </Col>
          <Col>
            <ButtonTheme useFor="SUBMIT" title="ลงทะเบียน" onClick={handleSubmit(onSubmit)} />
          </Col>
        </Row>
      </StyledContainer>
    </Spin>
  );
};
