import { useMutation } from '@tanstack/react-query';
import { updateTel } from '../../../resources/payload';
import { openAlert } from '../../../components/alert/hooks';
import { POST, POST_USER_TEL } from '../../../services';

export const useUpdateTel = ({ onSuccessCallBack }) => {
  return useMutation({
    mutationFn: async (value) => {
      const payload = updateTel(value);
      const response = await POST(POST_USER_TEL(), payload);
      if (onSuccessCallBack) onSuccessCallBack(response);

      return response.result.item;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'แก้ไขข้อมูลสำเร็จ',
      });
    },
    onError: (error) => {
      openAlert({
        model: 'message',
        type: 'error',
        message: error?.message || 'แก้ไขข้อมูลไม่สำเร็จ',
      });
    },
  });
};
