import { useMutation } from '@tanstack/react-query';
import { POST, POST_UPDATE_STATUS_BRAND } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';

export const useUpdateStatusBrand = ({ onSuccessCallBack }) => {
  return useMutation({
    mutationFn: async (payload) => {
      const response = await POST(POST_UPDATE_STATUS_BRAND(), payload);
      if (onSuccessCallBack) onSuccessCallBack(response);
      return response;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'แก้ไขสถานะ Brand สำเร็จ',
      });
    },
    onError: (error) => {
      openAlert({
        model: 'message',
        type: 'error',
        message: error?.message,
      });
    },
  });
};
