import { useCallback, useEffect, useState } from 'preact/hooks';
import { useForm } from 'react-hook-form';
import { useTeleQuery } from './useTeleQuery';
import { useOptions } from './useOptions';
import { useZipcode } from './useZipcodeQuery';
import { onUploadFile } from './onUploadFile';
import { handleSubmitForm } from '../func/submitHandler';
import {
  channelClaimQuery,
  storeTypeClaimsQuery,
} from '../../../../services/react-query/optionMasterQuery';

export const useGetClaimAddList = () => {
  const [showStoreField, setShowStoreField] = useState('');
  const { control, setValue, getValues, handleSubmit, watch, reset } = useForm();
  const fullName = watch('fullName');
  const isCreateNewAddress = watch('isCreateNewAddress');
  const { mutate: onCallTele, isPending: isPendingTele } = useTeleQuery({ reset, getValues });
  const { mutate: onCallZipCode, isPending: isPendingZipCode } = useZipcode({ reset, getValues });
  const { mutate: onAddCLaim, isPending: isPendingAddClaim } = onUploadFile();
  const { data: channelClaim, isPending: isPendingChannelClaim } = channelClaimQuery();
  const { data: storeTypeChannelOptions, isPending: isPendingStoreTypeChannel } =
    storeTypeClaimsQuery(showStoreField);
  const watchChannelClaim = watch('channelClaimId');

  useEffect(() => {
    if (watchChannelClaim && channelClaim) {
      const selectedChannel = channelClaim.find((option) => option.value === watchChannelClaim);
      const channelClaimType = selectedChannel?.channelClaimType;
      if (['FOCUS', 'STORE'].includes(channelClaimType)) {
        setShowStoreField(channelClaimType);
        setValue('isCreateNewAddress', 'false');
        setValue('customerAddressId', '');
        setValue('zipCode', '');
        setValue('provinceId', '');
        setValue('districtId', '');
        setValue('subDistrictId', '');
        setValue('other', '');
      } else {
        setShowStoreField('');
      }
    }
  }, [watchChannelClaim, channelClaim, setValue]);

  const {
    addressFullOptions,
    provinceOptions,
    districtOptions,
    subDistrictOptions,
    addressData,
    warrantyOptions,
    breakdownOptions,
  } = useOptions({
    watch,
  });

  const onEnterHandler = useCallback(
    async (value, item) => {
      switch (item?.name) {
        case 'telephone':
          reset({ telephone: getValues('telephone') });
          onCallTele(value);
          break;
        case 'zipCode':
          onCallZipCode(value);
          break;
        default:
          break;
      }
    },
    [getValues],
  );

  const onChangeAddress = (value, item) => {
    const addressSetNew = addressData?.find((data) => data?.customerAddressId === value);
    if (addressSetNew) {
      setValue('zipCode', addressSetNew?.zipcode);
      setValue('provinceId', {
        value: addressSetNew?.provinceId,
        label: addressSetNew?.provinceName,
      });
      setValue('districtId', {
        value: addressSetNew?.districtId,
        label: addressSetNew?.districtName,
      });
      setValue('subDistrictId', {
        value: addressSetNew?.subDistrictId,
        label: addressSetNew?.subDistrictName,
      });
      setValue('other', addressSetNew?.other);
    }

    switch (item?.name) {
      case 'zipCode':
        setValue('provinceId', '');
        setValue('districtId', '');
        setValue('subDistrictId', '');
        break;
      case 'provinceId':
        setValue('districtId', '');
        setValue('subDistrictId', '');
      case 'districtId':
        setValue('subDistrictId', '');
      default:
        break;
    }
  };

  const onChangeInfoClaim = (_, item) => {
    switch (item?.name) {
      case 'channelClaimId':
        setValue('storeId', '');
        break;
      case 'warrantyId':
        setValue('breakdownId', '');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isCreateNewAddress === 'true') {
      setValue('zipCode', '');
      setValue('provinceId', '');
      setValue('districtId', '');
      setValue('subDistrictId', '');
    }
  }, [isCreateNewAddress]);

  const onSubmit = (values) => {
    const valuesWithCustomerCode = {
      ...values,
      provinceId:
        typeof values.provinceId === 'object' ? values.provinceId.value : values.provinceId,
      districtId:
        typeof values.districtId === 'object' ? values.districtId.value : values.districtId,
      subDistrictId:
        typeof values.subDistrictId === 'object'
          ? values.subDistrictId.value
          : values.subDistrictId,
    };
    handleSubmitForm(valuesWithCustomerCode, onAddCLaim);
  };

  return {
    control,
    setValue,
    getValues,
    handleSubmit,
    onSubmit,
    watch,
    fullName,
    addressFullOptions,
    provinceOptions,
    onEnterHandler,
    districtOptions,
    subDistrictOptions,
    onChangeAddress,
    warrantyOptions,
    breakdownOptions,
    handleSubmit,
    channelClaim,
    storeTypeChannelOptions,
    showStoreField,
    onChangeInfoClaim,
    isPendingTele,
    isPendingZipCode,
    isPendingAddClaim,
    isPendingChannelClaim,
    isPendingStoreTypeChannel,
  };
};
