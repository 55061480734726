export const FORM_SEARCH = ({ onEnter }) => {
  return [
    {
      name: 'search',
      label: 'ค้นหารายการ (กรอกข้อมูลแล้วกด Enter เพื่อค้นหา)',
      type: 'INPUT',
      placeholder: 'ค้นหาข้อมูลลูกค้า',
      span: 15,
      onEnter,
      helper: 'Tips : สามารถค้นหาด้วย เบอร์, lineUid, รหัสลูกค้า และชื่อลูกค้า',
    },
  ];
};

export const FORM_MESSAGE = () => {
  return [
    {
      name: 'message',
      label: 'ข้อความ (ระบุได้ไม่เกิน 250 ตัวอักษร)',
      type: 'INPUT_AREA',
      placeholder: '',
      span: 24,
      rules: {
        required: true,
        minLength: 1,
        maxLength: 250,
      },
    },
  ];
};

export const searchDefaultValue = {
  search: '',
};

export const FORM_EDIT_USER = () => {
  const span = 12;
  return [
    {
      name: 'customerId',
      label: 'รหัสลูกค้า',
      type: 'HIDDEN',
      disabled: true,
    },
    {
      name: 'customerCode',
      label: 'รหัสลูกค้า',
      type: 'INPUT',
      span,
      disabled: true,
    },
    {
      name: 'fullName',
      label: 'ชื่อ',
      type: 'INPUT',
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'telephone',
      label: 'เบอร์ติดต่อ',
      type: 'INPUT_PHONE',
      placeholder: 'กรุณากรอกเบอร์โทรศัพท์',
      maxLength: 10,
      span,
      rules: {
        required: true,
        pattern: /^[0-9]*$/,
      },
      disabled: true,
    },
    {
      name: 'birthday',
      label: 'วันเกิด',
      type: 'DATE_PICKER',
      span: 12,
      format: 'YYYY-MM-DD',
    },
    {
      name: 'lineName',
      label: 'ไลน์',
      type: 'INPUT',
      disabled: true,
      span,
    },
  ];
};

export const FORM_SYNC_OLD_TEL = () => {
  return [
    {
      name: 'customerId',
      type: 'HIDDEN',
    },
    {
      name: 'telephone',
      label: 'เบอร์ติดต่อใหม่',
      type: 'INPUT_PHONE',
      placeholder: 'กรุณากรอกเบอร์โทรศัพท์',
      maxLength: 10,
      span: 24,
      rules: {
        required: true,
        pattern: /^[0-9]*$/,
      },
    },
  ];
};
