import dayjs from 'dayjs';

export const FORM_CLAIM_LIST = ({ onEnter }) => {
  return [
    {
      name: 'search',
      label: 'ค้นหารายการ (กรอกข้อมูลแล้วกด Enter เพื่อค้นหา)',
      type: 'INPUT',
      placeholder: '',
      span: 14,
      onEnter,
      helper: 'Tips : สามารถค้นหาด้วย รหัสเคลม , รหัสรับประกัน, เบอร์ , รหัสลูกค้า , ชื่อลูกค้า',
    },
    {
      name: 'startDate',
      label: 'ค้นหาข้อมูลรายการเคลมสินค้าโดยอิงวันที่',
      type: 'DATE_RANGE',
      placeholder: '',
      defaultValue: [dayjs().startOf('month'), dayjs()],
      span: 10,
      rules: {
        required: true,
      },
    },
  ];
};

export const searchDefaultValues = {
  search: '',
  startDate: [dayjs().subtract(7, 'day'), dayjs()],
};

export const FORM_TRACKING_NUMBER = ({ shippingOptions }) => {
  const span = 24;
  return [
    {
      name: 'shippingId',
      label: 'ขนส่ง',
      type: 'DROPDOWN',
      placeholder: '',
      properties: {
        options: shippingOptions || [],
      },
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'trackingNumber',
      label: 'เลขพัสดุ',
      type: 'INPUT',
      placeholder: '',
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'shippingDate',
      label: 'วันที่จัดส่ง',
      type: 'DATE_PICKER',
      span,
      defaultValue: dayjs(),
      disabled: true,
    },
    {
      name: 'claimId',
      type: 'HIDDEN',
    },
    {
      name: 'claimCode',
      type: 'HIDDEN',
    },
  ];
};

export const FORM_MESSAGE = () => {
  return [
    {
      name: 'message',
      label: 'ข้อความ (ระบุได้ไม่เกิน 500 ตัวอักษร)',
      type: 'INPUT_AREA',
      placeholder: '',
      span: 24,
      rules: {
        required: true,
        maxLength: 500,
      },
    },
  ];
};

export const FORM_REMARK = () => {
  return [
    {
      name: 'remark',
      label: 'ข้อความ (ระบุได้ไม่เกิน 250 ตัวอักษร)',
      type: 'INPUT_AREA',
      placeholder: '',
      span: 24,
      rules: {
        required: true,
        maxLength: 250,
      },
    },
  ];
};

export const FORM_AUTO_TRACKING = () => {
  const span = 24;
  return [
    {
      name: 'shippingId',
      label: 'ขนส่ง',
      type: 'DROPDOWN',
      properties: {
        options: [{ label: 'KERRY', value: 'KERRY' }],
      },
      defaultValue: 'KERRY',
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'shippingDate',
      label: 'วันที่จัดส่ง',
      type: 'DATE_PICKER',
      span,
      defaultValue: dayjs(),
      disabled: true,
    },
  ];
};

export const FORM_PROCESS_STATUS = ({ processStatusList = [] }) => {
  const span = 24;
  return [
    {
      name: 'processStatus',
      label: 'สถานะภายใน',
      type: 'DROPDOWN',
      span,
      properties: {
        options: processStatusList || [],
      },
    },
  ];
};
