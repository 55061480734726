import React from 'react';
import { StyledContainer } from '../../../components/container/styles';
import { TextLarge } from '../../../components/text';
import { Col, Row, Spin } from 'antd';
import { CardTheme } from '../../../components/card';
import { ButtonTheme, RenderForm } from '../../../components';
import { ContainerButton } from '../../../styles/global-style';
import { FORM_CLAIM_CREATE, FORM_CLAIM_DATA } from './form';
import { useGetClaimAddList } from './hooks/useGetClaimAddList';

export const ClaimAdd = () => {
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    watch,
    onSubmit,
    fullName,
    addressFullOptions,
    onEnterHandler,
    provinceOptions,
    districtOptions,
    subDistrictOptions,
    onChangeAddress,
    warrantyOptions,
    breakdownOptions,
    channelClaim,
    storeTypeChannelOptions,
    showStoreField,
    onChangeInfoClaim,
    isPendingTele,
    isPendingZipCode,
    isPendingAddClaim,
    isPendingChannelClaim,
    isPendingStoreTypeChannel,
  } = useGetClaimAddList();

  return (
    <Spin
      spinning={
        isPendingTele ||
        isPendingZipCode ||
        isPendingAddClaim ||
        isPendingChannelClaim ||
        isPendingStoreTypeChannel
      }
    >
      <StyledContainer>
        <TextLarge text="ยื่นเรื่องเคลม" />
        <Row gutter={[24, 24]}>
          <Col span={14}>
            <CardTheme>
              <TextLarge text="ข้อมูลส่วนตัว" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                onChange={onChangeAddress}
                forms={FORM_CLAIM_CREATE({
                  watch,
                  onEnter: onEnterHandler,
                  showOldAddress: !!fullName,
                  addressFullOptions,
                  provinceOptions,
                  districtOptions,
                  subDistrictOptions,
                  showStoreField,
                })}
              />
              <ContainerButton center>
                <ButtonTheme useFor="SUBMIT" title="บันทึก" onClick={handleSubmit(onSubmit)} />
              </ContainerButton>
            </CardTheme>
          </Col>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="ข้อมูลการแจ้งเคลม" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                onChange={onChangeInfoClaim}
                forms={FORM_CLAIM_DATA({
                  warrantyOptions,
                  breakdownOptions,
                  channelClaim,
                  storeTypeChannelOptions,
                  showStoreField,
                })}
              />
            </CardTheme>
          </Col>
        </Row>
      </StyledContainer>
    </Spin>
  );
};
