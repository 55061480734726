import { useMemo } from 'preact/hooks';
import { serviceOptions } from '../../../../functions/serviceOption';

export const useOptions = ({ data, watch }) => {
  const {
    categoryId,
    mobilebrands,
    mobileBrandId,
    mobileModelId,
    subDistrict,
    provinceId,
    district,
    province,
  } = watch();

  const categoryOptions = useMemo(() => serviceOptions('CATEGORY_OPTIONS', data), [data]);
  const mobileBrandOptions = useMemo(
    () =>
      serviceOptions(
        'MOBILE_BRAND',
        categoryOptions?.find((el) => el?.value === categoryId)?.mobilebrands,
      ),
    [mobilebrands, categoryId],
  );

  const mobileModelOptions = useMemo(
    () =>
      serviceOptions(
        'MOBILE_MODEL',
        mobileBrandOptions?.find((el) => el?.value === mobileBrandId)?.mobileModels,
      ),
    [mobileBrandId, mobileBrandOptions],
  );

  const productOptions = useMemo(
    () =>
      serviceOptions(
        'PRODUCT',
        mobileModelOptions?.find((el) => el?.value === mobileModelId)?.products,
      ),
    [mobileModelId, mobileModelOptions],
  );

  const provinceOptions = useMemo(() => serviceOptions('PROVINCE', province), [province]);
  const districtOptions = useMemo(
    () =>
      provinceId
        ? serviceOptions(
            'DISTRICT',
            district?.filter((el) => el?.provinceId === provinceId),
          )
        : [],
    [provinceId],
  );
  const subDistrictOptions = useMemo(
    () =>
      provinceId
        ? serviceOptions(
            'SUBDISTRICT',
            subDistrict?.filter((el) => el?.provinceId === provinceId),
          )
        : [],
    [provinceId, subDistrict],
  );

  return {
    categoryOptions,
    mobileBrandOptions,
    mobileModelOptions,
    productOptions,
    provinceOptions,
    districtOptions,
    subDistrictOptions,
  };
};
