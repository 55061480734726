import { useForm } from 'react-hook-form';
import { columns } from '../columns';
import { FORM_SEARCH, searchDefaultValues } from '../form';
import { useGetBrand } from './useGetMobile';
import { useUpdateStatusBrand } from './useUpdateStatusBrand';
import { UpdateStatusBrand } from '../../../../resources/payload';

export const useGetBrandList = () => {
  const formSearchObj = useForm({ defaultValues: searchDefaultValues });

  const {
    dataSource: dataBrand,
    onFetch,
    loading: isLoadingBrandList,
  } = useGetBrand({
    handleSubmit: formSearchObj.handleSubmit,
  });

  const { mutate: updateStatusBrand } = useUpdateStatusBrand({
    onSuccessCallBack: () => {
      onFetch();
    },
  });

  const onToggleStatus = (checked, record) => {
    const payload = UpdateStatusBrand(record, checked);
    updateStatusBrand(payload);
  };

  const handleOnEnter = (value) => {
    onFetch(value);
  };

  const columnData = columns({ onToggleStatus });
  const formSearch = FORM_SEARCH({ onEnter: handleOnEnter });

  return {
    columnData,
    formSearch,
    formSearchObj,
    dataBrand,
    isLoadingBrandList,
    onFetch,
  };
};
