import React from 'react';
import { Col, Row } from 'antd';
import { IconContainerDiv, StatusColumnContainer } from '../../../../styles/global-style';
import { PauseOutlined } from '@ant-design/icons';
import { requestStatusObg } from '../resource/requestStatusObg';
import { TextSmall } from '../../../../components/text';
import { StepIcon } from '../../../../resources/icon';

export const InfoRegistration = () => {
  const statusKeys = Object.keys(requestStatusObg);

  const renderStepIcon = (index) => {
    if (index === 2 || index === 3) {
      return (
        <IconContainerDiv>
          <PauseOutlined style={{ fontSize: '20px', color: '#9e9e9e' }} />
        </IconContainerDiv>
      );
    }
    if (index < statusKeys.length - 1) {
      return (
        <IconContainerDiv>
          <StepIcon />
        </IconContainerDiv>
      );
    }
    return null;
  };

  return (
    <>
      <Col span={24}>
        <TextSmall bold style={{ textDecoration: 'underline' }}>
          สถานะการลงทะเบียน
        </TextSmall>
      </Col>
      <Col span={24}>
        <Row align={'middle'} style={{ marginTop: '3px', marginBottom: '3px' }}>
          {statusKeys.map((key, index) => (
            <React.Fragment key={key}>
              <Col>
                <StatusColumnContainer color={requestStatusObg[key].color}>
                  <IconContainerDiv style={{ color: requestStatusObg[key].color }}>
                    {requestStatusObg[key].icon}
                  </IconContainerDiv>
                  <TextSmall>{requestStatusObg[key].label}</TextSmall>
                </StatusColumnContainer>
              </Col>
              {renderStepIcon(index) && <Col>{renderStepIcon(index)}</Col>}
            </React.Fragment>
          ))}
        </Row>
      </Col>
    </>
  );
};
