import React from 'react';
import { SpaceBetween, StyledContainer } from '../../components/container/styles';
import { TextLarge } from '../../components/text';
import { CardTheme } from '../../components/card';
import { ResponsivePageLayout } from '../../components/page/responsive-layout/ResponsivePageLayout';
import { Icon } from '../../resources';
import { useGetReportClaim } from './hooks/useGetReportClaim';
import { ResponsiveTableLayout } from '../../components/page/responsive-layout/views/ResponsiveTableLayout';
import { Col, Row } from 'antd';
import { ContainerButton } from '../../styles/global-style';
import ButtonExcelEmbedded from '../../components/report-excel/ButtonExcelEmbedded';

export const ReportClaim = () => {
  const {
    formSearchObj,
    formSearch,
    columnData,
    columnBeforeData,
    columnAfterData,
    changeProduct,
    loadingFull,
    loading,
    onSearchByField,
    changeProductFull,
    searchType,
    formatDataForExcel,
  } = useGetReportClaim();

  return (
    <StyledContainer>
      <TextLarge text="รายงานเปลี่ยนสินค้าข้ามรุ่น" />
      <CardTheme>
        <ResponsivePageLayout
          searchLayout={{
            title: 'ค้นหารายงานประวัติการเคลม',
            icon: <Icon.todo />,
            formSearch,
            useFormParam: formSearchObj,
            onSearch: formSearchObj.handleSubmit(onSearchByField),
            onClear: formSearchObj.reset,
          }}
          tableLayout={{
            columns: columnData || [],
            dataSource: searchType === 'date' ? changeProduct : changeProductFull || [],
            loading: searchType === 'date' ? loading : loadingFull,
            rowKey: 'rowNumber',
            hasPagination: true,
            expandable: {
              expandedRowRender: (record) => {
                const logDataBefore =
                  record?.claimChangeProductDetails?.map((item) => item?.logDataBefore) || [];
                const logDataAfter =
                  record?.claimChangeProductDetails?.map((item) => item?.logDataAfter) || [];

                return (
                  <Row gutter={[24, 24]}>
                    <Col span={12}>
                      <ResponsiveTableLayout
                        columns={columnBeforeData}
                        dataSource={logDataBefore || []}
                      />
                    </Col>
                    <Col span={12}>
                      <ResponsiveTableLayout
                        columns={columnAfterData}
                        dataSource={logDataAfter || []}
                      />
                    </Col>
                  </Row>
                );
              },
            },
            extraContent: (
              <SpaceBetween>
                <ContainerButton right>
                  <ButtonExcelEmbedded
                    title="Export"
                    filename="รายงานเปลี่ยนสินค้าข้ามรุ่น"
                    header={[
                      ...columnData.map((col) => ({ ...col })),
                      ...columnBeforeData.map((col) => ({
                        ...col,
                        dataIndex: `before_${col.dataIndex}`,
                        title: `ก่อน: ${col.title}`,
                      })),
                      ...columnAfterData.map((col) => ({
                        ...col,
                        dataIndex: `after_${col.dataIndex}`,
                        title: `หลัง: ${col.title}`,
                      })),
                    ]}
                    data={formatDataForExcel(
                      searchType === 'date' ? changeProduct : changeProductFull || [],
                    )}
                  />
                </ContainerButton>
              </SpaceBetween>
            ),
          }}
        />
      </CardTheme>
    </StyledContainer>
  );
};
