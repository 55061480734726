import { useMutation } from '@tanstack/react-query';
import { POST, POST_DESTROY_MANAGEMENT_DATA } from '../../../../services';
import { DeleteManagementData } from '../../../../resources/payload';
import { openAlert } from '../../../../components/alert/hooks';

export const useDeleteManageData = () =>
  useMutation({
    mutationFn: async (values) => {
      const payload = DeleteManagementData(values);
      const response = await POST(POST_DESTROY_MANAGEMENT_DATA(), payload);

      return response;
    },
    onSuccess: () => {
      openAlert({ model: 'message', type: 'success', message: 'ลบข้อมูล Management data สำเร็จ' });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
  });
