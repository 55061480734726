import { useMutation } from '@tanstack/react-query';
import { POST, POST_SEND_REMARK } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';
import { sendRemark } from '../../../../resources/payload';

export const useSendRemark = () => {
  return useMutation({
    mutationFn: async (values) => {
      const payload = sendRemark(values);
      const response = await POST(POST_SEND_REMARK(), payload);
      return response;
    },
    onSuccess: () => {
      openAlert({ model: 'message', type: 'success', message: 'ส่งข้อความหมายเหตุสำเร็จ' });
    },
  });
};
