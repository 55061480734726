import React from 'react';
import { ImCheckmark } from 'react-icons/im';
import { BiEdit } from 'react-icons/bi';
import { RiDeleteBin6Line, RiTimeLine } from 'react-icons/ri';
import { color } from '../../../../resources';
import { Icon } from '../../../../resources/icon';

export const requestStatusObg = {
  [10]: {
    icon: <BiEdit />,
    color: color.blueFocus,
    label: 'ACTIVE (ลงทะเบียน)',
  },
  [20]: {
    icon: <ImCheckmark />,
    color: color.yellow,
    label: 'CLAIMING (กำลังเคลม)',
  },
  [30]: {
    icon: <Icon.box />,
    color: color.submit,
    label: 'COMPLETED (สำเร็จ)',
  },
  [40]: {
    icon: <RiTimeLine />,
    color: '#9e9e9e',
    label: 'EXPIRED (หมดอายุ)',
  },
  [50]: {
    icon: <RiDeleteBin6Line />,
    color: color.edit,
    label: 'CANCEL (ยกเลิก)',
  },
};
