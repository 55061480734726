export const FORM_INFO_SCAN_DELIVER = ({
  onEnter,
  trackingInputRef,
  warrantyNumberCodeRef,
  claimCodeInputRef,
  shippingSelfOptions,
  itemCodeInputRef,
}) => {
  const span = 24;
  return [
    {
      name: 'shippingId',
      label: 'ขนส่ง',
      type: 'DROPDOWN',
      span,
      properties: {
        options: shippingSelfOptions || [],
      },
    },
    {
      name: 'claimCode',
      label: 'เลขที่เคลม (กรอกเลขที่เคลมแล้วกด Enter)',
      type: 'INPUT',
      onKeyDown: (e) => onEnter(e, 'claimCode'),
      ref: claimCodeInputRef,
      style: { fontSize: '11px', height: '50%', marginTop: '-2px' },
      span,
    },
    {
      name: 'trackingNumber',
      label: 'เลขที่พัสดุ',
      type: 'INPUT',
      ref: trackingInputRef,
      onKeyDown: (e) => onEnter(e, 'trackingNumber'),
      style: { fontSize: '11px', height: '50%', marginTop: '-2px' },
      span,
    },
    {
      name: 'itemCode',
      label: 'Item Code',
      type: 'INPUT',
      style: { fontSize: '11px', height: '50%', marginTop: '-2px' },
      ref: itemCodeInputRef,
      onKeyDown: (e) => onEnter(e, 'itemCode'),
      span,
    },
    {
      name: 'warrantyNumberCode',
      label: 'QR Code ที่ตัดการรับประกัน',
      type: 'INPUT',
      style: { fontSize: '11px', height: '50%', marginTop: '-2px' },
      onKeyDown: (e) => onEnter(e, 'warrantyNumberCode'),
      ref: warrantyNumberCodeRef,
      span,
    },
  ];
};

export const FORM_INFO_DOC = () => {
  const span = 12;
  return [
    {
      name: 'claimCode',
      label: 'เลขที่เคลม',
      type: 'TEXT',
      style: { fontSize: '11px' },
      span,
    },
    {
      name: 'fullName',
      label: 'ชื่อ-นามสกุล',
      type: 'TEXT',
      style: { fontSize: '11px' },
      span,
    },
    {
      name: 'telephone',
      label: 'หมายเลขโทรศัพท์',
      type: 'TEXT',
      style: { fontSize: '11px' },
      span,
    },
    {
      name: 'addressFullText',
      label: 'ที่อยู่',
      type: 'TEXT',
      style: { fontSize: '11px' },
      span,
    },
    {
      name: 'mobileBrandName',
      label: 'รุ่นสินค้า',
      type: 'TEXT',
      style: { fontSize: '11px' },
      span,
    },
    {
      name: 'mobileModelName',
      label: 'รุ่นมือถือ',
      type: 'TEXT',
      style: { fontSize: '11px' },
      span,
    },
    {
      name: 'claimStatusName',
      label: 'สถานะ',
      type: 'TEXT',
      style: { fontSize: '11px' },
      span,
    },
    {
      name: 'categoryCode',
      label: 'Item Code G',
      type: 'TEXT',
      style: { fontSize: '11px' },
      span,
    },
  ];
};

export const FORM_DELIVERY = ({ shippingSelfOptions }) => {
  return [
    {
      name: 'shippingId',
      label: 'ขนส่ง',
      type: 'DROPDOWN',
      span: 8,
      properties: {
        options: shippingSelfOptions || [],
      },
    },
  ];
};
