import { useForm } from 'react-hook-form';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../components';
import { FORM_ADD_PERMISSION } from '../form';
import {
  userPermissionQuery,
  userTypeQuery,
} from '../../../services/react-query/optionMasterQuery';
import { ContainerButton } from '../../../styles/global-style';
import { useInsertTypes } from '../hooks/useInsertTypes';

export const SetUpPermission = ({ open, onToggle, onFetch }) => {
  const { control, setValue, getValues, handleSubmit } = useForm();
  const { data: userList } = userPermissionQuery();
  const { data: userTypeList } = userTypeQuery();
  const { mutate: insertTypes } = useInsertTypes({ onSuccessCallback: onFetch });

  const onSubmit = (values) => {
    const selectedUserType = userTypeList?.find((type) => type.value === values.userTypeId);

    const payload = {
      userId: values.userId,
      userTypeFocusId: values.userTypeId,
      privilegeFocusData:
        selectedUserType?.privilegeData?.map((privilege) => ({
          code: privilege.code,
          status: privilege.status,
        })) || [],
    };

    insertTypes({ payload });
    onToggle({ defaultValues: '' });
  };

  return (
    <ModalTheme title="เพิ่มสิทธิ์" open={open} onToggle={onToggle}>
      <RenderForm
        control={control}
        setValue={setValue}
        getValues={getValues}
        forms={FORM_ADD_PERMISSION({ userList, userTypeList })}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
