import { useMutation } from '@tanstack/react-query';
import { POST, POST_APPROVE_CLAIM } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';

export const useApproveClaim = () =>
  useMutation({
    mutationFn: async ({ claimIdList }) => {
      const payload = {
        claimIdList,
      };
      const response = await POST(POST_APPROVE_CLAIM(), payload);
      return response;
    },

    onSuccess: (response) => {
      openAlert({ model: 'message', type: 'success', message: response?.message });
    },
    onError: (error) => {
      openAlert({ model: 'message', type: 'error', message: error?.message });
    },
  });
