import React from 'react';
import { CardStyle } from './styles';

export const CardTheme = ({ title, content, style, ...props }) => {
  return (
    <CardStyle title={title || ''} style={style} {...props}>
      {content || props.children}
    </CardStyle>
  );
};
