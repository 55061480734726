import React from 'react';
import { ModalTheme } from '../../../../components/modal/index.jsx';
import { useForm } from 'react-hook-form';
import { ContainerButton } from '../../../../styles/global-style.js';
import { FORM_REMARK } from '../form/index.js';
import { TextLarge, TextSmall } from '../../../../components/text/index.jsx';
import { ButtonTheme, RenderForm } from '../../../../components/index.js';
import { useSendRemark } from '../hooks/useSendRemark.js';
import { useEffect } from 'preact/hooks';

export const RemarkMessage = ({
  open,
  onToggle,
  claimData,
  onFetch,
  onFetchClaimFull,
  searchType,
}) => {
  const { control, setValue, getValues, handleSubmit } = useForm({});

  const { mutate: updateRemark } = useSendRemark({
    onSuccessCallBack: onFetch,
  });

  useEffect(() => {
    setValue('claimId', claimData?.claimId);
  }, [claimData]);

  const onSubmit = (values) => {
    updateRemark(values, {
      onSuccess: () => {
        searchType === 'date' ? onFetch() : onFetchClaimFull();
        onToggle({ defaultValue: '' });
      },
    });
  };

  return (
    <ModalTheme title=" " open={open} onToggle={onToggle}>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <TextLarge text="หมายเหตุ" />
      </div>
      <div style={{ textAlign: 'left', width: '100%' }}>
        <TextSmall text={'ชื่อ : ' + claimData?.fullName} />
        <TextSmall text={'เลขที่เคลม : ' + claimData?.claimCode} />
      </div>
      <RenderForm
        control={control}
        forms={FORM_REMARK()}
        setValue={setValue}
        getValues={getValues}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
