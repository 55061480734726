import { combineReducers } from 'redux';
import { authenReducer } from './authen/reducer';
import { alertReducer } from './alert/reducer';

const rootReducer = combineReducers({
  authen: authenReducer,
  alert: alertReducer,
});

export default rootReducer;
