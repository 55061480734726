import { ButtonTheme, ButtonToolTip } from '../../../components';
import {
  createColumnButtonArr,
  createColumnDate,
  createColumnText,
  widthOptions,
} from '../../../components/table/function';

export const columns = ({ onModalMessage, onModalEditUserInfo }) => [
  createColumnText('รหัสลูกค้า', 'customerCode', widthOptions(150)),
  createColumnText('ชื่อ', 'fullName', widthOptions(130)),
  createColumnDate('วันเกิด', 'birthday', widthOptions(120)),
  createColumnText('เบอร์ติดต่อ', 'telephone', widthOptions(150)),
  createColumnText('ไลน์', 'lineName', widthOptions(120)),
  createColumnButtonArr('จัดการ', 'rowNo', {
    others: {
      fixed: 'right',
      width: 180,
    },
    buttonArr: [
      {
        useFor: 'EDIT2',
        render: (_, record) => (
          <ButtonToolTip toolTip="แก้ไข" placement="topLeft">
            <ButtonTheme useFor="EDIT2" onClick={() => onModalEditUserInfo(record)} />
          </ButtonToolTip>
        ),
      },
      {
        useFor: 'LINE2',
        render: (_, record) => (
          <ButtonToolTip toolTip="ส่งข้อความ" placement="topLeft">
            <ButtonTheme useFor="LINE2" onClick={() => onModalMessage(record)} />
          </ButtonToolTip>
        ),
      },
    ],
  }),
];
