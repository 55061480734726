import { useCallback, useEffect, useState } from 'preact/hooks';
import { useTeleQuery } from './useGetTeleQuery';
import { useForm } from 'react-hook-form';
import { useWarrantyQuery } from './useWarrantyQuery';
import { useOptions } from '../../warranty-edit/hooks/useOptions';
import {
  channelBuyQuery,
  storeTypeChannelQuery,
} from '../../../../services/react-query/optionMasterQuery';
import { onUploadFile } from './onUploadFile';
import { handleSubmitForm } from '../func/submitHandler';

export const useGetWarrantyAddList = () => {
  const [showStoreField, setShowStoreField] = useState('');
  const { control, setValue, getValues, handleSubmit, reset, watch } = useForm();
  const { mutate: onCallTele, isPending: isPendingTele } = useTeleQuery({ reset, getValues });
  const { mutate: onCallWarranty, isPending: isPendingWarranty } = useWarrantyQuery({
    reset,
    getValues,
  });

  const { mobileBrandOptions, mobileModelOptions, productOptions } = useOptions({ watch });
  const { data: channelBuyOptions, isPending: loadingChannelBuy } = channelBuyQuery();
  const { mutate: onAddWarranty, isPending: isPendingAddWarranty } = onUploadFile({ reset });
  const { data: storeTypeChannelOptions, isPending: loadingStoreType } =
    storeTypeChannelQuery(showStoreField);
  const watchChannelBuy = watch('channelBuyId');

  useEffect(() => {
    if (watchChannelBuy && channelBuyOptions) {
      const selectedChannel = channelBuyOptions.find((option) => option.value === watchChannelBuy);
      const channelType = selectedChannel?.channelBuyType;
      if (['FOCUS', 'STORE'].includes(channelType)) {
        setShowStoreField(channelType);
      } else {
        setShowStoreField('');
      }
    }
  }, [watchChannelBuy, channelBuyOptions]);

  const handleOnEnterWarranty = useCallback(
    async (value) => {
      if (value.includes('?fw=')) {
        try {
          const url = new URL(value);
          value = url.searchParams.get('fw');
        } catch (error) {
          return;
        }
      }

      await onCallWarranty(value, {
        onSuccess: (data) => {
          setValue('warrantyNumberCode', data?.warranty?.warrantyNumberCode);
        },
        onError: () => {
          setValue('warrantyNumberCode', '');
        },
      });
    },
    [onCallWarranty, setValue],
  );

  const handleOnEnterTele = (value) => {
    onCallTele(value);
  };

  const onChangeValue = useCallback(
    (_, item) => {
      switch (item?.name) {
        case 'mobileBrandId':
          setValue('mobileModelId', '');
          setValue('mapProductId', '');
          break;
        case 'mobileModelId':
          setValue('mapProductId', '');
          break;
        case 'channelBuyId':
          setValue('storeId', '');
          break;
      }
    },
    [setValue],
  );

  useEffect(() => {
    if (mobileBrandOptions.length === 1) {
      setValue('mobileBrandId', mobileBrandOptions[0]?.value);
    }
    if (mobileModelOptions.length === 1) {
      setValue('mobileModelId', mobileModelOptions[0]?.value);
    }
  }, [mobileBrandOptions, mobileModelOptions, setValue]);

  const onSubmit = (values) => handleSubmitForm(values, onAddWarranty);

  return {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    watch,
    mobileBrandOptions,
    mobileModelOptions,
    productOptions,
    channelBuyOptions,
    storeTypeChannelOptions,
    onSubmit,
    isPendingTele,
    isPendingWarranty,
    handleOnEnterWarranty,
    handleOnEnterTele,
    onAddWarranty,
    onChangeValue,
    showStoreField,
    loadingStoreType,
    isPendingAddWarranty,
    loadingChannelBuy,
  };
};
