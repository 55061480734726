import { GET, GET_WARRANTY_INFO_CODE } from '../../../../services';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useEffect } from 'preact/hooks';
import { openAlert } from '../../../../components/alert/hooks';

export const useGetWarrantyEdit = ({ warrantyCode, reset, getValues }) => {
  const [data, setData] = useState();

  const { isPending, mutate } = useMutation({
    mutationFn: async () => {
      const response = await GET(GET_WARRANTY_INFO_CODE(warrantyCode));
      return response.result;
    },
    onSuccess: (result) => {
      const mobilebrands = result?.item?.mobilebrands;
      reset({
        ...getValues(),
        ...result?.warranty,
        invImageUrl: [{ uid: result?.warranty?.invImageUrl, url: result?.warranty?.invImageUrl }],
        packageImageUrl: [
          { uid: result?.warranty?.packageImageUrl, url: result?.warranty?.packageImageUrl },
        ],
        mobilebrands,
      });
      setData(result);
    },
    onError: (error) => {
      openAlert({ model: 'message', type: 'error', message: error?.message });
    },
  });

  useEffect(() => {
    if (warrantyCode) {
      mutate();
    }
  }, [warrantyCode]);

  return { dataSource: data, setData, onSearch: mutate, loading: isPending };
};
