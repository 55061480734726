import React, { useEffect, useState } from 'react';
import { ModalTheme } from '../../../components';
import { TextLarge, TextSmall } from '../../../components/text';
import { QRCode } from 'antd';

export const QrCodeStore = ({ open, onToggle, initialValues }) => {
  const [dataQr, setDataQr] = useState(null);

  useEffect(() => {
    if (initialValues) {
      const qrCode = JSON.stringify({
        ...initialValues?.qrCode,
      });
      setDataQr(qrCode);
    }
  }, [initialValues]);

  return (
    <ModalTheme title=" " open={open} onToggle={onToggle}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '16px',
          padding: '24px',
        }}
      >
        <TextLarge text={`ร้าน : ${initialValues?.storeMasterName}`} />
        <TextSmall text={`สาขา : ${initialValues?.storeName}`} />
        <QRCode value={dataQr} size={250} />
      </div>
    </ModalTheme>
  );
};
