import React, { useEffect } from 'react';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../../components';
import { ContainerButton } from '../../../../styles/global-style';
import { FORM_EDIT_COLOR } from '../form';
import { useForm } from 'react-hook-form';
import { useEditColor } from '../hooks/useEditColor';
import { Spin } from 'antd';

export const EditColor = ({ open, onToggle, onFetch, initialValues }) => {
  const { control, setValue, getValues, handleSubmit } = useForm();
  const { mutate: onEditColor, isPending: loadingEditColor } = useEditColor({
    onSuccessCallBack: onFetch,
  });

  useEffect(() => {
    if (initialValues) {
      Object.entries(initialValues).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [initialValues, setValue]);

  const onSubmit = (value) => {
    onEditColor(value);
    onToggle({ defaultValues: '' });
  };

  return (
    <Spin spinning={loadingEditColor}>
      <ModalTheme title="แก้ไขสี QR" open={open} onToggle={onToggle}>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          forms={FORM_EDIT_COLOR()}
        />
        <ContainerButton right>
          <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
          <ButtonTheme useFor="CANCEL" onClick={onToggle} />
        </ContainerButton>
      </ModalTheme>
    </Spin>
  );
};
