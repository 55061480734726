import React from 'react';
import { SpaceBetween, StyledContainer } from '../../../components/container/styles';
import { TextLarge } from '../../../components/text';
import { ResponsivePageLayout } from '../../../components/page/responsive-layout/ResponsivePageLayout';
import { Icon } from '../../../resources';
import { ContainerButton } from '../../../styles/global-style';
import ButtonExcelEmbedded from '../../../components/report-excel/ButtonExcelEmbedded';
import { useGetModelList } from './hooks/useGetModelList';

export const Model = () => {
  const { formSearch, formSearchObj, columnData, dataModel, isLoadingModelList, onFetch } =
    useGetModelList();

  return (
    <div>
      <StyledContainer>
        <TextLarge>รุ่นมือถือ</TextLarge>
        <ResponsivePageLayout
          searchLayout={{
            title: 'ค้นหารุ่นมือถือ',
            formSearch,
            useFormParam: formSearchObj,
            icon: <Icon.todo />,
            ignoreDateEmpty: true,
            onSearch: onFetch,
          }}
          tableLayout={{
            columns: columnData,
            dataSource: dataModel,
            hasPagination: true,
            loading: isLoadingModelList,
            extraContent: (
              <SpaceBetween>
                <ContainerButton right>
                  <ButtonExcelEmbedded
                    header={columnData}
                    data={dataModel}
                    filename="รายงานรุ่นมือถือ"
                    title="Export"
                  />
                </ContainerButton>
              </SpaceBetween>
            ),
          }}
        />
      </StyledContainer>
    </div>
  );
};
