import { useMutation } from '@tanstack/react-query';
import { postCutOfWarranty } from '../../../resources/payload';
import { openAlert } from '../../../components/alert/hooks';
import { POST, POST_CUT_OF_WARRANTY } from '../../../services';

export const usePostCutOfWarranty = () =>
  useMutation({
    mutationFn: async (values) => {
      const payload = postCutOfWarranty(values);

      const response = await POST(POST_CUT_OF_WARRANTY(), payload);
      return response;
    },
    onSuccess: (value) => {
      openAlert({
        model: 'message',
        type: 'success',
        message: value?.message || 'บันทึกข้อมูลสำเร็จ',
      });
    },
    onError: (err) => {
      openAlert({
        model: 'message',
        type: 'error',
        message: err?.message || 'เกิดข้อผิดพลาดในการบันทึกข้อมูล',
      });
    },
  });
