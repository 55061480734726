import { useEffect } from 'preact/hooks';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../components';
import { ContainerButton } from '../../../styles/global-style';
import { useUpdateTel } from '../hooks/useUpdateTel';
import { FORM_SYNC_OLD_TEL } from '../form';

export const TelEdit = ({ open, onToggle, initialValues, onFetch, onCloseAll }) => {
  const { control, setValue, getValues, handleSubmit } = useForm();
  const { mutate: onSubmitUpdateTel } = useUpdateTel({
    onSuccessCallBack: () => {
      onFetch();
      onCloseAll();
    },
  });

  useEffect(() => {
    if (!initialValues) {
      return;
    }
    Object.keys(initialValues).forEach((key) => {
      setValue(key, initialValues[key]);
    });
  }, [initialValues, setValue]);

  const onSubmit = (value) => {
    onSubmitUpdateTel(value);
    onToggle({ defaultValues: '' });
  };

  return (
    <ModalTheme title="แก้ไขเบอร์ติดต่อใหม่" open={open} onToggle={onToggle}>
      <RenderForm
        control={control}
        forms={FORM_SYNC_OLD_TEL()}
        setValue={setValue}
        getValues={getValues}
      />
      <ContainerButton>
        <ContainerButton right>
          <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
          <ButtonTheme useFor="CANCEL" onClick={onToggle} />
        </ContainerButton>
      </ContainerButton>
    </ModalTheme>
  );
};
