import {
  createColumnButton,
  createColumnNumber,
  createColumnTag,
  createColumnText,
  widthOptions,
} from '../../../components/table/function';
import { GENERATE_STATUS } from '../../../resources/status';

export const columns = ({ onGetQrExcel }) => {
  return [
    createColumnTag(
      'สถานะ',
      { key: 'generateStatus', text: 'generateStatus', icon: true },
      GENERATE_STATUS,
    ),
    createColumnNumber('จำนวน QR Code', 'serialNumberAmount', widthOptions(200)),
    createColumnText('อีเมล', 'recipientEmail', widthOptions(200)),
    createColumnText('สร้างโดย', 'createBy', widthOptions(200)),
    createColumnButton('Download ข้อมูล QR', 'excel', {
      buttonArr: [
        {
          useFor: 'DOWNLOAD_QR',
          onClick: (record) => {
            onGetQrExcel({ periodCode: record?.record?.periodCode });
          },
        },
      ],
    }),
  ];
};
