import { useForm } from 'react-hook-form';
import { FORM_SEARCH, searchDefaultValues } from '../form';
import { columnAfter, columnBefore, columns } from '../columns';
import { useGetReportChangeByDate } from './useGetReportChangeByDate';
import { useEffect, useState } from 'preact/hooks';
import { useGetReportChangeFull } from './useGetReportChangeFull';

export const useGetReportClaim = () => {
  const [searchType, setSearchType] = useState(null);
  const formSearchObj = useForm({ defaultValues: searchDefaultValues });

  const {
    dataSource: changeProduct,
    onFetch,
    isLoading: loading,
  } = useGetReportChangeByDate({
    handleSubmit: formSearchObj.handleSubmit,
  });

  const {
    dataSource: changeProductFull,
    onFetchFull,
    loading: loadingFull,
  } = useGetReportChangeFull({
    handleSubmit: formSearchObj.handleSubmit,
  });

  useEffect(() => {
    onSearchByField(searchDefaultValues);
  }, []);

  const onSearchByField = (values) => {
    if (values.search) {
      setSearchType('search');
      onFetchFull(values);
    } else if (values.startDate) {
      setSearchType('date');
      onFetch(values);
    }
  };

  const formatDataForExcel = (data) => {
    const formattedData = [];

    // วนลูปข้อมูลหลัก
    data.forEach((record) => {
      const baseData = {
        rowNumber: record.rowNumber,
        fullName: record.fullName,
        warrantyCode: record.warrantyCode,
        logBy: record.logBy,
        totalChanges: record.totalChanges,
        logDate: record.logDate,
      };

      // ถ้าไม่มีข้อมูล claimChangeProductDetails ให้เพิ่มแถวเปล่า
      if (!record.claimChangeProductDetails || record.claimChangeProductDetails.length === 0) {
        formattedData.push({
          ...baseData,
        });
        return;
      }

      // วนลูปข้อมูล claimChangeProductDetails เพื่อสร้างแถวสำหรับแต่ละรายการ
      record.claimChangeProductDetails.forEach((detail, index) => {
        const beforeData = detail.logDataBefore || {};
        const afterData = detail.logDataAfter || {};

        // สร้างข้อมูลสำหรับแถวนี้
        const rowData = {
          ...baseData,
          // ถ้าเป็นแถวที่ 2 เป็นต้นไป ไม่ต้องแสดงข้อมูลหลักซ้ำ
          ...(index > 0
            ? {
                rowNumber: '',
                fullName: '',
                warrantyCode: '',
                logBy: '',
                totalChanges: '',
                logDate: '',
              }
            : {}),

          // เพิ่มข้อมูล before
          before_itemCode: beforeData.itemCode || '',
          before_productName: beforeData.productName || '',
          before_mobileModelName: beforeData.mobileModelName || '',
          before_mobileBrandName: beforeData.mobileBrandName || '',

          // เพิ่มข้อมูล after
          after_itemCode: afterData.itemCode || '',
          after_productName: afterData.productName || '',
          after_mobileModelName: afterData.mobileModelName || '',
          after_mobileBrandName: afterData.mobileBrandName || '',
          after_remark: afterData.remark || '',
        };

        formattedData.push(rowData);
      });
    });

    return formattedData;
  };

  const columnData = columns();
  const columnBeforeData = columnBefore();
  const columnAfterData = columnAfter();
  const formSearch = FORM_SEARCH({ onEnter: formSearchObj.handleSubmit(onSearchByField) });

  return {
    formSearchObj,
    formSearch,
    columnData,
    columnBeforeData,
    columnAfterData,
    changeProduct,
    changeProductFull,
    searchType,
    onSearchByField,
    loadingFull,
    loading,
    formatDataForExcel,
  };
};
