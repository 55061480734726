import React from 'react';
import { SpaceBetweenShowInfo, StyledContainer } from '../../../components/container/styles';
import { TextLarge } from '../../../components/text';
import { CardTheme } from '../../../components/card';
import { ResponsivePageLayout } from '../../../components/page/responsive-layout/ResponsivePageLayout';
import { Icon } from '../../../resources/icon';
import { ContainerButton } from '../../../styles/global-style';
import ButtonExcelEmbedded from '../../../components/report-excel/ButtonExcelEmbedded';
import { ButtonTheme, Gap } from '../../../components';
import { columns, exportHeaders } from './columns';
import { ChangeTrackNumber } from './modals/TrackingNumber';
import { LineMessage } from './modals/LineMessage';
import { RemarkMessage } from './modals/RemarkMessage';
import { InfoClaimStatus } from './views/InfoRegistration';
import { Row, Spin } from 'antd';
import { AutoTracking } from './modals/AutoTracking';
import { useGetClaimList } from './hooks/useGetClaimList';
import { CustomImage } from '../../../functions/Image';
import { searchDefaultValues } from './form';
import { ProcessStatus } from './modals/ProcessStatus';

export const ClaimList = () => {
  const {
    formSearch,
    onOpenClaimAdd,
    onOpenTracking,
    onOpenLineMessage,
    onOpenRemarkMessage,
    onOpenAutoTracking,
    onToggleTracking,
    onToggleLineMessage,
    onToggleRemarkMessage,
    onToggleAutoTracking,
    onSearchByField,
    handleSubmitClaim,
    loadingClaimDate,
    loadingClaimFull,
    dataClaimDate,
    dataClaimFull,
    selectedRowKeys,
    searchType,
    claimData,
    onFetch,
    onSelectChange,
    onOpenClaimView,
    onOpenClaimEdit,
    formSearchObj,
    openLineMessage,
    openRemarkMessage,
    openTracking,
    openAutoTracking,
    setSelectedRowKeys,
    transformDataForExcel,
    onDownloadClaimPDF,
    onModalProcessStatus,
    openProcessStatus,
    onToggleProcessStatus,
    onFetchClaimFull,
    openConfirmModal,
    loadingSubmitApproveClaim,
    loadingDownloadPDF,
    handleSubmitResendKerry,
    loadingResendKerry,
    privilegeFocusType,
  } = useGetClaimList();

  return (
    <Spin spinning={loadingSubmitApproveClaim || loadingDownloadPDF || loadingResendKerry}>
      <StyledContainer>
        <TextLarge text="การเคลมสินค้า" />
        <CardTheme>
          {privilegeFocusType !== 'READ' && (
            <ContainerButton right>
              <ButtonTheme useFor="WARRANTY" title="ยื่นเรื่องเคลม" onClick={onOpenClaimAdd} />
            </ContainerButton>
          )}
          {privilegeFocusType !== 'READ' && (
            <Row gutter={16} flex justify="space-between">
              <Row justify="start">
                <ButtonTheme
                  useFor="BILL"
                  title="ใบส่งสินค้า"
                  disabled={selectedRowKeys.length === 0}
                  onClick={onDownloadClaimPDF}
                />
                <ButtonTheme
                  useFor="NEW_KERRY"
                  title="ขอเลขพัสดุใหม่ (KERRY)"
                  disabled={selectedRowKeys.length === 0}
                  onClick={handleSubmitResendKerry}
                />
              </Row>
              <Row justify="end">
                <ButtonTheme
                  useFor="CONFIRM_OK"
                  title="อนุมัติเคลม"
                  onClick={() =>
                    openConfirmModal({
                      onOk: () => handleSubmitClaim(),
                    })
                  }
                  disabled={selectedRowKeys.length === 0}
                />
                <ButtonTheme
                  useFor="TRANSPORT"
                  title="เลือกขนส่ง"
                  disabled={selectedRowKeys.length === 0}
                  onClick={onOpenAutoTracking}
                />
                {openAutoTracking && (
                  <AutoTracking
                    open={openAutoTracking}
                    onToggle={onToggleAutoTracking}
                    claimData={claimData}
                    selectedRowKeys={selectedRowKeys}
                    onDownloadClaimPDF={onDownloadClaimPDF}
                  />
                )}
              </Row>
            </Row>
          )}
          <Gap />
          <ResponsivePageLayout
            searchLayout={{
              title: 'ค้นหาการเคลมสินค้า',
              icon: <Icon.todo />,
              formSearch,
              useFormParam: formSearchObj,
              onSearch: formSearchObj.handleSubmit(onSearchByField),
              onClearCustom: () => {
                formSearchObj.reset(searchDefaultValues);
                setSelectedRowKeys([]);
              },
            }}
            content={<InfoClaimStatus />}
            tableLayout={{
              columns: columns({
                onOpenTracking,
                onOpenLineMessage,
                onOpenRemarkMessage,
                onOpenClaimEdit,
                onOpenClaimView,
                onModalProcessStatus,
                privilegeFocusType,
              }),
              loading: loadingClaimFull || loadingClaimDate,
              dataSource: searchType === 'search' ? dataClaimFull : dataClaimDate,
              rowKey: 'claimId',
              hasPagination: true,
              rowSelection: {
                selectedRowKeys,
                onChange: onSelectChange,
              },
              expandable: {
                expandedRowRender: (record) => (
                  <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <CustomImage
                      width={80}
                      preview
                      src={record?.claimImageUrl}
                      style={{ borderRadius: 10 }}
                    />
                    <CustomImage
                      width={80}
                      preview
                      src={record?.receiptImageUrl}
                      style={{ borderRadius: 10, marginLeft: 10 }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
                      <div style={{ marginBottom: '8px' }}>
                        <strong>ชื่อสินค้า: </strong>
                        {record.productName}
                      </div>
                      <div style={{ marginBottom: '8px' }}>
                        <strong>รุ่นมือถือ: </strong>
                        {record.mobileModelName}
                      </div>
                      <div>
                        <strong>อาการเสีย: </strong>
                        {record.breakdownName}
                      </div>
                    </div>
                  </div>
                ),
              },
              extraContent: (
                <SpaceBetweenShowInfo>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {selectedRowKeys.length > 0
                      ? `เลือก ${selectedRowKeys.length} รายการ`
                      : 'ยังไม่ได้เลือกรายการ'}
                  </div>
                  <ButtonExcelEmbedded
                    title="Export"
                    filename="รายงานการเคลมสินค้า"
                    header={exportHeaders}
                    data={transformDataForExcel(
                      searchType === 'search' ? dataClaimFull : dataClaimDate,
                    )}
                  />
                </SpaceBetweenShowInfo>
              ),
            }}
          />
        </CardTheme>
        {openTracking && (
          <ChangeTrackNumber
            open={openTracking}
            onToggle={onToggleTracking}
            claimData={claimData}
            onFetch={onFetch}
            onFetchClaimFull={onFetchClaimFull}
            searchType={searchType}
          />
        )}
        {openLineMessage && (
          <LineMessage
            open={openLineMessage}
            onToggle={onToggleLineMessage}
            claimData={claimData}
            onFetch={onFetch}
          />
        )}
        {openRemarkMessage && (
          <RemarkMessage
            open={openRemarkMessage}
            onToggle={onToggleRemarkMessage}
            claimData={claimData}
            onFetch={onFetch}
            onFetchClaimFull={onFetchClaimFull}
            searchType={searchType}
          />
        )}
        {openProcessStatus && (
          <ProcessStatus
            open={openProcessStatus}
            onToggle={onToggleProcessStatus}
            claimData={claimData}
            onFetch={onFetch}
          />
        )}
      </StyledContainer>
    </Spin>
  );
};
