import { BiEdit } from 'react-icons/bi';
import { color } from '../color';
import { ImCheckmark } from 'react-icons/im';
import { Icon } from '../icon';
import { RiDeleteBin6Line, RiTimeLine } from 'react-icons/ri';

export const WARRANTY_STATUS = [
  { key: 'ACTIVE', label: 'ACTIVE', icon: <BiEdit />, iconColor: color.blueFocus },
  { key: 'CLAIMING', label: 'CLAIMING', icon: <ImCheckmark />, iconColor: color.yellow },
  { key: 'COMPLETED', label: 'COMPLETED', icon: <Icon.box />, iconColor: color.submit },
  { key: 'EXPIRED', label: 'EXPIRED', icon: <RiTimeLine />, iconColor: '#9e9e9e' },
  { key: 'CANCEL', label: 'CANCEL', icon: <RiDeleteBin6Line />, iconColor: '#ff5e5e' },
];

export const CLAIM_STATUS = [
  { key: 'WAIT', label: 'รอการตรวจสอบ', iconColor: color.blueFocus, icon: <Icon.hourglass /> },
  {
    key: 'PREPARING',
    label: 'รอดำเนินการ',
    iconColor: color.yellow,
    icon: <Icon.truck />,
  },
  {
    key: 'TRANSPORTED',
    label: 'ดำเนินการเรียบร้อย',
    iconColor: color.line,
    icon: <Icon.paperPlane />,
  },
  { key: 'REJECTED', label: 'ไม่อนุมัติ', iconColor: color.red, icon: <Icon.times /> },
  { key: 'CANCEL', label: 'ยกเลิกรายการ', iconColor: color.gray, icon: <Icon.cancel /> },
  { key: 'BOOKING_EXPIRED', label: 'วันจองหมดอายุ', iconColor: color.gray, icon: <Icon.expired /> },
  {
    key: 'WARRANTY_EXPIRED',
    label: 'รับประกันหมดอายุ',
    iconColor: color.gray,
    icon: <Icon.expired />,
  },
];

export const PROCESS_STATUS = [
  { key: 'WAITING_REVIEW', label: 'รอตรวจสอบแจ้งเคลม', color: color.blueFocus },
  { key: 'WAITING_CHECK_LACK_ITEM', label: 'รอตรวจสอบ ของขาด', color: color.yellow },
  { key: 'INCOMPLETE_INFO', label: 'รอตรวจสอบ รูปไม่ถูกต้อง', color: color.yellow },
  { key: 'WAIT_CONTACT_CUSTOMER', label: 'รอติดต่อลูกค้า', color: color.red },
  { key: 'PREPARING_SEND', label: 'กำลังเตรียมพัสดุ', color: color.yellow },
  { key: 'SHIPPED', label: 'จัดส่งเรียบร้อย', color: color.line },
  { key: 'REJECT', label: 'ไม่อนุมัติ', color: color.red },
];

export const STORE_STATUS = [
  { key: 'ACTIVE', label: 'ACTIVE' },
  { key: 'INACTIVE', label: 'INACTIVE' },
];

export const BRAND_STATUS = [
  { key: 'ACTIVE', label: 'ACTIVE' },
  { key: 'INACTIVE', label: 'INACTIVE' },
];

export const STATUS_OPTIONS = [
  { key: 'ACTIVE', label: 'ACTIVE' },
  { key: 'INACTIVE', label: 'INACTIVE' },
];

export const MOBILE_BRAND_STATUS = [
  { key: 'ACTIVE', label: 'ACTIVE' },
  { key: 'INACTIVE', label: 'INACTIVE' },
];

export const GENERATE_STATUS = [
  { key: 'WAIT_GEN', label: 'รอ GEN QR' },
  { key: 'CREATING', label: 'กำลัง GEN QR' },
  { key: 'COMPLETED', label: 'สำเร็จ' },
  { key: 'CREATE_FAILED', label: 'ล้มเหลว' },
  { key: 'SEND_FAILED', label: 'ส่งอีเมลล้มเหลว' },
];

export const MAP_PRODUCT_STATUS = [
  { key: 'ACTIVE', label: 'ACTIVE' },
  { key: 'INACTIVE', label: 'INACTIVE' },
];

export const CLAIM_CHANNEL = [
  { key: 'ONLINE', label: 'Online' },
  { key: 'FOCUS', label: 'Focus Shop' },
  { key: 'STORE', label: 'ร้านที่ร่วมรายการ' },
];

export const CHANNEL_BUY = [
  { key: 'ONLINE', label: 'Online' },
  { key: 'COMPANY', label: 'ร้านมือถือ' },
  { key: 'FOCUS', label: 'Focus Shop' },
  { key: 'STORE', label: 'ร้านค้าที่ร่วมรายการ' },
];
