export const FORM_USER_REGISTRATION_WARRANTY = () => {
  const span = 24;
  return [
    {
      name: 'telephone',
      label: 'เบอร์ติดต่อ',
      type: 'INPUT_PHONE',
      placeholder: '',
      span,
      maxLength: 10,
      rules: {
        required: true,
        pattern: /^[0-9]*$/,
      },
      disabled: true,
    },
    {
      name: 'fullName',
      label: 'ชื่อ-นามสกุล',
      type: 'INPUT',
      placeholder: '',
      disabled: true,
      span,
    },
    {
      name: 'createDate',
      label: 'วันที่เริ่มรับประกัน',
      type: 'DATE_PICKER',
      placeholder: '',
      format: 'YYYY-MM-DD',
      disabled: true,
      span,
    },
    {
      name: 'expireDate',
      label: 'วันหมดอายุ',
      type: 'DATE_PICKER',
      format: 'YYYY-MM-DD',
      placeholder: '',
      disabled: true,
      span,
    },
  ];
};

export const FORM_PRODUCT_REGISTRATION_WARRANTY = ({ storeId }) => {
  const span = 24;
  const accept = [`image/jpeg`, 'image/png', 'image/jpg', 'image/bmp', 'image/gif'];
  const SHOW_STORE = [
    {
      name: 'storeId',
      label: 'ร้านค้า',
      type: 'INPUT',
      placeholder: '',
      disabled: true,
      span,
    },
  ];
  return [
    {
      name: 'warrantyCode',
      label: 'รหัสลงทะเบียนสินค้า',
      type: 'INPUT',
      placeholder: '',
      span,
      rules: {
        required: true,
      },
      disabled: true,
    },
    {
      name: 'warrantyNumberCode',
      label: 'รหัสรับประกันสินค้า',
      type: 'INPUT',
      placeholder: '',
      span,
      rules: {
        required: true,
      },
      disabled: true,
    },
    {
      name: 'mobileBrandName',
      label: 'ยี่ห้อมือถือ',
      type: 'DROPDOWN',
      placeholder: '',
      properties: {
        options: [],
      },
      span,
      rules: {
        required: true,
      },
      disabled: true,
    },
    {
      name: 'mobileModelName',
      label: 'รุ่นมือถือ',
      type: 'DROPDOWN',
      placeholder: '',
      properties: {
        options: [],
      },
      span,
      rules: {
        required: true,
      },
      disabled: true,
    },
    {
      name: 'productName',
      label: 'ชื่อสินค้า',
      type: 'DROPDOWN',
      placeholder: '',
      properties: {
        options: [],
      },
      span,
      rules: {
        required: true,
      },
      disabled: true,
    },
    {
      name: 'channelBuyName',
      label: 'ช่องทางการซื้อขาย',
      type: 'DROPDOWN',
      placeholder: '',
      properties: {
        options: [],
      },
      span,
      rules: {
        required: true,
      },
      disabled: true,
    },
    ...(storeId ? SHOW_STORE : []),
    {
      name: 'infoPicture',
      label: 'ชนิดรูปภาพที่ต้องการ (.png, .jpg, .jpeg, .bmp)',
      type: 'TEXT',
      span,
    },
    {
      name: 'invImageUrl',
      label: 'รูปใบเสร็จ',
      type: 'PREVIEW',
      accept: accept.join(','),
      listType: 'picture-card',
      span: 12,
      maxCount: 1,
    },
    {
      name: 'packageImageUrl',
      label: 'รูปสินค้า',
      type: 'PREVIEW',
      listType: 'picture-card',
      span: 12,
      maxCount: 1,
    },
  ];
};
