import React from 'react';
import { ModalTheme } from '../../../../components/modal/index.jsx';
import { useForm } from 'react-hook-form';
import { ContainerButton } from '../../../../styles/global-style.js';
import { FORM_MESSAGE } from '../form/index.js';
import { TextLarge, TextSmall } from '../../../../components/text/index.jsx';
import { ButtonTheme, RenderForm } from '../../../../components/index.js';
import { useSendMessage } from '../hooks/useSendMessage.js';
import { useEffect } from 'preact/hooks';

export const LineMessage = ({ open, onToggle, claimData, onFetch }) => {
  const { control, setValue, getValues, handleSubmit } = useForm({});

  const { mutate: sendMessage } = useSendMessage({
    onSuccessCallBack: onFetch,
  });

  useEffect(() => {
    setValue('claimId', claimData?.claimId);
  }, [claimData]);

  const onSubmit = (values) => {
    sendMessage(values);
    onToggle({ defaultValue: '' });
  };

  return (
    <ModalTheme title=" " open={open} onToggle={onToggle}>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <TextLarge text="ส่งข้อความ" />
      </div>
      <div style={{ textAlign: 'left', width: '100%' }}>
        <TextSmall text={'ชื่อ : ' + claimData?.fullName} />
        <TextSmall text={'เลขที่เคลม : ' + claimData?.claimCode} />
      </div>
      <RenderForm
        control={control}
        forms={FORM_MESSAGE()}
        setValue={setValue}
        getValues={getValues}
      />
      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
