import React from 'react';
import { color } from '../../../../resources';
import { Icon } from '../../../../resources/icon';

export const requestStatusObg2 = {
  [10]: {
    label: 'รอตรวจสอบ',
    color: color.blueFocus,
    icon: <Icon.hourglass />,
    order: 1,
  },
  [20]: {
    label: 'รอดำเนินการ',
    color: color.yellow,
    icon: <Icon.truck />,
    order: 2,
  },
  [30]: {
    label: 'ดำเนินการเรียบร้อย',
    color: color.line,
    icon: <Icon.paperPlane />,
    order: 3,
  },
  [40]: {
    label: 'ไม่อนุมัติ',
    color: color.edit,
    icon: <Icon.times />,
    order: 4,
  },
  [50]: {
    label: 'ยกเลิกรายการ',
    color: color.red,
    icon: <Icon.cancel />,
    order: 5,
  },
};
