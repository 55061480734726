import { useEffect } from 'preact/hooks';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ButtonTheme, ModalTheme, RenderForm } from '../../../components';
import { FORM_EDIT_USER } from '../form';
import { color } from '../../../resources';
import { ContainerButton } from '../../../styles/global-style';
import { useResetLine } from '../hooks/useResetLine';
import { useUpdateInfo } from '../hooks/useUpdateInfo';
import { useAntDModalContext } from '../../../components/modal/context/useAntdModalContext';
import { TelEdit } from './TelEdit';
import { useModal } from '../../../components/modal/hooks/useModal';

export const EditUserInfo = ({ open, onToggle, initialValues, onFetch }) => {
  const { control, setValue, getValues, handleSubmit } = useForm();
  const { openConfirmModal } = useAntDModalContext();
  const { open: openTel, onToggle: onToggleTel } = useModal();
  const { mutate: onSubmitResetLine } = useResetLine({ onSuccessCallBack: onFetch });

  const { mutate: onSubmitUpdateUser } = useUpdateInfo({
    onSuccessCallBack: onFetch,
  });

  const onCloseAll = () => {
    onToggle({ defaultValues: '' });
    onToggleTel({ defaultValues: '' });
  };

  useEffect(() => {
    if (!initialValues) {
      return;
    }
    Object.keys(initialValues).forEach((key) => {
      setValue(key, initialValues[key]);
    });
  }, [initialValues, setValue]);

  const onSubmit = (value) => {
    onSubmitUpdateUser(value);
    onToggle({ defaultValues: '' });
  };

  return (
    <ModalTheme title="แก้ไขข้อมูล" open={open} onToggle={onToggle}>
      <RenderForm
        control={control}
        forms={FORM_EDIT_USER()}
        setValue={setValue}
        getValues={getValues}
      />
      <div className="button">
        <ButtonTheme
          useFor="TEL"
          style={{ backgroundColor: color.blackFocus }}
          title="แก้ไขเบอร์ติดต่อ"
          onClick={onToggleTel}
        />
        <ButtonTheme
          useFor="LINE"
          title="รีเซ็ตไลน์"
          style={{ marginLeft: '40%', backgroundColor: color.line }}
          onClick={() =>
            openConfirmModal({
              onOk: () => {
                onSubmitResetLine({ customerId: initialValues.customerId });
                onToggle({ defaultValues: '' });
              },
              title: 'คุณต้องการรีเซ็ตไลน์หรือไม่',
            })
          }
        />
      </div>

      <ContainerButton right>
        <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
      {openTel && (
        <TelEdit
          open={openTel}
          onToggle={onToggleTel}
          initialValues={initialValues}
          onFetch={onFetch}
          onCloseAll={onCloseAll}
        />
      )}
    </ModalTheme>
  );
};
