export const FORM_SEARCH = ({ onEnter }) => {
  return [
    {
      name: 'search',
      type: 'INPUT',
      label: 'ค้นหารายการ',
      placeholder: 'ค้นหาร้านค้า',
      span: 12,
      helper:
        'Tips : สามารถค้นหาด้วย รหัสร้านค้า หรือ customer account ใน ax , saleCode , เบอร์ , อีเมล , ชื่อร้าน',
      onEnter,
    },
  ];
};

export const searchDefaultValue = {
  search: '',
};

export const FORM_CREATE_STORE = ({ storeTypeList }) => {
  const span = 12;
  return [
    {
      name: 'customerAccountCode',
      label: 'รหัสบัญชีร้านค้า',
      type: 'INPUT',
      span,
      placeholder: 'กรอกรหัสบัญชีร้านค้า',
      rules: {
        required: true,
      },
    },
    {
      name: 'storeMasterName',
      label: 'ชื่อร้านค้า',
      type: 'INPUT',
      span,
      placeholder: 'กรอกชื่อร้านค้า',
      rules: {
        required: true,
      },
    },
    {
      name: 'storeName',
      label: 'สาขา',
      type: 'INPUT',
      placeholder: 'กรอกสาขา',
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'saleCode',
      label: 'รหัส Sale',
      type: 'INPUT',
      placeholder: 'กรอกรหัส Sale',
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'phoneNumber',
      label: 'เบอร์ติดต่อ',
      type: 'INPUT_PHONE',
      placeholder: 'กรอกเบอร์โทรศัพท์ 10 หลัก',
      span,
      maxLength: 10,
      rules: {
        required: true,
        pattern: /^[0-9]*$/,
      },
    },
    {
      name: 'storeTypeId',
      label: 'บริษัท',
      type: 'DROPDOWN',
      placeholder: '--กรุณาเลือก--',
      span,
      properties: {
        options: storeTypeList || [],
      },
      rules: {
        required: true,
      },
    },
    {
      name: 'email',
      label: 'อีเมล',
      type: 'INPUT',
      placeholder: 'กรุณากรอกอีเมล',
      span,
      rules: {
        required: true,
        type: 'email',
        pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      },
    },
  ];
};

export const FORM_EDIT_STORE = ({ storeTypeList, statusOptions }) => {
  const span = 12;
  return [
    {
      name: 'storeId',
      label: 'รหัสร้านค้า',
      type: 'HIDDEN',
    },
    {
      name: 'storeCode',
      label: 'รหัสร้านค้า',
      type: 'INPUT',
      span,
      placeholder: 'กรอกรหัสร้านค้า',
      rules: {
        required: true,
      },
      disabled: true,
    },
    {
      name: 'storeMasterName',
      label: 'ชื่อร้านค้า',
      type: 'INPUT',
      span,
      placeholder: 'กรอกชื่อร้านค้า',
      rules: {
        required: true,
      },
    },
    {
      name: 'storeName',
      label: 'สาขา',
      type: 'INPUT',
      placeholder: 'กรอกสาขา',
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'saleCode',
      label: 'รหัส Sale',
      type: 'INPUT',
      placeholder: 'กรอกรหัส Sale',
      span,
      rules: {
        required: true,
      },
      disabled: true,
    },
    {
      name: 'phoneNumber',
      label: 'เบอร์ติดต่อ',
      type: 'INPUT',
      placeholder: 'กรอกเบอร์โทรศัพท์ 10 หลัก',
      span,
      maxLength: 10,
      rules: {
        required: true,
        pattern: /^[0-9]*$/,
      },
    },
    {
      name: 'storeTypeId',
      label: 'บริษัท',
      type: 'DROPDOWN',
      placeholder: '--กรุณาเลือก--',
      span,
      properties: {
        options: storeTypeList || [],
      },
      disabled: true,
    },
    {
      name: 'storeStatus',
      label: 'สถานะ',
      type: 'DROPDOWN',
      properties: {
        options: statusOptions || [],
      },
      span,
      rules: {
        required: true,
      },
    },
    {
      name: 'email',
      label: 'อีเมล',
      type: 'INPUT',
      placeholder: 'กรุณากรอกอีเมล',
      span,
      rules: {
        required: true,
        type: 'email',
        pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      },
    },
  ];
};
