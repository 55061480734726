import React from 'react';
import { StyledContainer } from '../../components/container/styles';
import { TextLarge } from '../../components/text';
import { Col, Row, Spin } from 'antd';
import { CardTheme } from '../../components/card';
import { ButtonTheme, Gap, RenderForm } from '../../components';
import { ResponsivePageLayout } from '../../components/page/responsive-layout/ResponsivePageLayout';
import { ContainerButton } from '../../styles/global-style';
import { useGetCutOfWarranty } from './hooks/useGetCutOfWarranty';
import { FORM_CUT_OF_WARRANTY, FORM_INFO_DOC, FORM_OPTIONS_CUT_OF_WARRANTY } from './form';

export const CutOfWarranty = () => {
  const {
    control,
    setValue,
    getValues,
    dataColumn,
    showBranch,
    onEnter,
    qrCodeInputRef,
    loadingWarrantyQr,
    tableData,
    warrantyCodeInputRef,
    claimCodeInputRef,
    loadingPostCutOfWarranty,
    storeCutOffData,
    onSubmit,
    handleSubmit,
    loadingUpdateCutOfWarranty,
    loadingStoreCutOff,
    privilegeFocusType,
  } = useGetCutOfWarranty();

  return (
    <Spin
      spinning={
        loadingPostCutOfWarranty ||
        loadingWarrantyQr ||
        loadingUpdateCutOfWarranty ||
        loadingStoreCutOff
      }
    >
      <StyledContainer>
        <TextLarge text="ข้อมูลตัดรับประกัน (เลือกกรอกเลขที่ลงทะเบียนหรือเลขที่เคลม อย่างใดอย่างหนึ่ง)" />
        <Gap />
        {privilegeFocusType !== 'READ' && (
          <RenderForm
            control={control}
            setValue={setValue}
            getValues={getValues}
            forms={FORM_OPTIONS_CUT_OF_WARRANTY()}
          />
        )}
        <Row gutter={16}>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="ข้อมูลตัดรับประกัน" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_CUT_OF_WARRANTY({
                  showBranch,
                  onEnter,
                  qrCodeInputRef,
                  warrantyCodeInputRef,
                  claimCodeInputRef,
                  storeCutOffData,
                })}
              />
            </CardTheme>
          </Col>
          <Col span={14}>
            <CardTheme>
              <TextLarge text="ข้อมูลเอกสาร" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_INFO_DOC()}
              />
            </CardTheme>
          </Col>
        </Row>
        <Gap />
        <ResponsivePageLayout
          tableLayout={{
            columns: dataColumn,
            dataSource: tableData || [],
          }}
        />
        <Gap />
        <ContainerButton center>
          <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        </ContainerButton>
      </StyledContainer>
    </Spin>
  );
};
