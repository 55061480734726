import React from 'react';

export const MainPage = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '60vh',
      }}
    >
      <img src="../../assets/images/logo-focus.png" alt="Logo" />
    </div>
  );
};
