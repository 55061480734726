import { useEffect, useState } from 'react';
import { GET, GET_EDIT_MANAGEMENT_PAGE } from '../../../../services';
import { useMutation } from '@tanstack/react-query';

export const useGetEditProduct = ({ mapProductId, reset }) => {
  const [data, setData] = useState([]);
  const { isPending, mutate } = useMutation({
    mutationFn: async () => {
      if (!mapProductId) {
        return null;
      }
      const response = await GET(GET_EDIT_MANAGEMENT_PAGE(mapProductId));
      return response?.result?.item;
    },
    onSuccess: (result) => {
      if (!result) return;
      console.log('result', result);
      setData(result);
      reset({
        ...result,
        productImageUrl: [{ uid: result?.productImageUrl, url: result?.productImageUrl }],
      });
    },
  });

  useEffect(() => {
    if (mapProductId) {
      mutate();
    }
  }, [mapProductId]);

  return { dataSource: data, onSearch: mutate, loading: isPending };
};
