import { useForm } from 'react-hook-form';
import { ROUTES_PATH } from '../../../../resources/routes-name';
import { useGetClaimEdit } from './useGetClaimEdit';
import { useEffect, useState } from 'preact/hooks';
import { navigateTo } from '../../../../utils/navigation';
import { useZipcode } from './useZipcodeQuery';
import { useOptionProvince } from './useOptionProvince';
import { onUploadFile } from './onUploadFile';
import { updateClaim } from '../../../../resources/payload';
import {
  channelClaimQuery,
  storeTypeClaimsQuery,
} from '../../../../services/react-query/optionMasterQuery';

export const useGetClaimEditList = () => {
  const [state, setState] = useState();
  const [data, setData] = useState();
  const [claimData, setClaimData] = useState();
  const [timelineList, setTimelineList] = useState();
  const [breakdownList, setBreakdownList] = useState();
  const [showStoreField, setShowStoreField] = useState('');
  const { control, setValue, getValues, reset, watch, handleSubmit } = useForm();
  const params = new URLSearchParams(window.location.search);
  const claimCode = params.get('code');

  const { dataSource, loading: isLoadingClaimDetail } = useGetClaimEdit({
    claimCode: state?.claimCode,
    getValues,
    reset,
  });
  const { isPending: isPendingUpload, mutate: mutateUpload } = onUploadFile({ claimData });
  const { mutate: onCallZipCode, isPending: isPendingZipCode } = useZipcode({ reset, getValues });
  const { provinceOptions, districtOptions, subDistrictOptions } = useOptionProvince({ watch });
  const { data: channelClaimOptions } = channelClaimQuery();
  const { data: storeTypeClaimOptions } = storeTypeClaimsQuery(showStoreField);
  const watchChannelClaim = watch('channelClaimId');

  const callZipCode = (value) => {
    onCallZipCode(value, {
      onSuccess: (result) => {
        reset({
          ...getValues(),
          ...result,
          provinceId: '',
          districtId: '',
          subDistrictId: '',
        });
      },
    });
  };

  useEffect(() => {
    if (data) {
      setClaimData(data?.claim);

      const autoCallZipCode = (values) => {
        if (values) {
          onCallZipCode(values);
        }
      };
      autoCallZipCode(data?.claim?.zipCode);
    }
  }, [data]);

  useEffect(() => {
    if (claimCode) {
      setState({ claimCode });
    }

    if (dataSource) {
      setData(dataSource);
    }

    if (data?.breakdowns) {
      const breakdown = data?.breakdowns?.map((item) => {
        return {
          label: item?.breakdownNameTh,
          value: item?.breakdownId,
        };
      });
      setBreakdownList(breakdown);
    }

    if (watchChannelClaim && channelClaimOptions) {
      const selectedChannel = channelClaimOptions.find(
        (option) => option.value === watchChannelClaim,
      );
      const channelClaimType = selectedChannel?.channelClaimType;
      if (['FOCUS', 'STORE'].includes(channelClaimType)) {
        setShowStoreField(channelClaimType);
      } else {
        setShowStoreField('');
      }
    }

    const timelineData = () => {
      if (data?.timelineList) {
        const timeline = data?.timelineList?.map((item) => {
          return {
            text: item?.optionStatusNameTh,
            color: item?.optionStatusCode == data?.claim?.claimStatus ? 'green' : 'black',
          };
        });
        setTimelineList(timeline);
      }
    };
    timelineData();
  }, [claimCode, dataSource, data, channelClaimOptions, watchChannelClaim]);

  const onSubmit = () =>
    handleSubmit((values) => {
      const formData = updateClaim(values);
      mutateUpload(formData);
    })();

  const goBack = () => {
    navigateTo({ pathname: ROUTES_PATH.ROUTE_CLAIM.PATH, state: getValues() });
  };

  return {
    control,
    setValue,
    getValues,
    goBack,
    timelineList,
    isLoadingClaimDetail,
    claimData,
    data,
    callZipCode,
    provinceOptions,
    districtOptions,
    subDistrictOptions,
    breakdownList,
    onSubmit,
    isPendingUpload,
    channelClaimOptions,
    storeTypeClaimOptions,
    showStoreField,
    isPendingZipCode,
  };
};
