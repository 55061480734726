import { useMutation } from '@tanstack/react-query';
import { POST, POST_UPDATE_USER_PERMISSION } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';
import { updatePermission } from '../../../resources/payload';

export const useUpdateUser = ({ userId, selectedRowKeys, dataSource, onSuccessCallback }) => {
  return useMutation({
    mutationFn: async () => {
      const payload = updatePermission({ userId, selectedRowKeys, dataSource });

      const response = await POST(POST_UPDATE_USER_PERMISSION(), payload);
      return response;
    },

    onSuccess: () => {
      openAlert({ model: 'message', type: 'success', message: 'แก้ไขข้อมูลสำเร็จ' });
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: (err) => {
      openAlert({ model: 'message', type: 'error', message: err?.message });
    },
  });
};
