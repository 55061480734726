import { useState, useCallback, useEffect } from 'preact/hooks';
import { useForm } from 'react-hook-form';
import { navigateTo } from '../../../../utils/navigation';
import { ROUTES_PATH } from '../../../../resources/routes-name';
import { FORM_WARRANTY_LIST, searchDefaultValues } from '../form';
import { useGetWarrantyDate } from './useGetWarrantyDate';
import { useGetWarrantyFull } from './useGetWarrantyFull';
import { useDeleteWarrantyQuery } from './useDeleteWarrantyQuery';
import { useSelector } from 'react-redux';

export const useWarrantyList = () => {
  const [searchType, setSearchType] = useState('date');
  const [isInitialFetch, setIsInitialFetch] = useState(true);
  const formSearchObj = useForm({ defaultValues: searchDefaultValues });
  const userProfile = useSelector((state) => state.authen.users);
  const { privilegeFocusType } = userProfile;

  const {
    dataSource: dataDate,
    onFetch,
    isLoading,
  } = useGetWarrantyDate({ handleSubmit: formSearchObj.handleSubmit });

  const {
    dataSource: dataFull,
    onFetchFull,
    loading: isLoadingFull,
  } = useGetWarrantyFull({ handleSubmit: formSearchObj.handleSubmit });

  const { mutate: handleDelete } = useDeleteWarrantyQuery({
    onSuccessCallBack: onFetch,
  });

  const onSearch = (values) => {
    if (values.search) {
      setSearchType('search');
      onFetchFull(values);
    } else if (values.startDate) {
      setSearchType('date');
      onFetch(values);
    }
  };

  const gotoWarrantyAdd = useCallback(() => {
    navigateTo({ pathname: ROUTES_PATH.ROUTE_WARRANTY_ADD.PATH });
  }, []);

  const formSearch = FORM_WARRANTY_LIST({ onEnter: formSearchObj.handleSubmit(onSearch) });

  useEffect(() => {
    if (location.state && isInitialFetch) {
      formSearchObj.handleSubmit(onFetch)();
      setIsInitialFetch(false);
    } else {
      onFetch();
    }
  }, [location.state, isInitialFetch]);

  const transformDataForExcel = (data) => {
    return data?.map((item) => ({
      warrantyCode: item.warrantyCode,
      warrantyNumberCode: item.warrantyNumberCode,
      categoryCode: item.categoryCode || '-',
      warrantyStatus: item.warrantyStatus,
      mobileBrandName: item.mobileBrandName,
      mobileModelName: item.mobileModelName,
      itemCode: item.itemCode,
      productName: item.productName,
      channelBuyName: item.channelBuyName,
      fullName: item.fullName,
      telephone: item.telephone,
      email: item.email || '-',
      createDate: item.createDate,
      claimCode: item.claimCode,
      claimRemark: item.claimRemark,
      claimDate: item.claimDate,
      lineUid: item.lineUid,
    }));
  };

  return {
    searchType,
    formSearchObj,
    dataDate,
    dataFull,
    isLoading,
    isLoadingFull,
    onSearch,
    onFetch,
    gotoWarrantyAdd,
    handleDelete,
    formSearch,
    isInitialFetch,
    transformDataForExcel,
    privilegeFocusType,
  };
};
