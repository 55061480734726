import React from 'react';
import { StyledContainer } from '../../../components/container/styles';
import { TextLarge } from '../../../components/text';
import { Col, Row, Spin } from 'antd';
import { CardTheme } from '../../../components/card';
import { ButtonTheme, RenderForm } from '../../../components';
import { FORM_PRODUCT_REGISTRATION_WARRANTY, FORM_USER_REGISTRATION_WARRANTY } from './form';
import { ContainerButton } from '../../../styles/global-style';
import { useGetWarrantyAddList } from './hooks/useGetWarrantyAddList';

export const WarrantyAdd = () => {
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    isPendingTele,
    isPendingWarranty,
    handleOnEnterWarranty,
    handleOnEnterTele,
    onSubmit,
    mobileBrandOptions,
    mobileModelOptions,
    productOptions,
    channelBuyOptions,
    storeTypeChannelOptions,
    onChangeValue,
    watch,
    showStoreField,
    loadingStoreType,
    isPendingAddWarranty,
    loadingChannelBuy,
  } = useGetWarrantyAddList();

  return (
    <Spin
      spinning={
        isPendingTele ||
        isPendingWarranty ||
        loadingStoreType ||
        isPendingAddWarranty ||
        loadingChannelBuy
      }
    >
      <StyledContainer>
        <TextLarge text="ลงทะเบียนสินค้า" />
        <Row gutter={16}>
          <Col span={14}>
            <CardTheme>
              <TextLarge text="ลูกค้าลงทะเบียนรับประกัน" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_USER_REGISTRATION_WARRANTY({ onEnter: handleOnEnterTele })}
              />
            </CardTheme>
          </Col>
          <Col span={10}>
            <CardTheme>
              <TextLarge text="สินค้าลงทะเบียนรับประกัน" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                onChange={onChangeValue}
                forms={FORM_PRODUCT_REGISTRATION_WARRANTY({
                  onEnter: handleOnEnterWarranty,
                  mobileBrandOptions,
                  mobileModelOptions,
                  productOptions,
                  channelBuyOptions,
                  storeTypeChannelOptions,
                  watch,
                  showStoreField,
                })}
              />
            </CardTheme>
          </Col>
        </Row>
        <ContainerButton right>
          {isPendingAddWarranty ? (
            <ButtonTheme useFor="SUBMIT" title="ลงทะเบียน" style={{ marginTop: '2%' }} />
          ) : (
            <ButtonTheme
              useFor="SUBMIT"
              title="ลงทะเบียน"
              style={{ marginTop: '2%' }}
              onClick={handleSubmit(onSubmit)}
            />
          )}
        </ContainerButton>
      </StyledContainer>
    </Spin>
  );
};
