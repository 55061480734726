import { useMutation } from '@tanstack/react-query';
import { POST, POST_CLAIM_REJECT } from '../../../../services';
import { rejectClaim } from '../../../../resources/payload';
import { openAlert } from '../../../../components/alert/hooks';

export const useDeleteClaim = () =>
  useMutation({
    mutationFn: async (value) => {
      const payload = rejectClaim(value);
      const response = await POST(POST_CLAIM_REJECT(), payload);
      return response;
    },
    onSuccess: (response) => {
      openAlert({ type: 'success', message: response?.message });
    },
    onError: (error) => {
      openAlert({ type: 'error', message: error?.message });
    },
  });
