import { useEffect, useState } from 'preact/hooks';
import { useModal } from '../../../../components/modal/hooks/useModal';
import { useForm } from 'react-hook-form';
import { useClaimDate } from './useClaimDate';
import { useGetClaimFull } from './useGetClaimFull';
import { useApproveClaim } from './useApproveClaim';
import { navigateTo } from '../../../../utils/navigation';
import { ROUTES_PATH } from '../../../../resources/routes-name';
import { FORM_CLAIM_LIST, searchDefaultValues } from '../form';
import { useDownloadPDF } from './useDownloadPDF';
import { useAntDModalContext } from '../../../../components/modal/context/useAntdModalContext';
import { useResendKerry } from './useResendKerry';
import { useSelector } from 'react-redux';

export const useGetClaimList = () => {
  const { openConfirmModal } = useAntDModalContext();
  const [searchType, setSearchType] = useState('date');
  const [claimData, setClaimData] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isInitialFetch, setIsInitialFetch] = useState(true);
  const { open: openTracking, onToggle: onToggleTracking } = useModal();
  const { open: openLineMessage, onToggle: onToggleLineMessage } = useModal();
  const { open: openRemarkMessage, onToggle: onToggleRemarkMessage } = useModal();
  const { open: openAutoTracking, onToggle: onToggleAutoTracking } = useModal();
  const { open: openProcessStatus, onToggle: onToggleProcessStatus } = useModal();
  const formSearchObj = useForm({ defaultValues: searchDefaultValues || location.state });
  const userProfile = useSelector((state) => state.authen.users);
  const { privilegeFocusType } = userProfile;
  const searchParamState = formSearchObj.watch();
  const { handleSubmit } = formSearchObj;

  const {
    loading: loadingClaimDate,
    onFetch,
    data: dataClaimDate,
  } = useClaimDate({ setSelectedRowKeys, searchParamState });

  const {
    loading: loadingClaimFull,
    onFetchFull: onFetchClaimFull,
    dataSource: dataClaimFull,
  } = useGetClaimFull({ handleSubmit: formSearchObj.handleSubmit });

  const { mutate: onDownloadPDF, isPending: loadingDownloadPDF } = useDownloadPDF({
    selectedRowKeys,
  });

  const { mutate: onSubmitApproveClaim, isPending: loadingSubmitApproveClaim } = useApproveClaim({
    selectedRowKeys,
    onSuccessCallback: () => {
      searchType === 'search' ? onFetchClaimFull() : onFetch();
    },
  });

  const { mutate: onSubmitResendKerry, isPending: loadingResendKerry } = useResendKerry({
    selectedRowKeys,
    onSuccessCallback: () => {
      searchType === 'search' ? onFetchClaimFull() : onFetch();
    },
  });

  useEffect(() => {
    if (location.state && isInitialFetch) {
      formSearchObj.handleSubmit(onFetch)();
      setIsInitialFetch(false);
    } else {
      onFetch(searchDefaultValues);
    }
  }, [location.state, isInitialFetch]);

  const onOpenTracking = (record) => {
    setClaimData(record);
    onToggleTracking({ defaultValue: record });
  };

  const onOpenLineMessage = (record) => {
    setClaimData(record);
    onToggleLineMessage({ defaultValue: record });
  };

  const onOpenRemarkMessage = (record) => {
    setClaimData(record);
    onToggleRemarkMessage({ defaultValue: record });
  };

  const onOpenAutoTracking = (record) => {
    setClaimData(record);
    onToggleAutoTracking({ defaultValue: record });
  };

  const onModalProcessStatus = (record) => {
    setClaimData(record);
    onToggleProcessStatus({ defaultValues: record });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onOpenClaimAdd = () => {
    navigateTo({ pathname: ROUTES_PATH.ROUTE_CLAIM_ADD.PATH });
  };

  const onOpenClaimEdit = (record) => {
    const url = `${ROUTES_PATH.ROUTE_CLAIM_VIEW.PATH}?code=${record.claimCode}`;
    window.open(url, '_blank');
  };

  const onOpenClaimView = (record) => {
    const url = `${ROUTES_PATH.ROUTE_CLAIM_VIEW.PATH}?code=${record.claimCode}`;
    window.open(url, '_blank');
  };

  const onSearchByField = (values) => {
    if (values.search) {
      setSearchType('search');
      onFetchClaimFull(values);
    } else if (values.startDate) {
      setSearchType('date');
      onFetch(values);
    }
  };

  const handleSubmitClaim = (value) => {
    onSubmitApproveClaim(value, {
      onSuccess: () => {
        onOpenAutoTracking();
      },
    });
  };

  const handleSubmitResendKerry = (value) => {
    onSubmitResendKerry(value);
  };

  const onDownloadClaimPDF = () => {
    onDownloadPDF(selectedRowKeys);
  };

  const transformDataForExcel = (data) => {
    return data?.map((item) => ({
      claimCode: item.claimCode,
      fullName: item.fullName,
      lineName: item.lineName,
      telephone: item.telephone,
      claimDate: item.claimDate,
      mobileBrandName: item.mobileBrandName,
      mobileModelName: item.mobileModelName,
      itemCode: item.itemCode,
      productName: item.productName,
      breakdownName: item.breakdownName,
      detail1: item.detail1,
      remainingDays: item.remainingDays,
      channelClaimName: item.channelClaimName,
      claimStatusName: item.claimStatusName,
      processStatusName: item.processStatusName,
      trackingNumber: item.trackingNumber,
      shippingDate: item.shippingDate,
      addressFullText: item.addressFullText,
      lineUid: item.lineUid,
    }));
  };

  const formSearch = FORM_CLAIM_LIST({ onEnter: formSearchObj.handleSubmit(onSearchByField) });

  return {
    formSearch,
    onOpenTracking,
    onOpenLineMessage,
    onOpenRemarkMessage,
    onOpenAutoTracking,
    onSelectChange,
    onOpenClaimAdd,
    onOpenClaimEdit,
    onOpenClaimView,
    onSearchByField,
    handleSubmitClaim,
    loadingClaimDate,
    loadingClaimFull,
    dataClaimDate,
    dataClaimFull,
    selectedRowKeys,
    searchType,
    claimData,
    openTracking,
    openLineMessage,
    openRemarkMessage,
    openAutoTracking,
    onToggleTracking,
    onToggleLineMessage,
    onToggleRemarkMessage,
    onToggleAutoTracking,
    handleSubmit,
    formSearchObj,
    transformDataForExcel,
    setSelectedRowKeys,
    onDownloadClaimPDF,
    onModalProcessStatus,
    openProcessStatus,
    onToggleProcessStatus,
    onFetch,
    onFetchClaimFull,
    openConfirmModal,
    loadingSubmitApproveClaim,
    loadingDownloadPDF,
    handleSubmitResendKerry,
    loadingResendKerry,
    privilegeFocusType,
  };
};
