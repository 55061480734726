import { useMutation } from '@tanstack/react-query';
import { GET, GET_WARRANTY_QR } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';

export const useGetWarrantyQr = () =>
  useMutation({
    mutationFn: async (value) => {
      const response = await GET(GET_WARRANTY_QR(value));
      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'ค้นหาเลขที่รับประกันสำเร็จ',
      });
    },
    onError: (error) => {
      openAlert({
        model: 'message',
        type: 'error',
        message: error.message,
      });
    },
  });
