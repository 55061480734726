import {
  createColumnSwitch,
  createColumnText,
  widthOptions,
} from '../../../../components/table/function';

export const columns = ({ onToggleStatus }) => {
  return [
    createColumnText('รหัสแบรนด์', 'mobileBrandCode', widthOptions(200)),
    createColumnText('ชื่อแบรนด์', 'mobileBrandNameTh', widthOptions(200)),
    createColumnSwitch('สถานะแบรนด์', 'mobileBrandStatus', {
      onChange: onToggleStatus,
      textChecked: 'Active',
      textUnChecked: 'Inactive',
      others: {
        fixed: 'right',
        width: 100,
      },
    }),
  ];
};
