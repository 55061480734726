import { useMutation } from '@tanstack/react-query';
import { GET, GET_SERIAL_NUMBER_QR } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';

export const useGetQrExcel = () => {
  const { mutate: onGetQrExcel, isPending: loadingGetQrExcel } = useMutation({
    mutationFn: async (obj) => {
      const response = await GET(GET_SERIAL_NUMBER_QR(obj), undefined, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `qr-${obj.periodCode}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      return response || [];
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'ดึงข้อมูลสำเร็จ',
      });
    },
    onError: (error) => {
      openAlert({
        model: 'message',
        type: 'error',
        message: error?.message || 'ดึงข้อมูลไม่สำเร็จ',
      });
    },
  });

  return { onGetQrExcel, loadingGetQrExcel };
};
