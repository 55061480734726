import { useMutation } from '@tanstack/react-query';
import { POST, POST_EDIT_COLOR } from '../../../../services';
import { UpdateCategory } from '../../../../resources/payload';
import { openAlert } from '../../../../components/alert/hooks';

export const useEditColor = ({ onSuccessCallBack }) => {
  return useMutation({
    mutationFn: async (values) => {
      const payload = UpdateCategory(values);
      const response = await POST(POST_EDIT_COLOR(), payload);
      if (onSuccessCallBack) onSuccessCallBack(response);

      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'แก้ไข Category สำเร็จ',
      });
    },
  });
};
