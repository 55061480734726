export const FORM_GEN_QR_CODE = ({ categoryList }) => {
  const span = 24;
  return [
    {
      name: 'recipientEmail',
      label: 'อีเมล',
      type: 'INPUT',
      placeholder: 'กรอกอีเมล',
      rules: {
        required: 'กรุณากรอกอีเมล',
      },
      span,
    },
    {
      name: 'categoryId',
      label: 'สี Qr',
      type: 'DROPDOWN',
      properties: {
        options: categoryList || [],
      },
      placeholder: 'กรุณาเลือกสี Qr',
      span,
    },

    {
      name: 'serialNumberAmount',
      label: 'จำนวน QR Code',
      type: 'INPUT_PHONE',
      placeholder: 'กรุณากรอกจำนวน QR Code',
      rules: {
        required: 'กรุณากรอกจำนวน QR Code',
      },
      span,
    },
  ];
};
