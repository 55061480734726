import { Popconfirm } from 'antd';
import { ButtonTheme, ButtonToolTip } from '../../../../components';
import {
  createColumnButtonArr,
  createColumnNumber,
  createColumnText,
  formatNumber,
  widthOptions,
} from '../../../../components/table/function';

export const columns = ({ onSubmitDeleteColor, onEditColor }) => {
  return [
    createColumnText('รหัสสี QR', 'categoryCode', widthOptions(150)),
    createColumnText('ชื่อสี QR', 'categoryName', widthOptions(150)),
    createColumnText('หมายเหตุ', 'remark', widthOptions(130)),
    createColumnNumber(
      'ระยะเวลารับประกัน',
      'defaultWarrantyPeriod',
      widthOptions(150, {
        render: (value) => formatNumber(value),
      }),
    ),
    createColumnButtonArr('จัดการ', 'rowNo', {
      others: {
        fixed: 'right',
        width: 180,
      },
      buttonArr: [
        {
          useFor: 'EDIT2',
          render: (_, record) => (
            <ButtonToolTip toolTip="แก้ไข" placement="topLeft">
              <ButtonTheme useFor="EDIT2" onClick={() => onEditColor(record)} />
            </ButtonToolTip>
          ),
        },
        {
          useFor: 'DELETE2',
          render: (_, record) => (
            <ButtonToolTip toolTip="ลบ" placement="topLeft">
              <Popconfirm
                title="คุณต้องการที่จะลบสี QR ใช่หรือไม่?"
                onConfirm={() => onSubmitDeleteColor(record)}
                okText="ใช่"
                cancelText="ไม่"
              >
                <ButtonTheme useFor="DELETE" />
              </Popconfirm>
            </ButtonToolTip>
          ),
        },
      ],
    }),
  ];
};
