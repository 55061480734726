import React from 'react';
import { CardTheme } from '../../card';
import { useForm } from 'react-hook-form';
import { RenderForm } from '../../forms/index.jsx';
import { ButtonTheme } from '../../buttons';
import { App } from 'antd';
import { validateBetweenDate } from '../../../functions/fn.js';
import { ContainerButton } from '../../../styles/global-style.js';
import { TextSmall } from '../../text';

export const SearchLayout = ({ props }) => {
  const { title, formSearch, onSearch, icon, onClearCustom } = props;
  const { modal } = App.useApp();
  const { handleSubmit, errors, setValue, getValues, control } = useForm();
  const onSubmit = (data) => {
    let dateStart = '';
    let dateEnd = '';
    formSearch.forEach((val) => {
      if (val.type === 'DATE_PICKER') {
        if (val.label.includes('เริ่ม')) {
          dateStart = getValues(val.name);
        }
        if (val.label.includes('สิ้นสุด') || val.label.includes('ถึง')) {
          dateEnd = getValues(val.name);
        }
      }
    });

    if (dateStart && dateEnd) {
      let validDate = validateBetweenDate(dateStart, dateEnd);
      if (validDate) {
        modal.warning({
          title: validDate,
        });
        return;
      }
      onSearch(data);
    } else if ((dateStart && !dateEnd) || (!dateStart && dateEnd)) {
      modal.warning({
        title: 'กรุณากรอกวันที่เริ่มต้นและวันที่สิ้นสุด',
      });
    } else {
      onSearch(data);
    }
  };

  const onClear = () => {
    formSearch.forEach((val) => setValue(val.name, null));
  };

  return (
    <CardTheme
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {icon}
          &nbsp;
          <TextSmall text={title} />
        </div>
      }
      content={
        <>
          <RenderForm
            control={control}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            forms={formSearch}
          />
          <ContainerButton right>
            <ButtonTheme useFor="SEARCH" onClick={handleSubmit(onSubmit)} />
            <ButtonTheme
              useFor="CLEAR"
              onClick={onClearCustom ? () => onClearCustom(setValue, formSearch) : onClear}
            />
          </ContainerButton>
        </>
      }
    />
  );
};
