import React from 'react';
import { StyledContainer } from '../../components/container/styles';
import { TextLarge } from '../../components/text';
import { Col, Row, Spin } from 'antd';
import { CardTheme } from '../../components/card';
import { ButtonTheme, Gap, RenderForm } from '../../components';
import { useGetScan } from './hooks/useGetScan';
import { ResponsivePageLayout } from '../../components/page/responsive-layout/ResponsivePageLayout';
import { FORM_INFO_DOC, FORM_INFO_SCAN_DELIVER } from './form';
import { ContainerButton } from '../../styles/global-style';

export const ScanDelivery = () => {
  const {
    control,
    setValue,
    getValues,
    columnData,
    handleOnEnter,
    trackingInputRef,
    claimCodeInputRef,
    warrantyNumberCodeRef,
    onSubmit,
    handleSubmit,
    loadingScanDelivery,
    loadingWarrantyQr,
    tableData,
    shippingSelfOptions,
    itemCodeInputRef,
    loadingUpdateTracking,
  } = useGetScan();

  return (
    <Spin spinning={loadingScanDelivery || loadingWarrantyQr || loadingUpdateTracking}>
      <StyledContainer>
        <TextLarge text="ลงทะเบียนสินค้า (คำเตือน ไม่ควร รีเฟรชหน้าจอระหว่างการแสกน)" />
        <Row gutter={16}>
          <Col span={8}>
            <CardTheme>
              <TextLarge text="ข้อมูลการยิงงาน" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_INFO_SCAN_DELIVER({
                  onEnter: handleOnEnter,
                  trackingInputRef,
                  claimCodeInputRef,
                  warrantyNumberCodeRef,
                  shippingSelfOptions,
                  itemCodeInputRef,
                })}
              />
            </CardTheme>
          </Col>
          <Col span={16}>
            <CardTheme>
              <TextLarge text="ข้อมูลเอกสาร" />
              <RenderForm
                control={control}
                setValue={setValue}
                getValues={getValues}
                forms={FORM_INFO_DOC()}
              />
            </CardTheme>
          </Col>
        </Row>
        <Gap />
        <ResponsivePageLayout
          tableLayout={{
            style: { marginTop: '5px' },
            columns: columnData,
            dataSource: tableData || [],
            hasPagination: true,
            scroll: {
              y: 200,
            },
          }}
        />
        <Gap />
        <ContainerButton center>
          <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
        </ContainerButton>
      </StyledContainer>
    </Spin>
  );
};
