import React from 'react';
import { ModalTheme } from '../../../../components/modal/index.jsx';
import { useForm } from 'react-hook-form';
import { ContainerButton } from '../../../../styles/global-style.js';
import { FORM_AUTO_TRACKING } from '../form/index.js';
import { TextLarge } from '../../../../components/text/index.jsx';
import { ButtonTheme, RenderForm } from '../../../../components/index.js';
import { useAutoTracking } from '../hooks/useAutoTracking.js';
import { Spin } from 'antd';

export const AutoTracking = ({ open, onToggle, selectedRowKeys, onDownloadClaimPDF }) => {
  const { control, setValue, getValues, handleSubmit } = useForm({});
  const { mutate: onSubmitAutoTracking, isPending: loadingSubmitAutoTracking } = useAutoTracking({
    selectedRowKeys,
  });

  const onSubmit = (value) => {
    onSubmitAutoTracking(value, {
      onSuccess: async () => {
        await onDownloadClaimPDF();
        onToggle({ defaultValue: '' });
      },
    });
  };

  return (
    <ModalTheme title=" " open={open} onToggle={onToggle}>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <TextLarge text="เลือกขนส่ง" />
      </div>
      <RenderForm
        control={control}
        forms={FORM_AUTO_TRACKING()}
        setValue={setValue}
        getValues={getValues}
      />
      <ContainerButton right>
        {loadingSubmitAutoTracking ? (
          <Spin spinning={loadingSubmitAutoTracking} />
        ) : (
          <>
            <ButtonTheme useFor="SUBMIT" onClick={handleSubmit(onSubmit)} />
          </>
        )}
        <ButtonTheme useFor="CANCEL" onClick={onToggle} />
      </ContainerButton>
    </ModalTheme>
  );
};
