import dayjs from 'dayjs';

export const FORM_WARRANTY_LIST = ({ onEnter }) => {
  return [
    {
      name: 'search',
      label: 'ค้นหารายการ (กรอกข้อมูลแล้วกด Enter เพื่อค้นหา)',
      type: 'INPUT',
      placeholder: '',
      span: 16,
      helper:
        'Tips : ช่องค้นหารายการ สามารถค้นหาด้วย รหัสรับประกัน, QR สินค้า, เบอร์ , รหัสลูกค้า , ชื่อลูกค้า',
      onEnter,
    },
    {
      name: 'startDate',
      label: 'ค้นหาผ่านวันที่ลงทะเบียน',
      type: 'DATE_RANGE',
      placeholder: '',
      span: 8,
      rules: {
        required: true,
      },
    },
  ];
};

export const searchDefaultValues = {
  search: '',
  startDate: [dayjs().subtract(7, 'day'), dayjs()],
};
