export const FORM_OPTIONS_CUT_OF_WARRANTY = () => {
  return [
    {
      name: 'cutOfWarranty',
      type: 'RADIO',
      span: 24,
      defaultValue: 'true',
      properties: {
        options: [
          { label: 'หน้าร้าน', value: 'true' },
          { label: 'ลูกค้า', value: 'false' },
        ],
      },
    },
  ];
};

export const FORM_CUT_OF_WARRANTY = ({
  showBranch,
  onEnter,
  qrCodeInputRef,
  claimCodeInputRef,
  storeCutOffData,
}) => {
  const branchClaimOptions = [
    {
      name: 'storeId',
      label: 'สาขาที่เคลม',
      type: 'DROPDOWN',
      properties: {
        options: storeCutOffData || [],
      },
      placeholder: 'สาขาที่เคลม',
    },
  ];

  return [
    ...(showBranch ? [] : branchClaimOptions),
    {
      name: 'claimCodeOrWarrantyCode',
      label: 'เลขที่ลงทะเบียน / เลขที่เคลม',
      type: 'INPUT',
      placeholder: 'เลขที่เคลม',
      onEnter: (value) => onEnter('claimCodeOrWarrantyCode', value),
      ref: claimCodeInputRef,
    },
    {
      name: 'warrantyNumberCode',
      label: 'QR Code ที่ตัดรับประกัน',
      type: 'INPUT',
      placeholder: 'QR Code ที่ตัดรับประกัน',
      onEnter: (value) => onEnter('warrantyNumberCode', value),
      ref: qrCodeInputRef,
    },
  ];
};

export const FORM_INFO_DOC = () => {
  return [
    {
      name: 'warrantyCode',
      label: 'เลขที่ลงทะเบียน',
      type: 'TEXT',
    },
    {
      name: 'claimCode',
      label: 'เลขที่เคลม',
      type: 'TEXT',
    },
    {
      name: 'fullName',
      label: 'ชื่อ-นามสกุล',
      type: 'TEXT',
    },
    {
      name: 'telephone',
      label: 'เบอร์โทรศัพท์',
      type: 'TEXT',
    },
    {
      name: 'addressFullText',
      label: 'ที่อยู่',
      type: 'TEXT',
    },
    {
      name: 'productName',
      label: 'ชื่อสินค้า',
      type: 'TEXT',
    },
    {
      name: 'mobileModelName',
      label: 'รุ่น',
      type: 'TEXT',
    },
    {
      name: 'claimStatusName',
      label: 'สถานะ',
      type: 'TEXT',
    },
  ];
};
