import { useEffect, useState } from 'preact/hooks';
import { useForm } from 'react-hook-form';
import { useGetClaimDetail } from './useGetClaimDetail';
import { navigateTo } from '../../../../utils/navigation';
import { ROUTES_PATH } from '../../../../resources/routes-name';
import { useApproveClaim } from './useApproveClaim';
import { useModal } from '../../../../components/modal/hooks/useModal';
import { useSelector } from 'react-redux';

export const useGetClaimViewList = () => {
  const { control, setValue, getValues, reset } = useForm();
  const { open: openReject, onToggle: onToggleReject } = useModal();
  const { open: openCancle, onToggle: onToggleCancle } = useModal();
  const { open: openChangeProduct, onToggle: onToggleChangeProduct } = useModal();
  const queryParams = new URLSearchParams(window.location.search);
  const claimCode = queryParams.get('code');
  const [state, setState] = useState();
  const [data, setData] = useState();
  const [claimData, setClaimData] = useState();
  const [timelineList, setTimelineList] = useState();
  const userProfile = useSelector((info) => info.authen.users);
  const { privilegeFocusType } = userProfile;

  const { dataSource, loading: isLoadingClaimDetail } = useGetClaimDetail({
    claimCode: state?.claimCode,
    getValues,
    reset,
  });

  const { mutate: approveClaim, isPending: isPendingApproveClaim } = useApproveClaim();

  useEffect(() => {
    if (claimCode) {
      setState({ claimCode });
    }

    if (dataSource) {
      setData(dataSource);
    }

    if (data) {
      setClaimData(data?.claim);
    }

    const timelineData = () => {
      if (data?.timelineList) {
        const timeline = data?.timelineList?.map((item) => {
          return {
            text: item?.optionStatusNameTh,
            color: item?.optionStatusCode == data?.claim?.claimStatus ? 'green' : 'black',
          };
        });
        setTimelineList(timeline);
      }
    };
    timelineData();
  }, [claimCode, dataSource, data]);

  const onSubmitApprove = () => {
    approveClaim({ claimIdList: [claimData?.claimId] });
  };

  const onModalReject = (record) => {
    setClaimData(record);
    onToggleReject({ record });
  };

  const onModalChangeProduct = (record) => {
    setClaimData(record);
    onToggleChangeProduct({ record });
  };

  const goBack = () => {
    navigateTo({
      pathname: ROUTES_PATH.ROUTE_CLAIM.PATH,
      state: getValues(),
    });
  };

  const gotoEditClaim = () => {
    navigateTo({
      pathname: ROUTES_PATH.ROUTE_CLAIM_EDIT.PATH,
      search: `?code=${claimData?.claimCode}`,
    });
  };

  const DISABLED_APPROVE_STATUSES = [
    'PREPARING',
    'TRANSPORTED',
    'CANCEL',
    'BOOKING_EXPIRED',
    'WARRANTY_EXPIRED',
  ];

  return {
    goBack,
    control,
    setValue,
    getValues,
    timelineList,
    isLoadingClaimDetail,
    dataSource,
    claimCode,
    claimData,
    onSubmitApprove,
    isPendingApproveClaim,
    openReject,
    onToggleReject,
    onModalReject,
    gotoEditClaim,
    openChangeProduct,
    onToggleChangeProduct,
    onModalChangeProduct,
    data,
    openCancle,
    onToggleCancle,
    DISABLED_APPROVE_STATUSES,
    privilegeFocusType,
  };
};
